function createTranslationsErrors(errors) {
  const translationFieldsDictionary = {
    name: 'name',
    description: 'description',
    meta_title: 'metaTitle',
    meta_description: 'metaDescription',
    short_description: 'shortDescription',
    reviews: 'reviews',
  };

  return Object.entries(errors).reduce((acc, [key, errorMessages]) => {
    const match = key.match(/^translations\.(.{2})\.(.+)/);

    if (!match) {
      return acc;
    }

    const locale = match[1];
    const property = match[2];
    const processedKey = `translations.${locale}.${translationFieldsDictionary[property]}`;

    return {
      ...acc,
      [processedKey]: errorMessages,
    };
  }, {});
}

function createPackageContentsErrors(error) {
  return Object.entries(error).reduce((acc, [key, value]) => {
    const keys = key.split('.');

    if (keys[0] === 'package_contents' && keys[1]) {
      acc[keys[1]] = {
        ...acc[keys[1]],
        [keys[2]]: value,
      };
    }

    return acc;
  }, []);
}

export function createPackagesStoreErrors(data) {
  return {
    isEnabled: data.is_enabled,
    networkId: data.network_id,
    price: data.discount_percent || data.fixed_cost,
    packageContents: data.package_contents,
    discountPercent: data.discount_percent,
    ...createTranslationsErrors(data),
    packages: {
      ...createPackageContentsErrors(data),
    },
  };
}

export function createPackagesTotalCostErrors(data) {
  return {
    isEnabled: data.is_enabled,
    networkId: data.network_id,
    price: data.discount_percent || data.fixed_cost,
    packages: {
      ...createPackageContentsErrors(data),
    },
  };
}
