<template>
  <v-btn
    v-bind="$attrs"
    :class="['base-button', slimBorder ? 'slim-border' : 'regular-border']"
    :color="color"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'lightBlack',
    },
    slimBorder: Boolean,
  },
};

</script>
