import { defaultState } from './state';

export default {
  SET_CLEAR_STATE: (state) => {
    Object.assign(state, defaultState());
  },
  SET_EXTERNAL_PANEL_ID: (state, value) => { state.formData.externalPanelId = value; },
  SET_IS_SHOWN: (state, value) => { state.isShown = value; },
  SET_LOADING: (state, value) => { state.loading = value; },
  SET_СOLLECTIONS: (state, value) => { state.collections = value; },
  SET_SELECT_СOLLECTIONS: (state, value) => { state.selectCollections = value; },
  SET_EDITING_PROVIDER: (state, value) => { state.editingProvider = value; },
  SET_FORM_DATA: (state, value) => { state.formData = value; },
  SET_SOURCE_PRICING_PER: (state, value) => { state.formData.sourcePricingPer = value; },
  SET_SOURCE_PRICE: (state, value) => { state.formData.sourcePrice = value; },
  SET_IS_ARCHIVED: (state, value) => { state.formData.isArchived = value; },
  SET_EXTERNAL_PANEL_SERVICE_ID: (state, value) => { state.formData.externalPanelServiceId = value; },
  SET_TYPE: (state, value) => { state.formData.type = value; },
  SET_NAME: (state, value) => { state.formData.name = value; },
  SET_ERRORS: (state, value) => { state.errors = value; },
};
