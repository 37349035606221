import dateFormatter from '@/services/functions/date-filter';

export default function createDiscountFromResource(data) {
  return {
    id: data.id,
    name: data.name,
    createdAt: dateFormatter(data.created_at),
    validFrom: dateFormatter(data.valid_from),
    validTo: dateFormatter(data.valid_to),
    services: (data.services || []).map((discountServiceResource) => ({
      id: discountServiceResource.id,
      name: discountServiceResource.name,
      discountPercent: Number(discountServiceResource.discount_percent) * 100,
    })),
    status: data.status,
    actions: {
      completable: data.actions.completable,
      archivable: data.actions.archivable,
      editable: data.actions.editable,
      unarchivable: data.actions.unarchivable,
    },
  };
}
