export default {
  async archivable({
    dispatch,
    rootGetters,
  }, providerId) {
    rootGetters['providers/getRepository'].archive(providerId)
      .then(() => {
        dispatch('providers/table/fetch', null, { root: true });
        dispatch('providersModal/closeProviderModal');
      });
  },
  async restore({
    dispatch,
    rootGetters,
  }, providerId) {
    rootGetters['providers/getRepository'].unarchive(providerId)
      .then(() => {
        dispatch('providers/table/fetch', null, { root: true });
        dispatch('providersModal/closeProviderModal');
      });
  },
};
