import createPromotableCheckerGroup from '@/classes/createPromotableCheckerGroup';
import urlFormatter from '../url-formatter';
import http from '../http';

const getURL = urlFormatter({
  index: '/api/v1/admin/promotable-checker-groups',
});

export default {
  index() {
    const endpoint = getURL('index');

    return http.get(endpoint)
      .then((response) => response.data.data.map(createPromotableCheckerGroup));
  },
};
