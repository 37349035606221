import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  init: '/api/v1/admin/websockets/init',
});
const createWebsocketCounters = (data) => {
  if (!data) { return null; }

  return {
    ordersInModerationCount: data.orders_in_moderation_count,
    ordersInProcessingCount: data.orders_in_processing_count,
    ordersWithNoActiveProvidersCount: data.orders_with_no_active_providers_count,
    freeTrialsInPendingCount: data.free_trials_in_pending_count,
  };
};

export default {
  async init() {
    const endpoint = getURL('init');
    const { data } = await http.get(endpoint);

    return createWebsocketCounters(data.data);
  },
  getURL,
};
