import statusCounters from './types/counters-names';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    statusesCounters: [
      {
        name: statusCounters.freeTrials,
        value: null,
        label: 'Free-trials',
        page: 'Requests',
        filters: [
          {
            name: 'status',
            value: 'pending',
          },
        ],
        icon: 'mdi-gift',
      },
      {
        name: statusCounters.orderInModeration,
        value: null,
        label: 'In Moderation',
        page: 'Orders',
        filters: [
          {
            name: 'status',
            value: 'in_moderation',
          },
        ],
        icon: 'mdi-format-list-bulleted',
      },
      {
        name: statusCounters.orderInProcessing,
        value: null,
        label: 'In Processing',
        page: 'Orders',
        filters: [
          {
            name: 'status',
            value: 'in_processing',
          },
        ],
        icon: 'mdi-format-list-bulleted',
      },
      {
        name: statusCounters.orderWithNoActiveProviders,
        value: null,
        label: 'No providers',
        filters: [
          {
            name: 'hasNoActiveProviders',
            value: 'true',
          },
        ],
        page: 'Orders',
        query: 'true',
        icon: 'mdi-format-list-bulleted',
      },
    ],
  },
  mutations,
  actions,
  getters,
};
