export default {
  openModal({ commit }, {
    id,
    type,
  }) {
    commit('SET_SHOWN_MODAL', true);
    commit('SET_TRANSACTIONS_ID', id);
    commit('SET_TYPE_MODAL', type);
  },
  closeModal({ commit }) {
    commit('SET_SHOWN_MODAL', false);
  },
  withdrawalTableFetch({ dispatch }) {
    dispatch('withdrawal/table/fetch', null, { root: true });
  },
  setErrors({ commit }, error) {
    commit('SET_ERRORS', error);
  },
  submitRejected({
    state,
    dispatch,
    rootGetters,
  }, message) {
    return rootGetters['withdrawal/getRepository'].rejectWithdrawal(state.transactionsId, {
      adminNote: message,
    })
      .then(() => {
        dispatch('closeModal');
        dispatch('withdrawalTableFetch');
      })
      .catch((error) => {
        if (error.messages) {
          dispatch('setErrors', error.messages.admin_note);
        }
      });
  },
  submitPaid({
    state,
    dispatch,
    rootGetters,
  }, message) {
    return rootGetters['withdrawal/getRepository'].markWithdrawalAsPaid(state.transactionsId, {
      transactionId: message,
    })
      .then(() => {
        dispatch('closeModal');
        dispatch('withdrawalTableFetch');
      })
      .catch((error) => {
        if (error.messages) {
          if (error.messages) {
            dispatch('setErrors', error.messages.transaction_id);
          }
        }
      });
  },
  setApproved({
    state,
    commit,
    dispatch,
    rootGetters,
  }, { id }) {
    commit('SET_TRANSACTIONS_ID', id);

    return rootGetters['withdrawal/getRepository'].approveWithdrawal(state.transactionsId)
      .then(() => {
        dispatch('withdrawal/table/fetch', null, { root: true });
      })
      .catch((error) => {
        if (error.messages) {
          dispatch('setErrors', error.messages);
        }
      });
  },
  clearErrors({
    commit,
  }) {
    commit('SET_ERRORS', '');
  },
};
