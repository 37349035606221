import createSecretFieldsFromResource from './createSecretFieldsFromResource';
import createPublicFieldsFromResource from './createPublicFieldsFromResource';

export default (item) => ({
  instruction: item.instruction,
  name: item.name,
  secretFields: (item.secret_fields || []).map(createSecretFieldsFromResource),
  data: (item.public_fields || []).map(createPublicFieldsFromResource),
  type: item.type,
});
