function createTranslationsErrors(errors) {
  const translationFieldsDictionary = {
    name: 'name',
  };

  return Object.entries(errors).reduce((acc, [key, errorMessages]) => {
    const match = key.match(/^translations\.(.{2})\.(.+)/);

    if (!match) {
      return acc;
    }

    const locale = match[1];
    const property = match[2];
    const processedKey = `translations.${locale}.${translationFieldsDictionary[property]}`;

    return {
      ...acc,
      [processedKey]: errorMessages,
    };
  }, {});
}

export default function createPackagesStoreErrors(data) {
  return {
    icon: data.icon,
    spendingGoal: data.spending_goal,
    discountPercent: data.discount_percent,
    thumbnailPeriod: data.thumbnail_period,
    consultationChatPeriod: data.consultation_chat_period,
    availableClosedSales: data.available_closed_sales,
    availablePersonalManager: data.available_personal_manager,
    ...createTranslationsErrors(data),
  };
}
