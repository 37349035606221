export default {
  headers: [
    {
      value: 'date',
      name: 'date',
      text: 'Date of usage',
      width: '132px',
      sortable: false,
      isShown: true,
    },
    {
      value: 'name',
      name: 'name',
      text: 'Name',
      width: '100px',
      isShown: true,
    },
    {
      value: 'code',
      name: 'code',
      text: 'Code',
      width: '100px',
      isShown: true,
    },
    {
      value: 'received',
      name: 'received',
      text: 'Received',
      width: '100px',
      sortable: true,
      isShown: true,
    },
    {
      value: 'spent',
      name: 'spent',
      text: 'Spent',
      width: '100px',
      sortable: true,
      isShown: true,
    },
    {
      value: 'usages',
      name: 'usages',
      text: 'Usages',
      width: '100px',
      sortable: true,
      isShown: true,
    },
  ],
};
