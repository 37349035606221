import {
  createDateFilter,
  createTextFilter,
  createLogsFilter,
  CreateLogsActionTypeFilter,
} from '../builder-definition/filters/table';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      width: '68px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
    },
    {
      value: 'createdAt',
      name: 'change_logs.created_at',
      isShown: true,
      text: 'Created at',
      width: '120px',
      customFilter: createDateFilter(),
    },
    {
      value: 'actionType',
      name: 'action_type',
      text: 'Action type',
      width: '60px',
      isShown: true,
      customFilter: CreateLogsActionTypeFilter(),
    },
    {
      value: 'type',
      name: 'model_type',
      text: 'Type',
      customFilter: createLogsFilter(),
      isShown: true,
    },
    {
      text: 'Target',
      name: 'model_id',
      isShown: true,
      value: 'modelType.fullName',
      sortable: false,
      customFilter: createTextFilter({ placeholder: 'Search from target id' }),
    },
    {
      value: 'information',
      text: 'Information',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search in information' }),
    },
  ],
};
