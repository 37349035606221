import { defaultDialogSetupBuilder } from '@/components/services/dialog/dialogSetup';

export default (invokeArgs = {}) => {
  defaultDialogSetupBuilder(
    {
      isModalShown: true,
      confirmTitle: 'Deactivate account',
      confirmText: 'Are you sure you want to deactivate account? You will be able to undo this action. ',
      confirmBtnColor: 'statusDeactivated',
      confirmBtnText: 'Deactivate',
      ...invokeArgs,
    },
  );
};
