import Echo from 'laravel-echo';
import tokenStoreFactory from '@/auth/tokenStore';
import settings from '@/settings';

window.Pusher = require('pusher-js');

export default function createWebsocket(options) {
  const tokenStore = tokenStoreFactory();
  const savedToken = tokenStore.getSavedToken();

  return new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKET_KEY,
    authEndpoint: `${settings.apiHost}/broadcasting/auth`,
    wsHost: settings.wsHost,
    wsPath: process.env.VUE_APP_WEBSOCKET_PATH,
    wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
    disableStats: false,
    forceTLS: process.env.NODE_ENV === 'production',
    auth: {
      headers: {
        Authorization: `Bearer ${savedToken}`,
      },
    },
    ...options,
  });
}
