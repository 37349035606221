import {
  createResetButton,
} from '../builder-definition/filters';
import { createPlainText } from '../builder-definition/filters/page';

export default {
  commonSearch: null,
  id: '',
  position: '',
  icon: '',
  name: '',
  status: '',
};

export const config = {
  defaultSearchPropName: 'id',
  exceptionFilters: ['status'],
  rows: [
    [
      createPlainText({
        filterProp: 'commonSearch',
        placeholder: 'Search',
      }),
      createResetButton(),
    ],
  ],
};
