import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createSpecialOffers from '@/classes/createSpecialOffers';
import http, { cancelTokenNames } from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  index: '/api/v1/admin/special-offers',
  collection: '/api/v1/admin/special-offers/collection',
  services: '/api/v1/admin/special-offers/services',
  update: '/api/v1/admin/special-offers/{id}',
  networks: '/api/v1/admin/special-offers/networks',
  archive: '/api/v1/admin/special-offers/{id}/archive',
  unarchive: '/api/v1/admin/special-offers/{id}/unarchive',
  delete: '/api/v1/admin/special-offers/{id}',
  getOffer: '/api/v1/admin/special-offers/{id}',
  enable: '/api/v1/admin/special-offers/{id}/enable',
  disable: '/api/v1/admin/special-offers/{id}/disable',
});

export default {
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('index');
    const response = await http.get(`${endpoint}?${requestBody}`, {
      applyCancelAdapter: cancelTokenNames.SPECIAL_OFFERS_TABLE,
    });

    return {
      items: response.data.data.map(createSpecialOffers),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  async collection() {
    const endpoint = getURL('collection');
    const { data } = await http.get(endpoint);

    return data.data;
  },
  async services({
    networkId,
    search = null,
    page = null,
  }) {
    const endpoint = getURL('services');

    let query = `${endpoint}?`;

    if (networkId) {
      query += `network_id=${networkId}&`;
    }

    query += search ? `search=${search}` : `page=${page}`;

    const { data } = await http.get(query);

    return data.data;
  },
  async networks() {
    const endpoint = getURL('networks');
    const { data } = await http.get(endpoint);

    return data.data;
  },
  async create(body) {
    const endpoint = getURL('index');

    try {
      const response = await http.post(endpoint, body);

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  },
  async delete(id) {
    const endpoint = getURL('delete', {
      id,
    });

    await http.delete(endpoint);
  },
  async update(body) {
    const endpoint = getURL('update', { id: body.id });

    try {
      const response = await http.put(endpoint, body);

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  },
  async archive(id) {
    const endpoint = getURL('archive', {
      id,
    });

    await http.post(endpoint);
  },
  async unarchive(id) {
    const endpoint = getURL('unarchive', {
      id,
    });

    await http.post(endpoint);
  },
  async enable(id) {
    const endpoint = getURL('enable', {
      id,
    });

    await http.post(endpoint);
  },
  async disable(id) {
    const endpoint = getURL('disable', {
      id,
    });

    await http.post(endpoint);
  },
  async getOffer(id) {
    const endpoint = getURL('getOffer', {
      id,
    });
    const data = await http.get(endpoint);

    return data.data.data;
  },
};
