import dateFilter from '@/services/functions/date-filter';

function createModelFromResource(data, type) {
  if (!data) {
    return null;
  }

  return {
    name: data.name,
    id: data.id,
    fullName: type === 'order' ? `${data.id}` : `[${data.id}] ${data.name}`,
  };
}

export default function createLogFromResource(data) {
  return {
    createdAt: dateFilter(data.created_at),
    id: data.id,
    information: data.information,
    modelType: createModelFromResource(data.model_type, data.type),
    type: data.type,
    actionType: data.action_type,
    newValue: data.new_value,
    oldValue: data.old_value,
  };
}
