export default {
  openImportProvidersModals({ dispatch }, { id }) {
    if (id) {
      dispatch('setId', id);
      dispatch('loadAvailableServices', id);
    }

    dispatch('setIsShown', true);
  },
  setId({ commit }, value) {
    commit('SET_ID', value);
  },
  setIsShown({ commit }, value) {
    commit('SET_IS_SHOWN', value);
  },
  closeImportProvidersModal({
    dispatch,
  }) {
    dispatch('setIsShown', false);
    dispatch('setClearState');
  },
  setSelected({ commit }, value) {
    commit('SET_SELECTED', value);
  },
  loadAvailableServices({
    commit,
    rootGetters,
  }, id) {
    commit('SET_LOADING', true);

    rootGetters['providers/getRepository'].getExternalPanelServicesForImport({
      externalPanelId: id,
    })
      .then((response) => {
        commit('SET_AVAILABLE_SERVICES', response);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  storeImportedProviders({
    commit,
    state,
    rootGetters,
    dispatch,
  }, {
    errorNotify,
    successNotify,
  }) {
    const selectedServices = state.selected.map((service) => service.id);

    commit('SET_SAVE_BTN_LOADING', true);

    rootGetters['providers/getRepository'].storeImportedProviders({
      externalPanelId: state.id,
      importableServicesIds: selectedServices,
    })
      .then(() => {
        dispatch('closeImportProvidersModal');
        successNotify();
      })
      .catch((error) => {
        const errorMessage = error instanceof Error ? error.message : error;

        commit('SET_ERRORS_MESSAGE', errorMessage);
        errorNotify();
      })
      .finally(() => {
        commit('SET_SAVE_BTN_LOADING', false);
      });
  },
  setClearState({ commit }) {
    commit('SET_CLEAR_STATE');
  },
  setSearch({ commit }, value) {
    commit('SET_SEARCH', value);
  },
};
