import { defaultDialogSetupBuilder } from '@/components/services/dialog/dialogSetup';

export default (invokeArgs = {}) => {
  defaultDialogSetupBuilder(
    {
      isModalShown: true,
      confirmTitle: 'Delete feature «Custom comments»',
      confirmText: 'Are you sure you want to delete feature «Custom comments»? This action cannot be undone.',
      confirmBtnColor: 'invokedDialogRed',
      confirmBtnText: 'Delete',
      ...invokeArgs,
    },
  );
};
