import { defaultDialogSetupBuilder } from '@/components/services/dialog/dialogSetup';

export default (invokeArgs = {}) => {
  const postfix = invokeArgs.amount > 1 ? 's' : '';
  const dialogSubject = `order${postfix}`;

  defaultDialogSetupBuilder(
    {
      isModalShown: true,
      confirmTitle: `Activate the ${dialogSubject}`,
      confirmText: `Are you sure you want to activate
       ${invokeArgs.amount} ${dialogSubject}? You will be able to undo this action.`,
      confirmBtnColor: 'invokedDialogGreen',
      confirmBtnText: `Activate ${dialogSubject} (${invokeArgs.amount})`,
      ...invokeArgs,
    },
  );
};
