<template functional>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!--eslint-disable -->
    <path d="M8.71599 15.9867H1.38477" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8642 15.9864C19.8642 17.577 18.5575 18.8664 16.9455 18.8664C15.3336 18.8664 14.0269 17.577 14.0269 15.9864C14.0269 14.3947 15.3336 13.1064 16.9455 13.1064C18.5575 13.1064 19.8642 14.3947 19.8642 15.9864Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5322 4.26206H19.8646" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.38428 4.26184C1.38428 5.8536 2.691 7.14184 4.30294 7.14184C5.91487 7.14184 7.22159 5.8536 7.22159 4.26184C7.22159 2.67125 5.91487 1.38184 4.30294 1.38184C2.691 1.38184 1.38428 2.67125 1.38428 4.26184Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <!--eslint-enable -->
  </svg>
</template>

<script>
export default {
};
</script>
