import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createPackage from '@/classes/createPackage';
import createAvailableServices from '@/classes/createAvailableServices';
import createPackagesTotalCost from '@/classes/createPackagesTotalCost';
import handleErrors from '@/services/handle-errors';
import http, { cancelTokenNames } from '../../http';
import urlFormatter from '../../url-formatter';
import settings from './settings';
import {
  createPackagesStoreErrors,
  createPackagesTotalCostErrors,
} from './create-packages-errors';
import {
  createPackageStoreRequest,
  createPackageUpdateRequest,
} from './create-package-store-request';
import createBulkPackagesEditRequest from './create-bulk-packages-edit-request';
import createBulkPackageEditErrors from './create-bulk-package-edit-errors';

const getURL = urlFormatter({
  list: '/api/v1/admin/packages',
  store: '/api/v1/admin/packages',
  show: '/api/v1/admin/packages/{packageId}',
  update: '/api/v1/admin/packages/{packageId}',
  bulkUpdate: '/api/v1/admin/packages/bulk',
  archive: '/api/v1/admin/packages/{packageId}/archive',
  unarchive: '/api/v1/admin/packages/{packageId}/unarchive',
  totalCost: 'api/v1/admin/packages/total-cost',
  availableServices: '/api/v1/admin/packages/available-services',
});
const packagesRepository = {
  async all() {
    const endpoint = getURL('list');

    try {
      const { data } = await http.get(endpoint);

      return data.data.map(createPackage);
    } catch (error) {
      return handleErrors(error, createPackagesStoreErrors);
    }
  },
  async list(queryParams) {
    const endpoint = getURL('list');
    const { data } = await http.get(
      `${endpoint}?${datatableFormaterStringified(queryParams)}`,
      { params: { is_archived: Number(queryParams.search.isArchived) } },
    );

    return {
      items: data.data.map(createPackage),
      totalItemsCount: data.recordsFiltered,
    };
  },
  async totalCost({
    packageContents,
    price,
  }) {
    const endpoint = getURL('totalCost');
    const priceValue = settings.prices[price.key].converting(price.value);
    const data = {
      package_contents: packageContents.map((packageContent) => ({
        service_id: packageContent.id,
        quantity: packageContent.quantity,
      })),
      [settings.prices[price.key].key]: priceValue,
    };

    try {
      const response = await http.post(endpoint, data, {
        applyCancelAdapter: cancelTokenNames.PACKAGES_TOTAL_COST,
      });

      return createPackagesTotalCost(response.data.data);
    } catch (error) {
      return handleErrors(error, createPackagesTotalCostErrors);
    }
  },
  async show(packageId) {
    const endpoint = getURL('show', { packageId });
    const { data } = await http.get(endpoint);

    return createPackage(data.data);
  },
  async store(data) {
    const endpoint = getURL('store');

    try {
      return await http.post(endpoint, createPackageStoreRequest(data));
    } catch (error) {
      return handleErrors(error, createPackagesStoreErrors);
    }
  },
  async update(data) {
    const endpoint = getURL('update', { packageId: data.id });

    try {
      return await http.put(endpoint, createPackageUpdateRequest(data));
    } catch (error) {
      return handleErrors(error, createPackagesStoreErrors);
    }
  },
  async availableServices({
    networkId,
    search = null,
    page = null,
  }) {
    const endpoint = getURL('availableServices');
    const { data } = await http.post(
      endpoint,
      {
        network_id: networkId,
      },
      {
        params: {
          search,
          page,
        },
      },
    );

    return data.data.map(createAvailableServices);
  },
  async bulkUpdate(services, formData) {
    const endpoint = getURL('bulkUpdate');

    try {
      return await http.post(endpoint, createBulkPackagesEditRequest(services, formData));
    } catch (error) {
      return handleErrors(error, createBulkPackageEditErrors);
    }
  },
  async archive(packageId) {
    const endpoint = getURL('archive', { packageId });

    try {
      return await http.post(endpoint);
    } catch (error) {
      return handleErrors(error, createPackagesStoreErrors);
    }
  },
  async unarchive(packageId) {
    const endpoint = getURL('unarchive', { packageId });

    try {
      return await http.post(endpoint);
    } catch (error) {
      return handleErrors(error, createPackagesStoreErrors);
    }
  },
};

export default packagesRepository;
