var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',_vm._g(_vm._b({class:[
    'base-autocomplete',
    {
      'persistent-border': _vm.isPersistedBorders,
      medium: _vm.size === 'm',
      small: _vm.size === 's',
    },
  ],attrs:{"dense":"","outlined":"","clear-icon":_vm.clearIcon,"append-icon":_vm.actualIcon,"attach":"","menu-props":{
    contentClass: 'base-autocomplete__content',
  }},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)},'v-autocomplete',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }