import {
  createQueryFilter, createResetButton,
} from '../builder-definition/filters';
import { createPlainText } from '../builder-definition/filters/page';

export default {
  commonSearch: '',
  id: '',
  createdAt: '',
  name: '',
  service: '',
  user: '',
  url: '',
  quantity: '',
  status: '',
  hasNoActiveProviders: '',
  source: '',
};

export const config = {
  defaultSearchPropName: 'id',
  exceptionFilters: [
    'status',
    'hasNoActiveProviders',
    'isPaid',
  ],
  rows: [
    [

      createQueryFilter({
        textFormatter: ({
          type, id,
        }) => {
          const queryType = {
            drip_feed: 'Drip feed',
            subscription: 'Subscription',
            combo_order: 'Combo order',
          }[type];

          if (!queryType || !id) return false;

          return `${queryType} #${id}`;
        },
        clearDispatchPath: 'orders/table/discardQueries',
      }),
      createPlainText({
        filterProp: 'commonSearch',
        placeholder: 'Search',
      }),
      createResetButton(),
    ],
  ],
};
