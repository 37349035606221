<template functional>
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!--eslint-disable -->
    <path d="M1 5L5 1L9 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <!--eslint-enable -->
  </svg>
</template>

<script>
export default {
};
</script>
