export default {
  // CANCEL SINGLE
  singleCancelId: (state) => state.single.id,
  isSingleCancelling: (_, getters) => Boolean(getters.singleCancelId),
  cancelAmount: (state) => state.single.amount,
  isDefaultAmountLoading: (state) => state.single.isDefaultAmountLoading,

  // CANCEL BULK
  bulkCancelIds: (state) => state.bulk.ids,
  isBulkCancelling: (_, getters) => Boolean(getters.bulkCancelIds),

  // CANCEL COMMON
  isCancelModalShown: (_, getters) => getters.isSingleCancelling || getters.isBulkCancelling,
  isRefundNeeded: (state) => state.common.modal.isRefundNeeded,
  sendEmailNotification: (state) => state.common.modal.sendEmailNotification,
  cancellingErrors: (state) => state.common.modal.errors,
  cancelReason: (state) => state.common.modal.reason,
  cancelDescription: (state) => state.common.modal.description,
  availableCancelReasons: (state) => state.common.availableReasons,
  availableCancelReasonsLoading: (state) => state.common.availableReasonsLoading,
  isSavingProcess: (state) => state.common.modal.isSavingProcess,
  refreshTable: (state) => state.common.refreshTable,
};
