import {
  createCurrencyFilter,
  createTextFilter,
} from '../builder-definition/filters/table';
import {
  createCellPlainStatuses,
  createCellActions,
} from '../builder-definition/tables';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      name: 'id',
      width: '90px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
    },
    {
      value: 'name',
      name: 'name',
      text: 'Name',
      hideable: false,
      isShown: true,
      width: '400px',
      customFilter: createTextFilter({ placeholder: 'Search name' }),
      ...createCellPlainStatuses({
        mapper: 'providers',
        statusProp: 'status',
      }),
    },
    {
      value: 'type',
      name: 'type',
      text: 'Type',
      hideable: false,
      isShown: true,
    },
    {
      value: 'balance',
      name: 'balance',
      text: 'Balance',
      sortable: true,
      isShown: true,
    },
    {
      value: 'currency',
      name: 'currency',
      text: 'Сurrency',
      sortable: false,
      width: '150px',
      isShown: true,
      customFilter: createCurrencyFilter(),
    },
    {
      text: 'Status',
      value: 'status',
      isHidden: true,
    },
    {
      value: 'actions',
      text: 'Actions',
      width: '60px',
      searchable: false,
      sortable: false,
      isShown: true,
      align: 'end',
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Edit',
              value: 'editable',
              prop: 'editable',
              callBack: (item, store) => {
                store.dispatch('providers/providersModal/openProviderModal', item.id);
              },
            },
            {
              text: 'Import services',
              callBack: (item, store) => {
                store.dispatch('providers/importServices/openImportServicesModal', {
                  id: item.id,
                  type: item.type,
                });
              },
            },
            {
              text: 'Import providers services',
              callBack: (item, store) => {
                store.dispatch('providers/importProvidersDialog/openImportProvidersModals', {
                  id: item.id,
                });
              },
            },
            {
              text: 'Billing',
              value: 'showBilling',
              prop: 'showBilling',
              callBack: (item, store) => {
                store.dispatch('providers/BillingDialog/openBillingModals', {
                  id: item.id,
                });
              },
            },
            {
              text: 'Archive',
              value: 'archivable',
              prop: 'archivable',
              callBack: (item, store) => {
                store.dispatch('providers/archivable', item.id);
              },
            },
            {
              text: 'Restore',
              value: 'restore',
              prop: 'restore',
              callBack: (item, store) => {
                store.dispatch('providers/restore', item.id);
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;

            return tableElement.actions[prop]
              ? tableElement.actions[prop]
              : false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
