import createExternalPanelFromResource from '@/classes/createExternalPanel';
import createProviderFromResource from '@/classes/createProvider';

export default function createExternalPanelServicesFromResource(data) {
  return {
    externalPanel: createExternalPanelFromResource(data.external_panel),
    externalPanelServiceId: data.external_panel_service_id,
    provider: createProviderFromResource(data.provider),
  };
}
