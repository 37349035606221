export default ({
  currentProp, singlRuns, to,
}) => ({
  componentName: 'cell-runs',
  componentArgs: {
    currentProp,
    singlRuns,
    to,
  },
});
