import stateExtender from '@/store/utils/stateExtender';
import RepositoryFactory from '@/services/repository-factory';
import datatableModule from '@/store/builder/datatableModule';
import table from '@/components/builders/configs/tables/subcribtionsTable';
import filters from '@/components/builders/configs/filters/subscriptionsFilters';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const subscriptionsRepository = RepositoryFactory.get('subscriptions');
const state = {
  repository: subscriptionsRepository,
  error: null,
  subscriptionsCollections: null,
  subscriptionsCollectionsIsLoaded: false,
  subscriptionsCollectionsIsLoading: false,
};

export default stateExtender({
  namespaced: true,
  state: () => state,
  modules: {
    table: datatableModule({
      fetch: subscriptionsRepository.table,
      headers: table.headers.filter((header) => header.isShown),
      allHeaders: table.headers,
      options: {
        itemsPerPage: 30,
        sortBy: ['id'],
        sortDesc: [true],
      },
      search: filters,
    }),
  },
  getters,
  mutations,
  actions,
});
