export const defaultState = () => ({
  providerId: null,
  type: '',
  search: '',
  allNetworks: [],
  selectedNetworkId: null,
  allServicesTypes: [],
  selectedServiceTypeId: null,
  services: [],
  selectedServices: [],
  multiplier: 0,
  loading: false,
  saveBtnLoading: false,
  tableOptions: {},
  errors: {},
  errorsMessage: '',
  errorsNotify: false,
});

export default () => ({
  innerIsShown: false,
  saving: false,
  ...defaultState(),
});
