import settings from '@/settings';

export default {
  Users: settings.permissions.viewUsers,
  Orders: settings.permissions.viewOrders,
  DripFeed: settings.permissions.viewOrders,
  Subscriptions: settings.permissions.viewOrders,
  Transactions: settings.permissions.viewFinances,
  Invoices: settings.permissions.viewInvoices,
  Withdrawal: settings.permissions.viewWithdrawals,
  Services: settings.permissions.viewServices,
  Providers: settings.permissions.manageServicesAndProviders,
  ExternalPanels: settings.permissions.manageExternalPanels,
  ExternalPanelOrders: settings.permissions.manageExternalPanelOrders,
  ReferralProgram: settings.permissions.viewReferralStatistics,
  PaymentMethods: settings.permissions.managePaymentMethods,
  OrderWidgets: settings.permissions.manageOrderWidgets,
  Webhooks: settings.permissions.manageWebhooks,
  Coupons: settings.permissions.manageCoupons,
  Discounts: settings.permissions.manageDiscounts,
  FreeTrials: settings.permissions.manageFreeTrials,
  Networks: settings.permissions.manageServiceTypes,
  ServiceTypes: settings.permissions.manageServiceTypes,
  Staff: settings.permissions.manageStaff,
  AuditLog: settings.permissions.viewAudits,
  PanelSettings: settings.permissions.managePanelSettings,
  ManageGrades: settings.permissions.manageGrades,
  ViewGrades: settings.permissions.viewGrades,
  Targets: settings.permissions.manageTargets,
  ChangeLogs: settings.permissions.viewChangeLogs,
};
