export default function createExternalPanelCollections(data) {
  if (!data) {
    return null;
  }

  return {
    currencies: data.currencies,
    statisticTypes: data.statistic_types,
    topProviders: data.top_providers.map((provider) => ({
      icon: provider.icon,
      id: provider.id,
      isUsed: provider.is_used,
      name: provider.name,
      sorting: provider.sorting,
      url: provider.url,
    })),
    type: data.type,
  };
}
