<template>
  <v-tooltip
    v-bind="$attrs"
    :class="'base-tooltip'"
    :content-class="`base-tooltip__content tooltip-${direction}`"
    :top="top"
    :bottom="bottom"
    :left="left"
    :right="right"
    :open-delay="openDelay"
    v-on="$listeners"
  >
    <template
      v-for="(index, name) in $scopedSlots"
      #[name]="data"
    >
      <slot
        :name="name"
        v-bind="data"
      />
    </template>

    <slot />
  </v-tooltip>
</template>

<script>
export default {
  name: 'BaseTooltip',
  props: {
    top: Boolean,
    bottom: Boolean,
    left: Boolean,
    right: Boolean,
    openDelay: {
      type: [String, Number],
      default: 100,
    },
  },
  computed: {
    direction() {
      if (this.bottom) {
        return 'bottom';
      }

      if (this.right) {
        return 'right';
      }

      if (this.left) {
        return 'left';
      }

      return 'top';
    },
  },
};
</script>
