<script>
import {
  saveFilters, rememberedFiltersHasNoEmpty, getSavedFilters,
} from '@/utils/rememberTableFilters';

export default {
  model: {
    prop: 'filters',
    event: 'update',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    storageKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    mutableFilters: {
      get() {
        return this.filters;
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(value) {
        this.saveFilters({
          KEY: this.storageKey,
          filters: value,
        });
      },
    },
  },
  created() {
    this.checkStorageFilters();
  },
  methods: {
    saveFilters,
    rememberedFiltersHasNoEmpty,
    getSavedFilters,

    checkStorageFilters() {
      if (!this.rememberedFiltersHasNoEmpty({ KEY: this.storageKey })) {
        return;
      }

      const mutableFilters = this.getSavedFilters({ KEY: this.storageKey });

      this.$emit('update', mutableFilters);
    },
  },
  render: () => null,
};
</script>
