<template>
  <div :class="['d-flex align-center ', disabled && 'disabledDottedText--text']">
    <colored-dot
      v-if="type === 'color'"
      class="mr-2"
      :color="color"
    />

    <base-hint
      v-else-if="type === 'hint'"
      class="mr-2"
      :hint="value"
      x-small
    />

    <div
      v-if="text"
      :class="
        [
          'text-uppercase statuses-text d-flex align-center',
          bold && 'statuses-text__bold',
        ]
      "
    >
      <div>
        {{ text }}
      </div>

      <base-hint
        v-if="hint"
        small
        class="ml-1"
        :hint="hint"
      />
    </div>

    <slot v-else />

    <slot name="append" />
  </div>
</template>
<script>
import ColoredDot from './ColoredDot.vue';

export default {
  components: { ColoredDot },
  props: {
    value: {
      type: [String, Object],
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'color',
    },
    hint: {
      type: Object,
      default: null,
    },
    bold: Boolean,
    disabled: Boolean,
  },
  computed: {
    color() {
      return this.disabled ? 'disabledDottedText' : this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.statuses-text{
  @include generateFont($font-size:14px, $line-height:14px);
  &__bold{
    font-weight: bold!important;
  }
}
</style>
