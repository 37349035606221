import RepositoryFactory from '@/services/repository-factory';

const paymentMethodBonuses = RepositoryFactory.get('paymentMethodBonuses');

export default {
  bonusModalOpen({
    dispatch,
    commit,
  }, {
    id,
    isShown,
  }) {
    dispatch('toggleModal', isShown);
    commit('SET_PAYMENT_METHOD_ID', id);
    dispatch('fetchPaymentMethod', id);
  },
  toggleModal({ commit }, value) {
    commit('SET_IS_SHOWN', value);
  },
  async fetchPaymentMethod({
    rootGetters, commit,
  }, id) {
    try {
      const loadedPaymentMethod = await rootGetters['paymentMethods/getRepository'].show(id);

      commit('SET_PAYMENT_METHOD_BONUSES', loadedPaymentMethod?.paymentMethodBonuses);
    } catch (errors) {
      commit('SET_ERRORS', errors.messages);
    }
  },
  addBonus({
    commit,
  }) {
    commit('ADD_BONUS', {
      bonusPercent: 0,
      fromAmount: 0,
      isEnabled: false,
    });
  },
  saveBonuses({
    dispatch,
    state,
    commit,
  }, {
    errorNotify,
    successNotification,
  }) {
    paymentMethodBonuses.store(state.paymentMethodId, state.paymentMethodBonuses)
      .then(() => {
        dispatch('fetchPaymentMethod', state.paymentMethodId);
        dispatch('paymentMethods/table/fetch', null, { root: true });
        dispatch('toggleModal', false);

        if (successNotification) {
          successNotification();
        }
      })
      .catch((error) => {
        if (error.messages) {
          commit('SET_ERRORS', error.messages);
          commit('SET_ERRORS_MESSAGE', error.message);
        }

        if (errorNotify) {
          errorNotify();
        }
      });
  },
  deleteBonus({
    commit,
    dispatch,
    state,
  }, bonus, index) {
    if (Object.prototype.hasOwnProperty.call(bonus, 'id')) {
      paymentMethodBonuses.delete(bonus.id)
        .then(() => {
          dispatch('fetchPaymentMethod', state.paymentMethodId);
          dispatch('paymentMethods/table/fetch', null, { root: true });
        });
    } else {
      commit('REMOVE_BONUS', index);
    }
  },
};
