export default {
  SET_OPTIONS_PAGE(state, payload) {
    state.options = {
      ...state.options,
      page: payload,
    };
  },
  INCREMENT_DRAW(state) {
    state.draw += 1;
  },
  END_LOADING(state) {
    state.loading = false;
  },
  START_LOADING(state) {
    state.loading = true;
  },
  CLEAR_SELECTED(state) {
    state.selected = [];
  },
};
