export const defaultState = () => ({
  loading: false,
  collections: {
    types: [],
  },
  editingProvider: null,
  formData: {
    name: '',
    type: '',
    externalPanelId: '',
    externalPanelServiceId: '',
    sourcePrice: '',
    sourcePricingPer: '',
    isArchived: '',
  },
  errors: {},
});

export default () => ({
  isShown: false,
  selectCollections: {
    types: [],
  },
  ...defaultState(),
});
