import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  index: '/api/v1/admin/notifications',
  update: '/api/v1/admin/notifications',
});

function inServerFormatedNotifications(notifications) {
  return notifications.map((item) => ({
    notification: item.name,
    description: item.description,
    is_enabled: item.isEnabled,
  }));
}

function inAppFormatedNotifications(notifications) {
  return notifications.map((item) => ({
    name: item.notification,
    description: item.description,
    isEnabled: item.is_enabled,
  }));
}

export default {
  index() {
    const endpoint = getURL('index');

    return http.get(endpoint)
      .then((response) => inAppFormatedNotifications(response.data.data));
  },
  update(notifications) {
    const endpoint = getURL('update');
    const data = inServerFormatedNotifications(notifications);

    return http.put(endpoint, {
      panel_notifications: data,
    });
  },
  getURL,
};
