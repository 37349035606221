import { createCellLink } from '../builder-definition/tables';
import { createTextFilter } from '../builder-definition/filters/table';

export default {
  headers: [
    {
      value: 'coupon.name',
      name: 'coupon_translations.name',
      text: 'Name',
      width: '270px',
      sortable: true,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search name' }),
    },
    {
      value: 'coupon.code',
      name: 'coupons.code',
      text: 'Code',
      width: '100px',
      sortable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Filter code' }),
    },
    {
      name: 'users.email',
      value: 'coupon.affiliate.email',
      text: 'Referral manager',
      width: '100px',
      sortable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search users' }),
      ...createCellLink({
        textProp: 'coupon.affiliate.email',

        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item?.coupon?.affiliate?.id,
          },
        }),
      }),
    },
    {
      value: 'received',
      name: 'received',
      text: 'Received',
      width: '100px',
      sortable: true,
      isShown: true,
    },
    {
      value: 'spent',
      name: 'spent',
      text: 'Spent',
      width: '100px',
      sortable: true,
      isShown: true,
    },
    {
      value: 'usages',
      name: 'usages',
      text: 'Usages',
      width: '100px',
      sortable: true,
      isShown: true,
    },
  ],
};
