import {
  actionNamer, mutationNamer,
} from '@/store/utils/storeNamer';

export default (state) => {
  const newActions = {};

  Object.keys(state.state()).forEach((propName) => {
    newActions[`${actionNamer(propName)}`] = ({ commit }, payload) => commit(mutationNamer(propName), payload);
  });

  return {
    ...newActions,
    ...state.actions,
  };
};
