const debouncedFetch = {
  SET_SEARCH(store, fullName) {
    store.dispatch(`${fullName}/setPage`, 1);
    store.dispatch(`${fullName}/debouncedFetch`);
  },
  SET_HEADER(store, fullName) {
    store.dispatch(`${fullName}/debouncedFetch`);
  },
  SET_OPTIONS(store, fullName) {
    store.dispatch(`${fullName}/debouncedFetch`);
  },
};

export default (store, mutation, {
  mutationName,
  fullName,
  moduleName,
}) => {
  if (moduleName === 'table') {
    if (debouncedFetch[mutationName]) {
      debouncedFetch[mutationName](store, fullName);
    }
  }
};
