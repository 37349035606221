export default class ValidationError extends Error {
  constructor(messages, message) {
    super('Validation Error');
    this.messages = messages;

    if (message) {
      this.message = message;
    }
  }
}
