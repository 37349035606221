<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <span v-on="on">
        <span v-if="!hideIfNoValue || value">
          {{ shortPrice }}
        </span>
      </span>
    </template>

    <span>
      {{ tooltipValue }}
    </span>
  </v-tooltip>
</template>

<script>
import getShortPrice from '@/utils/getShortPrice';

export default {
  name: 'BaseCostLabel',
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    currency: {
      type: String,
      default: '$',
    },
    toFixed: {
      type: Number,
      default: 0,
    },
    trimFraction: {
      type: [Number, Boolean],
      default: false,
    },
    hideIfNoValue: Boolean,
  },
  computed: {
    shortPrice() {
      return getShortPrice(String(this.value), this.currency, this.toFixed, this.trimFraction);
    },
    tooltipValue() {
      return String(this.value).includes('$') ? this.value : `$ ${this.value}`;
    },
  },
};
</script>
