export default function applyFavicon(favicon) {
  const link = document.createElement('link');

  link.rel = 'icon';
  link.href = favicon;

  const existingIcon = document.querySelector('link[rel="icon"]');

  if (existingIcon) {
    existingIcon.remove();
  }

  document.head.appendChild(link);
}
