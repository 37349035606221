import RepositoryFactory from '@/services/repository-factory';

const statisticsRepository = RepositoryFactory.get('coupons');

export default {
  namespaced: true,

  state: {
    groupBy: 'date',
    params: {
      groupBy: 'date',
      startDate: '',
      endDate: '',
    },
    statisticsDate: null,
  },

  mutations: {
    SET_PARAMS: (state, value) => { state.params = { ...value }; },
    SET_GROUP_BY: (state, value) => { state.groupBy = value; },
    SET_STATISTICS_DATE: (state, value) => { state.statisticsDate = value; },
  },

  actions: {
    setGroupBy({ commit }, value) {
      commit('SET_GROUP_BY', value);
    },
    fetchStatisticsDate({
      commit,
    }, value) {
      statisticsRepository.statisticsDate(value)
        .then((response) => {
          commit('SET_STATISTICS_DATE', response);
        });
    },
    setParams({ commit }, params) {
      commit('SET_PARAMS', params);
    },
  },

  getters: {
    params: (state) => state.params,
    groupBy: (state) => state.groupBy,
    paramsGroupBy: (state) => state.params.groupBy,
    startDate: (state) => state.params?.interval?.[0],
    endDate: (state) => state.params?.interval?.[1],
    statisticsDate: (state) => state.statisticsDate,
  },
};
