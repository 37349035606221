export default function createExternalPanelService(data) {
  return {
    id: data.id,
    name: data.name,
    externalPanelPrice: data.external_panel_price,
    providerPrice: data.provider_price,
    price: data.external_panel_price,
    networkId: data.network_id,
    serviceTypeId: data.service_type_id,
  };
}
