import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import http from '../../http';
import urlFormatter from '../../url-formatter';
import createUpdateRequest from './createUpdateRequest';
import createTableOrderWidgetFromResource from './createTableOrderWidgetFromResource';
import handleUpdateErrors from './handlerUpdateErrors';
import createOrderWidgetFromResource from './createOrderWidgetFromResource';

const getURL = urlFormatter({
  update: '/api/v1/admin/order-widgets/{id}',
  table: '/api/v1/admin/order-widgets',
  show: '/api/v1/admin/order-widgets/{id}',
  statistics: '/api/v1/admin/order-widget-statistics',
});

export default {
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      headersDictionary: {
        payment_methods: 'paymentMethodsIds',
      },
      options,
      search,
    });
    const endpoint = getURL('table');
    const response = await http.get(`${endpoint}?${requestBody}`);

    return {
      items: response.data.data.map(createTableOrderWidgetFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  show(id) {
    return http.get(getURL('show', { id }))
      .then((response) => createOrderWidgetFromResource(response.data.data));
  },
  update(id, formData) {
    const endpoint = getURL('update', { id });

    return http.put(endpoint, createUpdateRequest(formData))
      .catch(handleUpdateErrors);
  },
  statistics({
    groupBy, startDate, endDate, widgets, services,
  }) {
    const endpoint = getURL('statistics');

    return http.get(endpoint, {
      params: {
        group_by: groupBy,
        start_date: startDate,
        end_date: endDate,
        orderWidgets: widgets,
        services,
      },
    })
      .then((response) => response.data.data);
  },
  getURL,
};
