import repositoryFactory from '@/services/repository-factory';
import websockets from '@/websockets';

const websocketsRepository = repositoryFactory.get('websockets');

export default {
  subscribeToCounters({
    state, dispatch,
  }) {
    state.statusesCounters
      .map((c) => c.name)
      .forEach((counterName) => {
        websockets.statusesCounters
          .subscribeToCounter(counterName, (counterValue) => {
            dispatch('setNewStatusesCounters', {
              counterName,
              counterValue,
            });
          });
      });
  },
  applicationCountsUpdated(_, panelId) {
    websockets.statusesCounters.applicationCountsUpdated(
      `admin.panel.${panelId}`,
    );
  },
  setNewStatusesCounters({ commit }, payload) {
    commit('SET_NEW_STATUSES_COUNTERS', payload);
  },
  async getInitialValues({ commit }) {
    const data = await websocketsRepository.init();

    commit('SET_ALL_STATUSES_COUNTERS', data);
  },
};
