import {
  createResetButton,
} from '../builder-definition/filters'; import { createPlainText } from '../builder-definition/filters/page';

export default {
  commonSearch: null,
  id: '',
  name: '',
  endpoint: '',
  apiToken: '',
  statisticType: '',
  currency: '',
  isArchived: 0,
  status: '',
};

export const config = {
  defaultSearchPropName: 'name',
  exceptionFilters: ['status'],
  rows: [
    [
      createPlainText({
        filterProp: 'commonSearch',
        placeholder: 'Search',
      }),
      createResetButton(),
    ],
  ],
};
