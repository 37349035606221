import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import http from '@/services/http';
import urlFormatter from '@/services/url-formatter';
import createWebhookFromResource from '@/classes/createWebhook';
import createWebhookTypeFromResource from '@/classes/createWebhookType';
import handleErrors from '@/services/handle-errors';
import createWebhookStoreRequest from './createWebhookStoreRequest';
import createWebhookStoreErrors from './createWebhookStoreErrors';

const getURL = urlFormatter({
  table: '/api/v1/admin/webhooks',
  update: '/api/v1/admin/webhooks/{id}',
  store: '/api/v1/admin/webhooks',
  types: '/api/v1/admin/webhook-types',
});

export default {
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('table');
    const response = await http.get(`${endpoint}?${requestBody}`);

    return {
      items: response.data.data.map(createWebhookFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  getTypes() {
    const endpointUrl = getURL('types');

    return http.get(endpointUrl)
      .then((response) => response.data.data.map(createWebhookTypeFromResource));
  },
  store(formData) {
    const endpointUrl = getURL('store');

    return http.post(endpointUrl, createWebhookStoreRequest(formData))
      .then((response) => createWebhookFromResource(response.data.data))
      .catch((error) => handleErrors(error, createWebhookStoreErrors));
  },
  update(id, formData) {
    const endpointUrl = getURL('update', { id });

    return http.put(endpointUrl, createWebhookStoreRequest(formData))
      .then((response) => createWebhookFromResource(response.data.data))
      .catch((error) => handleErrors(error, createWebhookStoreErrors));
  },
  getURL,
};
