export default {
  setDiscountModal({
    dispatch,
    commit,
  }, {
    id,
    isShown,
  }) {
    dispatch('showDiscountModal', isShown);

    if (id) {
      dispatch('fetchDiscount', id);
      commit('SET_DISCOUNT_ID', id);
    }
  },
  showDiscountModal({
    commit, dispatch,
  }, isShown) {
    dispatch('resetState');
    commit('SET_SHOWN', isShown);
  },
  setFormData({ commit }, discount) {
    commit('SET_NAME', discount.name);
    commit('SET_SERVICES', discount.services);
    commit('SET_VALID_FROM', discount.validFrom);
    commit('SET_VALID_TO', discount.validTo);
    commit('SET_VALID_TO', discount.validTo);
    commit('SET_CREATED_AT', discount.createdAt);
  },
  fetchDiscount({
    dispatch,
    rootGetters,
  }, id) {
    dispatch('setLoading', true);

    rootGetters['discount/getRepository'].show(id)
      .then((response) => {
        dispatch('setFormData', response);
      })
      .catch((errors) => {
        dispatch('setErrors', errors);
      })
      .finally(() => {
        dispatch('setLoading', false);
      });
  },
  setLoading({ commit }, value) {
    commit('SET_LOADING', value);
  },
  setDiscountName({ commit }, name) {
    commit('SET_NAME', name);
  },
  setValidFrom({ commit }, validFrom) {
    commit('SET_VALID_FROM', validFrom);
  },
  setValidTo({ commit }, validTo) {
    commit('SET_VALID_TO', validTo);
  },
  save({
    state, rootGetters, dispatch,
  }, { errorNotify }) {
    if (state.discountId) {
      rootGetters['discount/getRepository'].update(state.discountId, state.formData)
        .then(() => {
          dispatch('discount/table/fetch', null, { root: true });
          dispatch('showDiscountModal', false);
        })
        .catch((errors) => {
          dispatch('setErrors', errors);

          if (errorNotify) {
            errorNotify();
          }
        });
    } else {
      rootGetters['discount/getRepository'].store(state.formData)
        .then(() => {
          dispatch('discount/table/fetch', null, { root: true });
          dispatch('showDiscountModal', false);
        })
        .catch((errors) => {
          dispatch('setErrors', errors);

          if (errorNotify) {
            errorNotify();
          }
        });
    }
  },
  setServices({ commit }, val) {
    commit('SET_SERVICES', val);
  },
  setErrors({ commit }, errors) {
    commit('SET_ERRORS', errors);
  },
  resetState({ commit }) {
    commit('SET_CLEAR_STATE');
  },
};
