export default function createPaymentMethodBonusFromResource(data) {
  if (!data) {
    return null;
  }

  return {
    bonusPercent: Number(data.bonus_percent) * 100,
    fromAmount: Number(data.from_amount),
    id: data.id,
    isEnabled: data.is_enabled,
  };
}
