import { defaultState } from './state';

export default {
  SET_CLEAR_STATE: (state) => {
    Object.assign(state, defaultState());
  },
  SET_ID: (state, value) => { state.id = value; },
  SET_IS_SHOWN: (state, value) => { state.isShown = value; },
  SET_LOADING: (state, value) => { state.loading = value; },
  SET_SEARCH: (state, value) => { state.search = value; },
  SET_AVAILABLE_SERVICES: (state, value) => { state.availableServices = value; },
  SET_SELECTED: (state, value) => { state.selected = value; },
  SET_SAVE_BTN_LOADING: (state, value) => { state.saveBtnLoading = value; },
  SET_ERRORS_MESSAGE: (state, value) => { state.errorsMessage = value; },
};
