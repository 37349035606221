<template>
  <v-simple-checkbox
    v-bind="$attrs"
    :off-icon="offIcon"
    :on-icon="onIcon"
    :indeterminate-icon="indeterminateIcon"
    class="base-simple-checkbox"
    :ripple="false"
    v-on="$listeners"
  />
</template>
<script>

export default {
  name: 'BaseSimpleCheckbox',
  props: {
    onIcon: {
      type: String,
      default: '$icon-checkbox-on',
    },
    offIcon: {
      type: String,
      default: '$icon-checkbox',
    },
    indeterminateIcon: {
      type: String,
      default: '$icon-checkbox',
    },
  },
};
</script>
