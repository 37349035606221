import dateFormatter from '@/services/functions/date-filter';

export default function createCouponFromResource(data) {
  return {
    id: data.id,
    actions: {
      archivable: data.actions.archivable,
      restore: data.actions.restore,
      editable: data.actions.editable,
    },
    status: data.status,
    displayable: data.displayable,
    name: data.name,
    description: data.description,
    code: data.code,
    paymentType: data.payment_type,
    affiliate: data.affiliate,
    minAmount: data.min_amount,
    validFrom: dateFormatter(data.valid_from),
    validTo: dateFormatter(data.valid_to),
    bonusType: data.bonus_type,
    bonusAmountCalculationType: data.bonus_amount_calculation_type,
    bonusPercentage: Math.round(data.bonus_percentage * 100),
    bonusAmount: data.bonus_amount,
    usageLimit: data.usage_limit,
    workWithServiceDiscount: data.works_with_service_discount,
    workWithUserCustomPrice: data.works_with_user_custom_price,
    useWithOnlyUnpaidOrders: data.use_with_only_unpaid_orders,
    workWithBalance: data.works_with_balance,
    translations: data.translations,
  };
}
