import createExternalPanelServicesFromResource
  from '@/services/repositories/external-panels-repository/createExternalPanelServices';
import createOrderFromResource from '@/classes/createOrder';
import dateFilter from '@/services/functions/date-filter';

export default function createExternalPanelOrderFromResource(data) {
  return {
    actions: {
      recreatable: data.actions.recreatable,
    },
    charge: data.charge,
    externalPanelOrderId: data.external_panel_order_id,
    externalPanelService: createExternalPanelServicesFromResource(data.external_panel_service),
    id: data.id,
    initialCharge: data.initial_charge,
    initialQuantity: data.initial_quantity,
    lastError: data.last_error,
    order: createOrderFromResource(data.order),
    remains: data.remains,
    startCount: data.start_count,
    status: data.status,
    createdAt: dateFilter(data.created_at),
  };
}
