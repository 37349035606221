export default () => ({
  isShown: false,
  saving: false,
  success: false,
  invoiceId: null,
  formData: {
    amount: null,
  },
  errors: {},
});
