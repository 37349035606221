<template>
  <!-- eslint-disable max-len -->
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5C15.8655 5 19 8.13373 19 12C19 15.8655 15.8655 19 12 19C8.13373 19 5 15.8655 5 12C5 8.13373 8.13373 5 12 5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.5 9V15" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.5 9V15" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'IconDanger',
};
</script>
