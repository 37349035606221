<template>
  <ThemeRenderless
    v-slot="{ isDark }"
  >
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!--eslint-disable -->
      <path opacity="0.4" d="M10.6996 16.8981H17.0766" :stroke="isDark ? '#FFFFFF' : '#11142D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M9.8549 1.95573C10.5919 1.01673 11.7829 1.06573 12.7229 1.80273L14.1129 2.89273C15.0529 3.62973 15.3859 4.77273 14.6489 5.71373L6.3599 16.2887C6.0829 16.6427 5.6599 16.8517 5.2099 16.8567L2.0129 16.8977L1.2889 13.7827C1.1869 13.3457 1.2889 12.8857 1.5659 12.5307L9.8549 1.95573Z"  :stroke="isDark ? '#FFFFFF' : '#11142D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path opacity="0.4" d="M8.30261 3.93616L13.0966 7.69416" :stroke="isDark ? '#FFFFFF' : '#11142D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <!--eslint-enable -->
    </svg>
  </ThemeRenderless>
</template>

<script>
import ThemeRenderless from '@/components/ThemeRenderless.vue';

export default {
  components: {
    ThemeRenderless,
  },
};
</script>
