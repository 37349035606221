export const defaultState = () => ({
  providerId: null,
  errors: {},
  errorsMessage: '',
  loading: false,
  presetSettigns: [],
  isOurPanel: false,
  formData: {
    name: '',
    endpoint: '',
    apiToken: '',
    currency: '',
    syncServices: false,
    syncPrices: false,
    syncTextFields: false,
    syncDescriptionField: '',
    syncParams: false,
    syncLabels: false,
    resaleMarginPercent: '',
    isArchived: false,
    autoRecreateOrdersIsAllowed: false,
  },
  providers: [],
});

export default () => ({
  isShown: false,
  saving: false,
  success: false,
  ...defaultState(),
});
