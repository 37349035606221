import createServiceFromResource from '@/classes/createService';
import createUserFromResource from '@/classes/createUser';
import dateFormatter from '@/services/functions/date-filter';
import intervalFormatter from '@/services/functions/interval-filter';

export default function createDripFeeds(data) {
  if (!data) {
    return null;
  }

  return {
    actions: data.actions || {},
    countries: data.countries,
    id: data.id,
    minutesBetweenRuns: intervalFormatter(data.minutes_between_runs),
    name: data.name,
    orders: data.orders,
    ordersCount: data.orders_count,
    price: data.price,
    promotable: data.promotable,
    quantity: data.quantity,
    runs: data.runs,
    service: createServiceFromResource(data.service),
    status: data.status,
    url: data.url,
    user: createUserFromResource(data.user),
    totalQuantity: data.total_quantity,
    createdAt: dateFormatter(data.created_at),
  };
}
