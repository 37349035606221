import stateExtender from '@/store/utils/stateExtender';
import RepositoryFactory from '@/services/repository-factory';
import datatableModule from '@/store/builder/datatableModule';
import table from '@/components/builders/configs/tables/gradesTable';
import filters from '@/components/builders/configs/filters/gradesFilters';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import editorDialog from './submodules/editorDialog';

const gradesRepository = RepositoryFactory.get('grades');
const state = {
  repository: gradesRepository,
  error: null,
};

export default stateExtender({
  namespaced: true,
  state: () => state,
  modules: {
    table: datatableModule({
      fetch: gradesRepository.table,
      headers: table.headers.filter((header) => header.isShown),
      allHeaders: table.headers,
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 30,
      },
      search: filters,
    }),
    editorDialog,
  },
  getters,
  mutations,
  actions,
});
