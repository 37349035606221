import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createDripFeedFromResource from '@/classes/createDripFeed';
import http, { cancelTokenNames } from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  table: '/api/v1/admin/drip-feeds',
  show: '/api/v1/admin/drip-feeds/{id}',
  cancel: '/api/v1/admin/drip-feeds/{dripFeedId}/cancel',
});

export default {
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('table');
    const response = await http.get(`${endpoint}?${requestBody}`, {
      applyCancelAdapter: cancelTokenNames.DRIP_FEED_TABLE,
    });

    return {
      items: response.data.data.map(createDripFeedFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  cancel({ dripFeedId }) {
    const endpoint = getURL('cancel', { dripFeedId });

    return http.post(endpoint);
  },
  getURL,
};
