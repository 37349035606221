export default ({
  mapper,
  statusProp,
  hideTooltip,
  to,
}) => ({
  componentName: 'cell-plain-statuses',
  componentArgs: {
    mapper,
    statusProp,
    hideTooltip,
    to,
  },
});
