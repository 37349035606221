import { datatableFormater } from '@clickadilla/datatable-formatter';
import { stringify } from 'qs';
import createOrderFromResource from '@/classes/createOrder';
import handleErrors from '../handle-errors';
import http, { cancelTokenNames } from '../http';
import urlFormatter from '../url-formatter';

const cancelErrorsHandler = (data) => ({
  returnMoney: data.return_money,
  amount: data.amount,
  cancelReason: data.cancel_reason,
  cancelDescription: data.cancel_description,
  sendEmailNotification: data.send_email_notification,
});
const recreateErrorsHandler = (data) => ({
  providerId: data.provider_id,
  quantity: data.quantity,
});
const getURL = urlFormatter({
  table: '/api/v1/admin/orders',
  showOrder: '/api/v1/admin/orders/{orderId}',
  setHasRefill: '/api/v1/admin/orders/{orderId}/set-has-refill',
  setAutoRefillIsAllowed: 'api/v1/admin/orders/{orderId}/set-auto-refill-is-allowed',
  showStatistic: 'api/v1/admin/orders/statistics',
  promotableStatistics: '/api/v1/admin/orders/promotable-statistics',
  activate: '/api/v1/admin/orders/{orderId}/activate',
  singleCancel: '/api/v1/admin/orders/{orderId}/cancel',
  getDefaultRefundAmount: '/api/v1/admin/orders/{orderId}/refund-amount',
  pause: '/api/v1/admin/orders/{orderId}/pause',
  complete: '/api/v1/admin/orders/{orderId}/complete',
  update: '/api/v1/admin/orders/{orderId}',
  approve: '/api/v1/admin/orders/{orderId}/approve',
  reject: '/api/v1/admin/orders/{orderId}/reject',
  changeUrl: '/api/v1/admin/orders/{orderId}/change-url',
  multipleActivate: '/api/v1/admin/orders/activate',
  multipleCancel: '/api/v1/admin/orders/cancel',
  multiplePause: '/api/v1/admin/orders/pause',
  multipleComplete: '/api/v1/admin/orders/complete',
  fetchAvailableCancelReasons: '/api/v1/admin/orders/cancel-reasons',
  changeProvider: '/api/v1/admin/orders/change-provider',
  updateComment: '/api/v1/admin/orders/{orderId}/update-comment',
});

export default {
  async table({
    headers, options, search, queries,
  }) {
    const {
      type, id,
    } = queries;
    const idParamsNameByType = {
      subscription: 'subscription_id',
      drip_feed: 'drip_feed_id',
      combo_order: 'combo_order_id',
    }[type];
    const requestBody = {
      ...datatableFormater({
        headers,
        options,
        search,
      }),
    };

    if (idParamsNameByType) {
      requestBody[idParamsNameByType] = id;
    }

    const endpoint = getURL('table');
    const response = await http.get(`${endpoint}?${stringify(requestBody)}`, {
      applyCancelAdapter: cancelTokenNames.ORDERS_TABLE,
    });

    return {
      items: response.data.data.map(createOrderFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  show({ orderId }) {
    const endpoint = getURL('showOrder', { orderId });

    return http.get(endpoint)
      .then((response) => ({
        order: createOrderFromResource(response.data.data),
        links: response.data.links,
      }));
  },
  getDefaultRefundAmount({ orderId }) {
    const endpoint = getURL('getDefaultRefundAmount', { orderId });

    return http.get(endpoint)
      .then((response) => ({ amount: response.data.data.amount }));
  },
  setHasRefill({
    orderId, hasRefill,
  }) {
    const endpoint = getURL('setHasRefill', { orderId });

    return http.post(endpoint, { has_refill: hasRefill });
  },
  setAutoRefillIsAllowed({
    orderId, autoRefillIsAllowed,
  }) {
    const endpoint = getURL('setAutoRefillIsAllowed', { orderId });

    return http.post(endpoint, {
      auto_refill_is_allowed: autoRefillIsAllowed,
    });
  },
  statistic({
    groupBy, endDate, startDate, orders,
  }) {
    const endpoint = getURL('showStatistic');

    return http.get(endpoint, {
      params: {
        groupBy,
        endDate,
        startDate,
        orders,
      },
    })
      .then((response) => response.data.data);
  },
  getPromotableStatistics({
    order,
  }) {
    const endpoint = getURL('promotableStatistics');

    return http.get(endpoint, {
      params: {
        order,
      },
    })
      .then((response) => response.data.data);
  },
  activate({ orderId }) {
    const endpoint = getURL('activate', { orderId });

    return http.post(endpoint);
  },
  approve({ orderId }) {
    const endpoint = getURL('approve', { orderId });

    return http.post(endpoint);
  },
  reject({ orderId }) {
    const endpoint = getURL('reject', { orderId });

    return http.post(endpoint);
  },
  pause({ orderId }) {
    const endpoint = getURL('pause', { orderId });

    return http.post(endpoint);
  },
  fetchAvailableCancelReasons() {
    const endpoint = getURL('fetchAvailableCancelReasons');

    return http.get(endpoint)
      .then((response) => response.data.data.map((item) => ({
        label: item.label,
        type: item.type,

      })));
  },
  complete({ orderId }) {
    const endpoint = getURL('complete', { orderId });

    return http.post(endpoint);
  },
  multipleActivate({ ordersIds }) {
    const endpoint = getURL('multipleActivate');

    return http.post(endpoint, { order_ids: ordersIds })
      .catch((error) => handleErrors(error));
  },
  singleCancel({
    orderId,
    cancelReason,
    cancelDescription,
    returnMoney,
    amount,
    sendEmailNotification,
  }) {
    const endpoint = getURL('singleCancel', { orderId });

    return http.post(endpoint, {
      return_money: returnMoney,
      cancel_reason: cancelReason,
      cancel_description: cancelDescription,
      amount,
      send_email_notification: sendEmailNotification,
    })
      .catch((error) => handleErrors(error, cancelErrorsHandler));
  },
  multipleCancel({
    ordersIds,
    returnMoney,
    cancelReason,
    cancelDescription,
    sendEmailNotification,
  }) {
    const endpoint = getURL('multipleCancel');

    return http.post(endpoint, {
      order_ids: ordersIds,
      return_money: returnMoney,
      cancel_reason: cancelReason,
      cancel_description: cancelDescription,
      send_email_notification: sendEmailNotification,

    })
      .catch((error) => handleErrors(error, cancelErrorsHandler));
  },
  multiplePause({ ordersIds }) {
    const endpoint = getURL('multiplePause');

    return http.post(endpoint, { order_ids: ordersIds })
      .catch((error) => handleErrors(error));
  },
  multipleComplete({ ordersIds }) {
    const endpoint = getURL('multipleComplete');

    return http.post(endpoint, { order_ids: ordersIds })
      .catch((error) => handleErrors(error));
  },
  changeProvider({
    orderIds, providerId, quantity,
  }) {
    const endpoint = getURL('changeProvider');

    return http.post(endpoint, {
      provider_id: providerId,
      order_ids: orderIds,
      ...(quantity ? { quantity } : {}),
    })
      .catch((error) => handleErrors(error, recreateErrorsHandler));
  },
  updateComment({
    orderId, comment,
  }) {
    const endpoint = getURL('updateComment', { orderId });

    return http.post(endpoint, {
      comment,
    })
      .catch((error) => handleErrors(error, recreateErrorsHandler));
  },
  async changeUrl({
    orderId,
    url,
  }) {
    const endpoint = getURL('changeUrl', { orderId });

    try {
      const response = await http.post(endpoint, {
        url,
      });

      return response;
    } catch (error) {
      return handleErrors(error);
    }
  },
  getURL,
};
