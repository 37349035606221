export default ({
  textFormatter, dispatchText, clearDispatchPath,
}) => ({
  type: 'filter-queries',
  'max-width': '290px',
  'min-width': '130px',
  attrs: {
    textFormatter,
    dispatchText,
    clearDispatchPath,
  },
});
