import settings from '@/settings';
import {
  createDateFilter,
  createTextFilter,
} from '../builder-definition/filters/table';
import {
  createCellActions, createCellPlainStatuses,
} from '../builder-definition/tables';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      width: '68px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
      ...createCellPlainStatuses({
        mapper: 'discount',
        statusProp: 'status',
      }),
    },
    {
      text: 'Name',
      value: 'name',
      width: '120px',
      hideable: false,
      customFilter: createTextFilter({ placeholder: 'Search Name' }),
      isShown: true,
    },
    {
      name: 'created_at',
      value: 'createdAt',
      text: 'Created at',
      width: '90px',
      isShown: true,
      customFilter: createDateFilter(),
    },
    {
      name: 'valid_from',
      value: 'validFrom',
      text: 'Valid from',
      width: '90px',
      isShown: true,
      customFilter: createDateFilter(),
    },
    {
      name: 'valid_to',
      value: 'validTo',
      text: 'Valid to',
      width: '90px',
      isShown: true,
      customFilter: createDateFilter(),
    },
    {
      text: 'Status',
      value: 'status',
      isHidden: true,
    },
    {
      value: 'actions',
      text: 'Actions',
      width: '60px',
      searchable: false,
      sortable: false,
      isShown: true,
      permission: settings.permissions.manageDiscounts,
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Archive',
              value: 'archivable',
              prop: 'archivable',
              callBack: (item, store) => {
                store.dispatch('discount/archive', item.id);
              },
            },
            {
              text: 'Unarchive',
              value: 'unarchivable',
              prop: 'unarchivable',
              callBack: (item, store) => {
                store.dispatch('discount/unarchive', item.id);
              },
            },
            {
              text: 'Complete',
              value: 'completable',
              prop: 'completable',
              callBack: (item, store) => {
                store.dispatch('discount/complete', item.id);
              },
            },
            {
              text: 'Edit',
              value: 'editable',
              prop: 'editable',
              callBack: (item, store) => {
                store.dispatch('discount/discountModal/setDiscountModal', {
                  id: item.id,
                  isShown: true,
                });
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;

            return tableElement.actions[prop]
              ? tableElement.actions[prop]
              : false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
