export const defaultState = () => ({
  selectedLocaleInner: 'en',
  selectedWithdrawLocaleInner: 'en',
  previewIcon: null,
  previewQrCode: null,
  fileName: '',
  iconName: 'Payment method icon',
  qrCodeName: 'QR code',
  selectedTranslation: {},
  selectedWithdrawTranslation: {},
  isAllowedForNewUser: true,
  paymentMethodList: [],
});

export const defaultPaymentMethod = () => ({
  allowQrCode: false,
  paymentQrCode: '',
  createdAt: '',
  chargeCurrency: '',
  chargeType: '',
  isEnabled: 0,
  icon: '',
  id: null,
  isAllowedForNewUser: 0,
  maxAmount: null,
  minAmount: null,
  feePercent: null,
  position: 1,
  name: '',
  paymentMethodBonuses: [],
  strategy: '',
  type: '',
  description: '',
  translations: {},
  withdrawalOptions: {
    availableForWithdrawal: false,
    feePercent: null,
    maxAmount: null,
    minAmount: null,
    minFee: null,
  },
  publicFields: {
    isUserComment: false,
    qrCode: '',
  },
});

export default () => ({
  isShown: false,
  typeModal: '',
  paymentMethodId: null,
  collections: [],
  selectedMethodInner: null,
  emptyPaymentMethod: {},
  ...defaultState(),
  paymentMethod: { ...defaultPaymentMethod() },
  errors: {},
  errorMessage: '',
});
