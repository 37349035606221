import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createDiscountFromResource from '@/classes/createDiscount';
import urlFormatter from '@/services/url-formatter';
import http, { cancelTokenNames } from '@/services/http';
import createCouponsStoreErrors from './createDiscountStoreErrors';
import createCouponsStoreRequest from './createDiscountStoreRequest';

const getURL = urlFormatter({
  list: '/api/v1/admin/discounts',
  show: '/api/v1/admin/discounts/{discountId}',
  store: '/api/v1/admin/discounts',
  update: '/api/v1/admin/discounts/{discountId}',
  complete: '/api/v1/admin/discounts/{discountId}/complete',
  archive: '/api/v1/admin/discounts/{discountId}/archive',
  unarchive: '/api/v1/admin/discounts/{discountId}/unarchive',
});

export default {
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const statusAll = search.status === '';
    const endpoint = getURL('list');
    const response = await http.get(`${endpoint}?${requestBody}&is_all=${statusAll}`, {
      applyCancelAdapter: cancelTokenNames.DISCOUNT_TABLE,
    });

    return {
      items: response.data.data.map(createDiscountFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  show(discountId) {
    const endpoint = getURL('show', { discountId });

    return http.get(endpoint)
      .then((response) => createDiscountFromResource(response.data.data));
  },
  update(discountId, formData) {
    const endpoint = getURL('update', { discountId });

    return http.put(endpoint, createCouponsStoreRequest(formData))
      .then((response) => createDiscountFromResource(response.data.data))
      .catch((error) => {
        if (error.response) {
          throw createCouponsStoreErrors(error.response.data);
        } else {
          throw error;
        }
      });
  },
  store(formData) {
    const endpoint = getURL('store');

    return http.post(endpoint, createCouponsStoreRequest(formData))
      .then((response) => createDiscountFromResource(response.data.data))
      .catch((error) => {
        if (error.response) {
          throw createCouponsStoreErrors(error.response.data);
        } else {
          throw error;
        }
      });
  },
  complete({ discountId }) {
    const endpoint = getURL('complete', { discountId });

    return http.post(endpoint);
  },
  archive({ discountId }) {
    const endpoint = getURL('archive', { discountId });

    return http.post(endpoint);
  },
  unarchive({ discountId }) {
    const endpoint = getURL('unarchive', { discountId });

    return http.post(endpoint);
  },
};
