import { defaultDialogSetupBuilder } from '@/components/services/dialog/dialogSetup';

export default (invokeArgs = {}) => {
  defaultDialogSetupBuilder(
    {
      isModalShown: true,
      confirmTitle: 'Edit "Custom comments" feature',
      confirmText: `Are you sure you want to edit feature "Custom comments"? It is used in the following services:
 <ul>${invokeArgs.attaches.map((attache) => `<li style="color: #6B6B6B;">${attache.name}</li>`)}</ul>`,
      confirmBtnColor: 'invokedDialogBlack',
      confirmBtnText: 'Save',
      ...invokeArgs,
    },
  );
};
