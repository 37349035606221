const abstractTranslator = (data, fieldMap, direction = 'receive') => {
  if (!data) {
    throw new Error('No data to translate');
  }

  if (!fieldMap) {
    throw new Error('No field map to translate');
  }

  const getFieldNames = (appFieldName, serverSideName) => ({
    source: direction === 'send' ? appFieldName : serverSideName,
    destination: direction === 'send' ? serverSideName : appFieldName,
  });
  const newObj = {};

  Object.entries(fieldMap).forEach(([appSideFieldName, mapConfig]) => {
    if (typeof mapConfig === 'string') {
      const fieldNames = getFieldNames(appSideFieldName, mapConfig);

      newObj[fieldNames.destination] = data[fieldNames.source];

      return;
    }

    const fieldConfig = mapConfig[direction];

    if (typeof mapConfig[direction] === 'string') {
      const fieldNames = getFieldNames(appSideFieldName, mapConfig[direction]);

      newObj[fieldNames.destination] = data[fieldNames.source];

      return;
    }

    if (typeof fieldConfig?.name === 'string') {
      fieldConfig.skip = fieldConfig.skip ?? false;
      fieldConfig.handler = (typeof fieldConfig.handler === 'function' && fieldConfig.handler) || ((val) => val);

      const fieldNames = getFieldNames(appSideFieldName, fieldConfig.name);

      if (fieldConfig.skip === true) {
        return;
      }

      if (typeof fieldConfig.skip === 'function') {
        if (fieldConfig.skip(data[fieldNames.source])) {
          return;
        }
      }

      newObj[fieldNames.destination] = fieldConfig.handler(data[fieldNames.source]) ?? null;

      return;
    }

    throw new Error('Error in field map syntax for transform');
  });

  return newObj;
};
const translateToCamelByFieldMap = (data, fieldMap) => abstractTranslator(data, fieldMap, 'receive');
const translateToSnakeByFieldMap = (data, fieldMap) => abstractTranslator(data, fieldMap, 'send');

export default {
  receive: translateToCamelByFieldMap,
  send: translateToSnakeByFieldMap,
};
