import fieldMapper from '@/services/helpers/fieldMapper';

const themes = ['dark', 'light'];
const colorKeyMap = {
  primary: 'primary',
  secondary: 'secondary',
  headerLeft: 'header_left',
  headerRight: 'header_right',
  icons: 'icons',
  info: 'info',
  success: 'success',
  warning: 'warning',
};

export default class PanelColors {
  constructor(colorsData) {
    themes.forEach((theme) => {
      this[theme] = fieldMapper.receive(colorsData[theme], colorKeyMap);
    });
  }

  getDataForRequest() {
    const colorsRequestData = {};

    themes.forEach((theme) => {
      colorsRequestData[theme] = fieldMapper.send(this[theme], colorKeyMap);
    });

    return colorsRequestData;
  }
}
