import dateFormatter from '@/services/functions/date-filter';

export default function createAffiliatesResponse(data) {
  return {
    name: data.name,
    email: data.email,
    startedAt: dateFormatter(data.started_at),
    endedAt: dateFormatter(data.ended_at),
    id: data.user_id,
    referralId: data.referral_id,
  };
}
