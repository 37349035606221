const availableFieldNames = {
  qr_code: 'qrCode',
  is_user_comment: 'isUserComment',
};

export default (field) => ({
  default: field.default,
  name: availableFieldNames[field.name] || field.name,
  options: field.options,
  rules: field.rules,
  type: field.type,
});
