import createUserFromResource from '@/classes/createUser';

export default function createTableOrderWidgetFromResource(orderWidgetResource) {
  return {
    id: orderWidgetResource.id,
    name: orderWidgetResource.name,
    user: createUserFromResource(orderWidgetResource.user),
    isOuter: orderWidgetResource.is_outer,
    apiToken: orderWidgetResource.api_token,
  };
}
