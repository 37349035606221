export default function createPackagesTotalCost(data) {
  if (!data) {
    return null;
  }

  return {
    defaultCost: data.default_cost,
    difference: data.difference,
    totalCost: data.total_cost,
    packageContents: data.package_contents.reduce((acc, packageContent) => {
      acc[packageContent.id] = {
        id: packageContent.id,
        cost: packageContent.cost,
        price: packageContent.price,
        quantity: packageContent.quantity,
      };

      return acc;
    }, {}),
  };
}
