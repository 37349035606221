export default {
  isShown: (state) => state.isShown,
  collections: (state) => state.collections,
  selectCollections: (state) => state.selectCollections,
  loading: (state) => state.loading,
  editingProvider: (state) => state.editingProvider,
  externalPanelId: (state) => state.formData.externalPanelId,
  externalPanelServiceId: (state) => state.formData.externalPanelServiceId,
  sourcePricingPer: (state) => state.formData.sourcePricingPer,
  sourcePrice: (state) => state.formData.sourcePrice,
  isArchived: (state) => state.formData.isArchived,
  type: (state) => state.formData.type,
  name: (state) => state.formData.name,
  errors: (state) => state.errors,
};
