function createTranslationsErrors(errors) {
  const translationFieldsDictionary = {
    name: 'name',
    description: 'description',
  };

  return Object.entries(errors).reduce((acc, [key, errorMessages]) => {
    const match = key.match(/^translations\.(.{2})\.(.+)/);

    if (!match) {
      return acc;
    }

    const locale = match[1];
    const property = match[2];
    const processedKey = `translations.${locale}.${translationFieldsDictionary[property]}`;

    return {
      ...acc,
      [processedKey]: errorMessages,
    };
  }, {});
}

export default function createCouponsStoreErrors(errors) {
  return {
    ...createTranslationsErrors(errors),
    code: errors.code,
    minAmount: errors.min_amount,
    paymentType: errors.payment_type,
    bonusType: errors.bonus_type,
    bonusAmountCalculationType: errors.bonus_amount_calculation_type,
    validTo: errors.valid_to,
    bonusAmount: errors.bonus_amount,
    bonusPercentage: errors.bonus_percentage,
    affiliate: errors.affiliate,
    validFrom: errors.valid_from,
    usageLimit: errors.usage_limit,
    workWithServiceDiscount: errors.works_with_service_discount,
    workWithUserCustomPrice: errors.works_with_user_custom_price,
    useWithOnlyUnpaidOrders: errors.use_with_only_unpaid_orders,
    workWithBalance: errors.works_with_balance,
  };
}
