import fieldMapper from '@/services/helpers/fieldMapper';

const orderTypesMap = {
  single: 'single',
  multi: 'multi',
  autopromotion: 'autopromotion',
  expert: 'expert',
};

export default class ShowCreateOrderTypes {
  constructor(types = {}) {
    Object.assign(this, fieldMapper.receive(types, orderTypesMap));
  }

  getDataForRequest() {
    return fieldMapper.send(this, orderTypesMap);
  }
}
