import invokeDialog from '@/components/services';
import {
  createCellActions, createCellLink, createCellPlainStatuses, createCellRuns,
} from '../builder-definition/tables';
import { createCellUrl } from '../builder-definition/tables/plain-builders';
import {
  createAsyncSelectFilter, createDateFilter, createTextFilter,
} from '../builder-definition/filters/table';

export default {
  headers: [
    {
      text: 'ID',
      value: 'id',
      hideable: false,
      name: 'subscriptions.id',
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
      width: '100px',
      isShown: true,
      ...createCellPlainStatuses({
        mapper: 'subscription',
        statusProp: 'status',
      }),
    },
    {
      text: 'URL',
      value: 'url',
      width: '130px',
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search URL' }),
      ...createCellUrl(),
    },
    {
      text: 'User',
      value: 'user',
      name: 'users.email',
      width: '153px',
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search User' }),
      ...createCellLink({
        textProp: 'user.email',
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.user.id,
          },
        }),
      }),
    },
    {
      text: 'Content',
      value: 'contentsLimit',
      name: 'contents_limit',
      width: '86px',
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search Content' }),
      ...createCellRuns({
        currentProp: 'subscriptionContentsCount',
        to: (item) => ({
          name: 'Orders',
          query: {
            type: 'subscription',
            id: item.id,
          },
        }),
      }),
    },
    {
      text: 'Billing type',
      value: 'billingType',
      name: 'billing_type',
      width: '104px',
      customFilter: createAsyncSelectFilter({
        config: {
          listGetterName: 'subscriptions/getSubscriptionsCollections',
          listCheckerName: 'subscriptions/checksubscriptionsCollections',
          listLoadingName: 'subscriptions/getSubscriptionsCollectionsIsLoading',
        },
      }),
      isShown: true,
    },
    {
      text: 'Created at',
      value: 'createdAt',
      width: '200px',
      customFilter: createDateFilter(),
      name: 'subscriptions.created_at',
      isShown: true,
    },
    {
      text: 'Completed at',
      value: 'completedAt',
      width: '200px',
      customFilter: createDateFilter(),
      name: 'completed_at',
      isShown: true,
    },
    {
      text: 'Status',
      value: 'status',
      isHidden: true,
    },
    {
      text: 'Actions',
      value: 'actions',
      searchable: false,
      sortable: false,
      width: '100px',
      isShown: true,
      ...createCellActions({
        actionItems: (tableElement, contextStore) => {
          const defaultActions = [
            {
              text: 'Cancel',
              value: 'cancelable',
              prop: 'cancelable',
              callBack: (item, store) => {
                invokeDialog({
                  confirmCallback: () => {
                    store.dispatch('subscriptions/cancelSubscription', item.id);
                  },
                }, 'cancelSubscriprion');
              },
            },
          ];
          const excluded = [];

          defaultActions.forEach((action) => {
            if (action.permission && !contextStore.getters['auth/checkPermission'](action.permission)) {
              excluded.push(action.value);
            }
          });

          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;
            if (tableElement.actions[prop]) return true;

            return false;
          });

          return filteredActions.filter(({ value }) => !excluded.includes(value));
        },
      }),
    },
  ],
};
