import camelize from '@/services/functions/camelize';

export default function createPriceModification(data) {
  if (!data) {
    return null;
  }

  return {
    costAfter: data.cost_after,
    costBefore: data.cost_before,
    costDiff: data.cost_diff,
    id: data.id,
    modifierId: data.modifier_id,
    name: data.name,
    type: camelize(data.type),
  };
}
