export default class PanelCurrencies {
  constructor(currencies) {
    this.default = currencies.default;
    this.exchangeRates = currencies.exchange_rates;
  }

  getDataForRequest() {
    return {
      default: this.default,
      exchange_rates: {
        RUB: this.exchangeRates?.RUB || 'null',
      },
    };
  }
}
