import {
  createTargetAttachmentTypeResponse,
  createTargetAttachmentTypeRequest,
} from './createTargetAttachmentType';

export function createTargetAttachmentResponse(data) {
  return {
    id: data.id,
    name: data.name || '',
    isSystem: data.is_system || null,
    selectables: data.selectables ? data.selectables.map((item) => item.id) : [],
    type: createTargetAttachmentTypeResponse(data.target_type),
  };
}

export function createTargetAttachmentRequest(data) {
  return {
    test: false,
    name: data.name,
    is_system: data.isSystem,
    selectable_ids: data.selectables,
    target_type: createTargetAttachmentTypeRequest(data.type),
  };
}
