import { defaultState } from './state';

export default {
  SET_CLEAR_STATE: (state) => {
    Object.assign(state, defaultState());
  },
  SET_IS_SHOWN: (state, val) => { state.isShown = val; },
  SET_IS_SHOWN_NETWORKS: (state, val) => { state.isShownNetworks = val; },
  SET_IS_SHOWN_SERVICE_TYPES: (state, val) => { state.isShownServiceTypes = val; },
  SET_NETWORK_ID: (state, val) => { state.networkId = val; },
  SET_SERVICE_TYPE_ID: (state, val) => { state.serviceTypeId = val; },
  SET_ERRORS: (state, val) => { state.errors = val; },
  SET_NETWORKS: (state, val) => { state.networks = val; },
  SET_SERVICES: (state, val) => { state.services = val; },
  SET_SERVICE_TYPE: (state, val) => { state.serviceType = val; },
  SET_IS_CREATED: (state, val) => { state.isCreated = val; },
  SET_SELECTED_LOCALE: (state, val) => { state.selectedLocale = val; },
  SET_SELECTED_ICON: (state, val) => { state.serviceType.icon = val; },
  SET_TRANSLATIONS: (state, locale) => {
    state.serviceType.translations[locale] = {
      name: '',
      description: '',
      metaDescription: '',
      metaTitle: '',
    };
  },
  UPDATE_TRANSLATIONS: (state, {
    key,
    value,
  }) => {
    state.serviceType.translations = {
      ...state.serviceType.translations,
      [state.selectedLocale]: {
        ...state.serviceType.translations[state.selectedLocale],
        [key]: value,
      },
    };
  },
};
