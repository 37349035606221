import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createProviderFromResource from '@/classes/createProvider';
import createAvailableProviderFromResource from '@/classes/createAvalibleProvider';
import createValidationError from '@/services/functions/create-validation-error';
import urlFormatter from '../url-formatter';
import http, { cancelTokenNames } from '../http';

const getURL = urlFormatter({
  collections: '/api/v1/admin/providers/collections',
  list: '/api/v1/admin/providers',
  show: '/api/v1/admin/providers/{id}',
  getAvailable: '/api/v1/admin/providers',
  store: '/api/v1/admin/providers',
  update: '/api/v1/admin/providers/{id}',
  availableForOrderRecreate: '/api/v1/admin/providers/available-for-order-recreate',
  archive: '/api/v1/admin/providers/{id}/archive',
  unarchive: '/api/v1/admin/providers/{id}/unarchive',
});
const errorDictionary = {
  name: 'name',
  sourcePrice: 'source_price',
  sourcePricingPer: 'source_pricing_per',
  type: 'type',
  externalPanelId: 'external_panel_service.external_panel_id',
  externalPanelServiceId: 'external_panel_service.external_panel_service_id',
};

function createRequestData({
  name,
  sourcePrice,
  sourcePricingPer,
  type,
  externalPanelId,
  externalPanelServiceId,
}) {
  return {
    name,
    source_price: sourcePrice,
    source_pricing_per: sourcePricingPer,
    type,
    ...(
      type === 'external_panel'
        ? {
          external_panel_service: {
            external_panel_id: externalPanelId,
            external_panel_service_id: externalPanelServiceId,
          },
        }
        : {}
    ),
  };
}

export default {
  show(id) {
    const endpoint = getURL('show', { id });

    return http.get(endpoint)
      .then((response) => createProviderFromResource(response.data.data));
  },
  collections() {
    const endpoint = getURL('collections');

    return http.get(endpoint)
      .then((response) => {
        const { data } = response.data;

        return {
          eventTypes: data.event_types,
          types: data.types,
          providers: data.providers,
        };
      });
  },
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('list');
    const response = await http.get(`${endpoint}?${requestBody}`, {
      applyCancelAdapter: cancelTokenNames.PROVIDERS_SERVICES_TABLE,
    });

    return {
      items: response.data.data.map(createProviderFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  list() {
    const endpoint = getURL('list');

    return http.get(endpoint)
      .then((response) => response.data.data);
  },
  store(formData) {
    const endpoint = getURL('store');

    return http.post(endpoint, createRequestData(formData))
      .catch(createValidationError(errorDictionary));
  },
  getAvailable({
    serviceId, orderId,
  } = {}) {
    const endpoint = getURL('getAvailable');

    return http.get(endpoint, {
      params: {
        service_id: serviceId,
        order_id: orderId,
      },
    })
      .then((response) => response.data.data.map(createProviderFromResource));
  },
  update(id, formData) {
    const endpoint = getURL('update', { id });

    return http.put(endpoint, createRequestData(formData))
      .catch(createValidationError(errorDictionary));
  },
  availableForOrderRecreate(name) {
    const endpoint = getURL('availableForOrderRecreate');

    return http.get(endpoint, {
      params: {
        name,
      },
    })
      .then((response) => response.data.data.map(createAvailableProviderFromResource));
  },
  availableForOrderRecreateByPage(page) {
    const endpoint = getURL('availableForOrderRecreate');

    return http.get(endpoint, {
      params: {
        page,
      },
    })
      .then((response) => ({
        items: response.data.data.map(createAvailableProviderFromResource),
        lastPage: response.data.last_page,
        currentPage: response.data.current_page,
      }));
  },
  async archive(id) {
    const endpoint = getURL('archive', { id });

    try {
      const { data } = await http.post(endpoint);

      return createProviderFromResource(data.data);
    } catch (error) {
      return createValidationError(errorDictionary);
    }
  },
  async unarchive(id) {
    const endpoint = getURL('unarchive', { id });

    try {
      const { data } = await http.post(endpoint);

      return createProviderFromResource(data.data);
    } catch (error) {
      return createValidationError(errorDictionary);
    }
  },
  getURL,
};
