import createUserServiceFromResource from '@/classes/createUserService';
import http from '../http';
import urlFormatter from '../url-formatter';
import handleErrors from '../handle-errors';

const getURL = urlFormatter({
  list: '/api/v1/admin/users/{userId}/user-services',
  store: '/api/v1/admin/users/{userId}/user-services',
  update: '/api/v1/admin/users/{userId}/user-services/{userServiceId}',
  delete: '/api/v1/admin/users/{userId}/user-services/{userServiceId}',
  bulk: '/api/v1/admin/users/{userId}/user-services/bulk',
});
const createSaveRequest = (formData) => ({
  service_id: formData.serviceId,
  min_price: formData.minPrice,
  default_price: formData.newPrice,
  auto_refill_is_allowed: formData.autoRefillIsAllowed,
  auto_refill_once_fill_percent: formData.autoRefillOnceFillPercent,
  auto_refill_min_drop_percent: formData.autoRefillMinDropPercent,
  auto_refill_max_drop_percent: formData.autoRefillMaxDropPercent,
  auto_refill_max_total_refilled_percent: formData.autoRefillMaxTotalRefilledPercent,
  max_order_quantity_for_use_throttle: formData.maxOrderQuantityForUseThrottle,
  order_quantity_ratio: formData.orderQuantityRatio,
  has_refill: formData.hasRefill,
});
const createValidationErrors = (errors) => ({
  serviceId: errors.service_id,
  minPrice: errors.min_price,
  newPrice: errors.default_price,
  autoRefillIsAllowed: errors.auto_refill_is_allowed,
  autoRefillOnceFillPercent: errors.auto_refill_once_fill_percent,
  autoRefillMinDropPercent: errors.auto_refill_min_drop_percent,
  autoRefillMaxDropPercent: errors.auto_refill_max_drop_percent,
  autoRefillMaxTotalRefilledPercent: errors.auto_refill_max_total_refilled_percent,
  maxOrderQuantityForUseThrottle: errors.max_order_quantity_for_use_throttle,
  orderQuantityRatio: errors.order_quantity_ratio,
  hasRefill: errors.has_refill,
});

export default {
  list({ userId }) {
    const endpoint = getURL('list', { userId });

    return http.get(endpoint)
      .then((response) => response.data.data.map(createUserServiceFromResource));
  },
  store(userId, formData) {
    const endpoint = getURL('store', { userId });

    return http.post(endpoint, createSaveRequest(formData))
      .catch((error) => handleErrors(error, createValidationErrors));
  },
  update(userId, userServiceId, formData) {
    const endpoint = getURL('update', {
      userId,
      userServiceId,
    });

    return http.patch(endpoint, createSaveRequest(formData))
      .catch((error) => handleErrors(error, createValidationErrors));
  },
  delete({
    userId,
    userServiceId,
  }) {
    const endpoint = getURL('delete', {
      userId,
      userServiceId,
    });

    return http.delete(endpoint);
  },
  bulk(userId, services) {
    const endpoint = getURL('bulk', { userId });

    return http.post(endpoint, {
      user_services: services.map((service) => ({
        service_id: service.id,
        default_price: service.newPrice,
        min_price: service.newPrice,
      })),
    });
  },
  getURL,
};
