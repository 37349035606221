import {
  createResetButton,
} from '../builder-definition/filters'; import { createPlainText } from '../builder-definition/filters/page';

export default {
  commonSearch: null,
  name: '',
  received: '',
  spent: '',
  usages: '',
  status: '',
};

export const config = {
  defaultSearchPropName: 'coupon_translations.name',
  exceptionFilters: ['status'],
  rows: [
    [
      createPlainText({
        filterProp: 'commonSearch',
        placeholder: 'Search',
      }),
      createResetButton(),
    ],
  ],
};
