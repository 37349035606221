export default {
  prices: {
    fixedCost: {
      key: 'fixed_cost',
      converting: (value) => value,
    },
    discountPercent: {
      key: 'discount_percent',
      converting: (value) => (Number(value) / 100).toFixed(3),
    },
  },
};
