import { createTargetAttachmentResponse } from '@/classes/createTargetAttachment';
import { createTargetAttachmentTypeResponse } from '@/classes/createTargetAttachmentType';
import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  list: '/api/v1/admin/targets/list',
  listTypes: '/api/v1/admin/target-attachments-types',
});

export default {
  async listTargets() {
    const endpoint = getURL('list');
    const response = await http.get(endpoint);

    return response.data.data.map((item) => createTargetAttachmentResponse(item));
  },
  async listTypes() {
    const endpoint = getURL('listTypes');
    const response = await http.get(endpoint);

    return response.data.data.types.map((item) => createTargetAttachmentTypeResponse(item));
  },

  getURL,
};
