export default function createBulkServicesEditRequest(services, formData) {
  return {
    services: services.map(
      (service) => ({
        id: service.id,
        ...Object.entries({
          is_enabled: formData.isEnabled,
          is_archived: formData.isArchived,
          drip_feed_is_allowed: formData.dripFeedIsAllowed,
          api_access: formData.apiAccess,
          restricted_access: formData.restrictedAccess,
          moderation_is_enabled: formData.moderationIdEnabled,
          has_refill: formData.hasRefill,
          sync_prices: formData.syncPrices,
          sync_params: formData.syncParams,
          sync_text_fields: formData.syncTextFields,
          sync_description_field: formData.syncDescriptionField,
          sync_short_description: formData.syncShortDescriptionField,
          sync_reviews: formData.syncReviewsField,
          resale_margin_percent: formData.resaleMarginPercent,
          forbids_orders_with_same_url: formData.forbidsOrdersWithSameUrl,
          cancel_by_client_is_allowed: formData.cancelByClientIsAllowed,
          auto_cancel: formData.autoCancel,
        })
          .reduce((acc, [key, value]) => {
            if (value !== null) {
              acc[key] = value;
            }

            return acc;
          }, {}),
      }),
    ),
  };
}
