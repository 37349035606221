export default {
  isShown: (state) => state.isShown,
  isShownNetworks: (state) => state.isShownNetworks,
  isShownServiceTypes: (state) => state.isShownServiceTypes,
  serviceTypeId: (state) => state.serviceTypeId,
  networksList: (state) => state.networks,
  servicesList: (state) => state.services,
  isCreated: (state) => state.isCreated,
  selectedLocale: (state) => state.selectedLocale,
  network: (state) => state.serviceType.network,
  translations: (state) => state.serviceType.translations,
  selectedIcon: (state) => state.serviceType.icon,
  fullDescription: (state) => state.serviceType.translations[state.selectedLocale].fullDescription,
  name: (state) => state.serviceType.translations[state.selectedLocale].name,
  metaDescription: (state) => state.serviceType.translations[state.selectedLocale].metaDescription,
  metaTitle: (state) => state.serviceType.translations[state.selectedLocale].metaTitle,
};
