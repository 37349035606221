import createUserFromResource from '@/classes/createUser';
import createSubscriptionContentByResource from './createSubscriptionContent';
import createSubscriptionServiceByResource from './createSubscriptionService';

function date(rawDateString) {
  if (!rawDateString) {
    return rawDateString;
  }

  return rawDateString.replace(/[TZ]/g, ' ');
}

export default function createSubscriptionByResource(data) {
  return {
    id: data.id,
    name: data.name,
    url: data.url,
    type: data.type,
    user: createUserFromResource(data.user),
    status: data.status,
    contentsLimit: data.contents_limit,
    subscriptionContentsCount: data.subscription_contents_count,
    subscriptionServices: data.subscription_services
      ? data.subscription_services.map(createSubscriptionServiceByResource)
      : [],
    subscriptionContents: data.subscription_contents
      ? data.subscription_contents.map(createSubscriptionContentByResource)
      : data.subscription_contents,
    recurringPaymentMethods: data.recurring_payment_methods,
    billingType: data.billing_type,
    expiredAt: date(data.expired_at),
    createdAt: date(data.created_at),
    completedAt: date(data.completed_at),
    actions: data.actions,
  };
}
