<template functional>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!--eslint-disable -->
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.1875 24.5847V9.41399C33.1875 4.12899 29.4442 0.812744 24.147 0.812744H9.853C4.55575 0.812744 0.8125 4.11149 0.8125 9.41399L0.8125 24.5847C0.8125 29.8872 4.55575 33.1877 9.853 33.1877H24.147C29.4442 33.1877 33.1875 29.8872 33.1875 24.5847Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.1505 17.0002H9.84949" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.564 10.4413L24.151 17.0003L17.564 23.5593" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <!--eslint-enable -->
  </svg>
</template>

<script>
export default {
};
</script>
