export const defaultState = () => ({
  externalPanelId: null,
  billings: [],
  isShown: false,
  search: {},
  errors: {},
});

export default () => ({
  isShown: false,
  ...defaultState(),
});
