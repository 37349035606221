export default ({
  typeColumn,
  icon,
}) => ({
  componentName: 'cell-name-and-type',
  componentArgs: {
    typeColumn,
    icon,
  },
});
