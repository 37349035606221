import moment from 'moment';

export default function createExternalPanelBillingFromResource(data) {
  return {
    id: data.id,
    date: moment(data.date).utc().format('YYYY-MM-DD'),
    bonus: data.bonus,
    fee: data.fee,
    spent: data.amount,
    paymentMethodCommission: data.payment_method_commission,
  };
}
