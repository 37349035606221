<template>
  <div>
    <div v-if="hint">
      <base-tooltip
        tooltip
        top
      >
        <template #activator="{ on, attrs }">
          <v-icon
            v-bind="{ ...attrs, ...$attrs }"
            v-on="on"
          >
            $icon-danger
          </v-icon>
        </template>

        <div>
          {{ hint.text }}
        </div>
      </base-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseHint',
  props: {
    hint: {
      type: Object,
      default: null,
    },
  },
};
</script>
