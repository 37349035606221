const types = {
  geotargeting: {
    name: 'Geotargeting',
  },
  comment: {
    name: 'Comment',
  },
  keyword: {
    name: 'Keyword',
  },
  seo_keyword: {
    name: 'Seo keyword',
  },
  language: {
    name: 'Language',
  },
};

export function createTargetAttachmentTypeResponse(data) {
  return types[data] ? {
    ...types[data],
    key: data,
  } : {
    name: data,
    key: data,
  };
}

export function createTargetAttachmentTypeRequest(data) {
  return data.key || null;
}
