import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  fetch: '/settings',
});

export default {
  fetch() {
    const endpoint = getURL('fetch');

    return http.get(endpoint)
      .then((response) => {
        const { data } = response;

        return {
          favicon: data.favicon,
          panelName: data.panel_name,
          lightLogo: data.light_logo,
          darkLogo: data.dark_logo,
          panelId: data.panel_id,
          logoDestinationUrl: data.logo_destination_url,
          logoAlt: data.logo_alt,
          defaultReferralProgramPercent: data.default_referral_program_percent * 100,
        };
      });
  },
};
