import { defaultState } from './state';

export default {
  SET_CLEAR_STATE: (state) => {
    Object.assign(state, defaultState());
  },
  SET_COUPON_ID: (state, value) => { state.couponId = value; },
  SET_IS_SHOWN: (state, value) => { state.isShown = value; },
  SET_FORM_DATA: (state, formData) => { state.formData = { ...formData }; },
  SET_TRANSLATIONS: (state, translations) => {
    state.formData.translations = { ...translations };
  },
  SET_DISPLAYABLE: (state, value) => { state.formData.displayable = value; },
  SET_USE_WITH_ONLY_UNPAID_ORDERS: (state, value) => {
    state.formData.useWithOnlyUnpaidOrders = value;
  },
  SET_WORK_WITH_SERVICE_DISCOUNT: (state, value) => {
    state.formData.workWithServiceDiscount = value;
  },
  SET_WORK_WITH_USER_CUSTOM_PRICE: (state, value) => {
    state.formData.workWithUserCustomPrice = value;
  },
  SET_WORK_WITH_BALANCE: (state, value) => {
    state.formData.workWithBalance = value;
  },
  SET_CODE: (state, value) => { state.formData.code = value; },
  SET_VALID_FROM: (state, value) => { state.formData.validFrom = value; },
  SET_VALID_TO: (state, value) => { state.formData.validTo = value; },
  SET_USAGE_LIMIT: (state, value) => { state.formData.usageLimit = value; },
  SET_BONUS_TYPE: (state, value) => { state.formData.bonusType = value; },
  SET_BONUS_AMOUNT_CALCULATION_TYPE: (state, value) => {
    state.formData.bonusAmountCalculationType = value;
  },
  SET_BONUS_PERCENTAGE: (state, value) => { state.formData.bonusPercentage = value; },
  SET_BONUS_AMOUNT: (state, value) => { state.formData.bonusAmount = value; },
  SET_PAYMENT_TYPE: (state, value) => { state.formData.paymentType = value; },
  SET_MIN_AMOUNT: (state, value) => { state.formData.minAmount = value; },
  SET_AFFILIATE: (state, value) => { state.formData.affiliate = value; },
  SET_TRANSLATIONS_NAME: (state, value) => { state.formData.translations[state.locale].name = value; },
  SET_ERRORS: (state, value) => { state.errors = value; },
  SET_NAME: (state) => { state.formData.name = state.formData.translations.en.name; },
  SET_DESCRIPTION: (state, value) => {
    state.formData.translations[state.locale].description = value;
  },
};
