import RepositoryFactory from '@/services/repository-factory';
import settings from '@/settings';
import applyFavicon from './applyFavicon';

const instanceSettingsRepository = RepositoryFactory.get('instanceSettings');

export default {
  namespaced: true,
  state: {
    apiHost: settings.apiHost,
    instanceSettings: null,
  },
  mutations: {
    SET_INSTANCE_SETTINGS: (state, value) => { state.instanceSettings = value; },
    SET_FAVICON: (state, value) => { state.favicon = value; },
  },
  actions: {
    fetch({ commit }) {
      instanceSettingsRepository.fetch()
        .then((instanceSettings) => {
          commit('SET_INSTANCE_SETTINGS', instanceSettings);

          document.title = instanceSettings.panelName;
          applyFavicon(instanceSettings.favicon);
        });
    },
  },
};
