<script>
export default {
  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },
  render() {
    return this.$scopedSlots.default({
      isDark: this.isDark,
    });
  },
};
</script>
