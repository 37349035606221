import getters from './getters';

const state = {
};

export default {
  namespaced: true,
  state: () => state,
  getters,
};
