import ValidationError from '@/classes/errors/ValidationError';
import ServerError from '@/classes/errors/ServerError';

export default function createValidationError(dictionary = {}) {
  return (error) => {
    if (error.response && error.response.data) {
      if (error.response.data.errors) {
        const serverErrors = error.response.data.errors;
        const errorMessage = error.response.data.message;
        const messages = Object.entries(dictionary)
          .reduce((acc, [localParamName, serverParamName]) => {
            if (serverErrors[serverParamName]) {
              if (acc[localParamName]) {
                acc[localParamName] = [...acc[localParamName], ...serverErrors[serverParamName]];
              } else {
                acc[localParamName] = serverErrors[serverParamName];
              }
            }

            return acc;
          }, serverErrors);

        throw new ValidationError(messages, errorMessage);
      }

      if (error.response.data.message) {
        throw new ServerError(error.response.data.message);
      }
    }

    throw error;
  };
}
