import numberToStringWithCommas from '@/services/functions/number-to-string-with-commas';

function numberedValue(value) {
  const filteredStringValue = (value ?? '').replace(/[\s$]/g, '');

  return Number.isNaN(filteredStringValue) ? 0 : Number(filteredStringValue);
}

function absoluteNumber(value) {
  return Math.abs(numberedValue(value));
}

function numberSign(value) {
  return numberedValue(value) < 0 ? '-' : '';
}

function currency(currencySign) {
  return currencySign || '$';
}

function resultValue(value, toFixed, trimFraction) {
  const parts = absoluteNumber(value).toString().split('.');

  parts[0] = numberToStringWithCommas(parts[0], ' ');

  if (toFixed) {
    parts[1] = Array.from((parts[1] ?? [])).concat(Array(toFixed).fill(0)).slice(0, toFixed).join('');
  }

  if (trimFraction !== false) {
    if (trimFraction === true) {
      const fractionNumber = Number(parts[1]);

      parts[1] = fractionNumber > 0 ? parts[1] : null;
    } else {
      parts[1] = parts[1].slice(0, trimFraction);
    }
  }

  return parts[1] === null ? parts[0] : parts.join('.');
}

export default function getShortPrice(value, currencySign, toFixed = 0, trimFraction = false) {
  return `${numberSign(value)} ${currency(currencySign)} ${resultValue(value, toFixed, trimFraction)}`;
}
