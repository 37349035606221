import waiter from '@/store/utils/waiter';
import invokeDialog from '@/components/services';

export default {
  async multipleCancelAndReturnMoney({
    dispatch, getters, commit,
  }, ordersIds) {
    await waiter('orders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.multipleCancelAndReturnMoney({ ordersIds });
    }, { clearSelected: true });
  },
  async multipleCancel({
    dispatch, getters, commit,
  }, ordersIds) {
    await waiter('orders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.multipleCancel({ ordersIds });
    }, { clearSelected: true });
  },

  async multipleComplete({
    dispatch, getters, commit,
  }, ordersIds) {
    await waiter('orders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.multipleComplete({ ordersIds });
    }, { clearSelected: true });
  },
  async multipleActivate({
    dispatch, getters, commit,
  }, ordersIds) {
    await waiter('orders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.multipleActivate({ ordersIds });
    }, { clearSelected: true });
  },
  async multiplePause({
    dispatch, getters, commit,
  }, ordersIds) {
    await waiter('orders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.multiplePause({ ordersIds });
    }, { clearSelected: true });
  },

  async confirmedMultipleComplete({ dispatch }, ordersIds) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('multipleComplete', ordersIds);
      },
      amount: ordersIds.length,
    }, 'completeOrder');
  },
  async confirmedMultipleActivate({ dispatch }, ordersIds) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('multipleActivate', ordersIds);
      },
      amount: ordersIds.length,
    }, 'activateOrder');
  },
  async confirmedMultiplePause({ dispatch }, ordersIds) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('multiplePause', ordersIds);
      },
      amount: ordersIds.length,
    }, 'pauseOrder');
  },

  async complete({
    dispatch, getters, commit,
  }, orderId) {
    await waiter('orders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.complete({ orderId });
    });
  },
  async cancelAndReturnMoney({
    dispatch, getters, commit,
  }, orderId) {
    await waiter('orders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.cancelAndReturnMoney({ orderId });
    });
  },
  async cancel({
    dispatch, getters, commit,
  }, orderId) {
    await waiter('orders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.cancel({ orderId });
    });
  },
  async pause({
    dispatch, getters, commit,
  }, orderId) {
    await waiter('orders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.pause({ orderId });
    });
  },
  async reject({
    dispatch, getters, commit,
  }, orderId) {
    await waiter('orders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.reject({ orderId });
    });
  },
  async activate({
    dispatch, getters, commit,
  }, orderId) {
    await waiter('orders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.activate({ orderId });
    });
  },
  async approve({
    dispatch, getters, commit,
  }, orderId) {
    await waiter('orders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.approve({ orderId });
    });
  },

  async confirmedApprove({ dispatch }, orderId) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('approve', orderId);
      },
      amount: 1,
    }, 'approveOrder');
  },
  async confirmedActivate({ dispatch }, orderId) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('activate', orderId);
      },
      amount: 1,
    }, 'activateOrder');
  },
  async confirmedReject({ dispatch }, orderId) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('reject', orderId);
      },
      amount: 1,
    }, 'rejectOrder');
  },
  async confirmedPause({ dispatch }, orderId) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('pause', orderId);
      },
      amount: 1,
    }, 'pauseOrder');
  },
  async confirmedComplete({ dispatch }, orderId) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('complete', orderId);
      },
      amount: 1,
    }, 'completeOrder');
  },
};
