<template>
  <div
    class="dot rounded-pill"
    :class="color"
  />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.dot{
  height: 10px;
  width: 10px;
}
</style>
