import { defaultDialogSetupBuilder } from '@/components/services/dialog/dialogSetup';

export default (invokeArgs = {}) => {
  defaultDialogSetupBuilder(
    {
      isModalShown: true,
      confirmTitle: 'Delete feature «Custom comments»',
      confirmText: `Are you sure you want to delete feature «Custom comments»? It is used in the following services:
<ul>${invokeArgs.attaches.map((attache) => `<li style="color: #6B6B6B;">${attache.name}</li>`)}</ul>`,
      confirmBtnColor: 'invokedDialogRed',
      confirmBtnText: 'Delete',
      ...invokeArgs,
    },
  );
};
