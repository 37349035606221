import ValidationError from '@/classes/errors/ValidationError';
import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  list: '/api/v1/admin/providers/get-available-for-manual-add-statistics',
  store: '/api/v1/admin/provider-statistics',
});

export default {
  list(id) {
    const endpoint = getURL('list');

    return http.get(endpoint, {
      params: {
        order_id: id,
      },
    })
      .then((response) => response.data.data);
  },
  store({
    orderId,
    date,
    providerStatistics,
  }) {
    const endpoint = getURL('store');
    const providers = providerStatistics.map((provider) => ({
      provider_id: provider.id,
      cost: provider.cost,
      quantity: provider.quantity,
    }));

    return http.post(endpoint, {
      order_id: orderId,
      provider_statistics: providers,
      date,
    })
      .catch((error) => {
        const { errors } = error.response.data;
        const errorsDate = errors.date;

        delete errors.date;

        const providersErrors = Object.entries(errors).reduce((acc, [key, value]) => {
          const [
            , , name,
          ] = key.split('.');

          acc[name] = value;

          return acc;
        }, {});

        throw new ValidationError({
          date: errorsDate,
          providers: providersErrors,
        });
      });
  },
  getURL,
};
