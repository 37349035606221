import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createValidationError from '@/services/functions/create-validation-error';
import createPaymentMethodFromResource from '@/classes/createPaymentMethod';
import handleErrors from '@/services/handle-errors';
import http, { cancelTokenNames } from '../../http';
import urlFormatter from '../../url-formatter';
import createPaymentRequestBody from './createPaymentRequestBody';
import createCollectionFromResource from './createCollectionFromResource';
import getFormData from '../../helpers/formDataBuilder';

const getURL = urlFormatter({
  list: '/api/v1/admin/payment-methods',
  show: '/api/v1/admin/payment-methods/{id}',
  forWidget: '/api/v1/admin/payment-methods/get-available-for-order-widget',
  store: '/api/v1/admin/payment-methods',
  update: '/api/v1/admin/payment-methods/{id}',
  collections: '/api/v1/admin/payment-methods/collections',
  typeCollections: 'api/v1/admin/payments/collections',
  disable: '/api/v1/admin/payment-methods/{id}/disable',
  enable: '/api/v1/admin/payment-methods/{id}/enable',
  sort: '/api/v1/admin/payment-methods/sort',
});

export default {
  list() {
    const endpoint = getURL('list');

    return http.get(endpoint)
      .then((response) => response.data.data.map(createPaymentMethodFromResource));
  },
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('list');
    const response = await http.get(`${endpoint}?${requestBody}`, {
      applyCancelAdapter: cancelTokenNames.PAYMENT_METHODS_TABLE,
    });

    return {
      items: response.data.data.map(createPaymentMethodFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  show(id) {
    const endpoint = getURL('show', { id });

    return http.get(endpoint)
      .then((response) => createPaymentMethodFromResource(response.data.data));
  },
  disable(id) {
    const endpoint = getURL('disable', { id });

    return http.post(endpoint)
      .then((response) => response.data.data);
  },
  enable(id) {
    const endpoint = getURL('enable', { id });

    return http.post(endpoint)
      .then((response) => response.data.data);
  },
  forWidget() {
    const endpoint = getURL('forWidget');

    return http.get(endpoint)
      .then((response) => response.data.data.map(createPaymentMethodFromResource));
  },
  update(id, formData) {
    const endpoint = getURL('update', { id });
    const data = getFormData({
      ...createPaymentRequestBody(formData),
      _method: 'put',
    });

    return http.post(endpoint, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .catch(createValidationError());
  },
  store(formData) {
    const endpoint = getURL('store');
    const data = getFormData(createPaymentRequestBody(formData));

    return http.post(endpoint, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => response.data.data)
      .catch(createValidationError());
  },
  collections() {
    const endpoint = getURL('collections');

    return http.get(endpoint)
      .then((response) => response.data.data.map(createCollectionFromResource));
  },
  typeCollections() {
    const endpoint = getURL('typeCollections');

    return http.get(endpoint)
      .then((response) => response.data.data.types);
  },
  sort(paymentMethodsIds) {
    const endpoint = getURL('sort');

    return http.post(endpoint, {
      payment_methods: paymentMethodsIds,
    })
      .catch((error) => handleErrors(error));
  },
  getURL,
};
