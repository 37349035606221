import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createWithdrawalResponse from '@/classes/createWithdrawal';
import createTransactionFromResource from '@/classes/createTransaction';
import http, { cancelTokenNames } from '../http';
import urlFormatter from '../url-formatter';
import handleErrors from '../handle-errors';

const getURL = urlFormatter({
  transactionsTable: '/api/v1/admin/payments',
  withdrawalsTable: '/api/v1/admin/withdrawals',
  rejectWithdrawal: '/api/v1/admin/withdrawals/{id}/reject',
  approveWithdrawal: '/api/v1/admin/withdrawals/{id}/approve',
  markWithdrawalAsPaid: '/api/v1/admin/withdrawals/{id}/mark-as-paid',
});

export default {
  async transactionsTable({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('transactionsTable');
    const response = await http.get(`${endpoint}?${requestBody}`, {
      applyCancelAdapter: cancelTokenNames.TRANSACTIONS_TABLE,
    });

    return {
      items: response.data.data.map(createTransactionFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },

  async withdrawalsTable({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('withdrawalsTable');
    const response = await http.get(`${endpoint}?${requestBody}`, {
      applyCancelAdapter: cancelTokenNames.WITHDRAWAL_TABLE,
    });

    return {
      items: response.data.data.map(createWithdrawalResponse),
      totalItemsCount: response.data.recordsFiltered,
    };
  },

  rejectWithdrawal(id, data) {
    const endpoint = getURL('rejectWithdrawal', { id });

    return http.post(endpoint, {
      admin_note: data.adminNote || null,
    })
      .catch((error) => handleErrors(error));
  },

  async approveWithdrawal(id) {
    const endpoint = getURL('approveWithdrawal', { id });

    await http.post(endpoint);
  },

  markWithdrawalAsPaid(id, data) {
    const endpoint = getURL('markWithdrawalAsPaid', { id });

    return http.post(endpoint, {
      transaction_id: data.transactionId || null,
    })
      .catch((error) => handleErrors(error));
  },

  getURL,
};
