import ValidationError from '@/classes/errors/ValidationError';
import ServerError from '@/classes/errors/ServerError';

export default function handleServerError(handler = null) {
  return (error) => {
    if (!error.response || !error.response.data) {
      return Promise.reject(error);
    }

    if (error.response.data.message && !error.response.data.errors) {
      return Promise.reject(new ServerError(error.response.data.message));
    }

    const { errors } = error.response.data;

    return Promise.reject(
      new ValidationError(
        handler ? handler(errors) : errors,
      ),
    );
  };
}
