import dateFilter from '@/services/functions/date-filter';
import createUserFromResource from './createUser';

export default function createFreeViewFromResource(data) {
  return {
    actions: {
      approvable: data.actions.approvable,
      rejectable: data.actions.rejectable,
    },
    approvedAt: dateFilter(data.approved_at),
    approvedBy: createUserFromResource(data.approved_by),
    createdAt: dateFilter(data.created_at),
    id: data.id,
    orderId: data.order_id,
    proofUrl: data.proof_url,
    quantity: data.quantity,
    name: data.name,
    status: data.status,
    url: data.url,
    user: createUserFromResource(data.user),
  };
}
