import { defaultDialogSetupBuilder } from '@/components/services/dialog/dialogSetup';

export default (invokeArgs = {}) => {
  const postfix = invokeArgs.amount > 1 ? 's' : '';
  const dialogSubject = `order${postfix}`;

  defaultDialogSetupBuilder(
    {
      isModalShown: true,
      confirmTitle: `Pause the ${dialogSubject}`,
      confirmText: `Are you sure you want to pause
 <strong>${invokeArgs.amount}</strong> ${dialogSubject}? You will be able to undo this action.`,
      confirmBtnColor: 'invokedDialogRed',
      confirmBtnText: `Pause ${dialogSubject} (${invokeArgs.amount})`,
      ...invokeArgs,
    },
  );
};
