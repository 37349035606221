export default {
  isShown: (state) => state.isShown,
  id: (state) => state.id,
  selected: (state) => state.selected,
  saveBtnLoading: (state) => state.saveBtnLoading,
  loading: (state) => state.loading,
  search: (state) => state.search,
  availableServices: (state) => state.availableServices,
  errorsMessage: (state) => state.errorsMessage,
};
