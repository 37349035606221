var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',_vm._g(_vm._b({ref:"select",class:[
    'base-select',
    {
      'flat-bottom': _vm.focused,
      'persistent-border': _vm.isPersistedBorders,
      medium: _vm.size === 'm',
      small: _vm.size === 's',
    },
  ],attrs:{"outlined":"","dense":"","clear-icon":_vm.clearIcon,"append-icon":_vm.actualIcon,"menu-props":{
    offsetY: true,
    'content-class': _vm.contentClass,
  }},on:{"click":_vm.checkFocusState,"blur":_vm.checkFocusState,"input":_vm.checkFocusState,"change":_vm.checkFocusState}},'v-select',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }