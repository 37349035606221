import getQueryParams from '@/utils/getQueryParams';

export default function getImpersonationToken() {
  const { token } = getQueryParams(window.location.href);

  if (!token) {
    return false;
  }

  return token;
}
