import dateFilter from '@/services/functions/date-filter';
import createUserFromResource from './createUser';

export default function createWithdrawalResponse(data) {
  if (!data) {
    return null;
  }

  return {
    actions: {
      approve: data?.actions?.mark_as_approve || false,
      paid: data?.actions?.mark_as_paid || false,
      reject: data?.actions?.mark_as_reject || false,
    },
    id: data.id,
    accountNumber: data.account_number,
    amount: data.amount,
    withdrawalMethod: data.withdrawal_method,
    adminNote: data.admin_note,
    userComment: data.user_comment,
    transactionId: data.transaction_id,
    status: data.status,
    createdAt: dateFilter(data.created_at),
    user: createUserFromResource(data.user),
  };
}
