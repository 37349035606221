import dateFilter from '@/services/functions/date-filter';
import createUserFromResource from '@/classes/createUser';

export default function createTransactionFromResource(data) {
  return {
    adminId: data.admin_id,
    amount: data.amount,
    createdAt: dateFilter(data.created_at),
    id: data.id,
    orderId: data.order_id,
    paymentMethod: data.payment_method,
    transactionType: data.transaction_type,
    type: data.type,
    user: createUserFromResource(data.user),
    comment: data.comment,
  };
}
