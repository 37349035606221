import dateFilter from '@/services/functions/date-filter';

export default function createPromotable(data) {
  if (!data) {
    return null;
  }

  return {
    embedUrl: data.embed_url,
    regionRestriction: data.region_restriction,
    averageViewers: data.average_viewers,
    firstStatisticViewers: data.first_statistic_viewers,
    supportedAt: dateFilter(data.supported_at),
    supportedUntil: dateFilter(data.supported_until),
  };
}
