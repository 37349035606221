export default ({
  to,
  textProp,
}) => ({
  componentName: 'cell-link',
  componentArgs: {
    to,
    textProp,
  },
});
