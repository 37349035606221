export default async (
  refreshModule,
  {
    dispatch, commit,
  },
  callback,
  { clearSelected = false } = {},
) => {
  dispatch(`${refreshModule}/startLoading`, null, { root: true });

  try {
    await callback();
  } catch (e) {
    commit('SET_ERROR', e);
  } finally {
    await dispatch(`${refreshModule}/endLoading`, null, { root: true });
    await dispatch(`${refreshModule}/fetch`, null, { root: true });

    if (clearSelected) {
      await dispatch(`${refreshModule}/clearSelected`, null, { root: true });
    }
  }
};
