import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createInvoiceFromResource from '@/classes/createInvoice';
import http, { cancelTokenNames } from '../http';
import urlFormatter from '../url-formatter';
import handleErrors from '../handle-errors';

const getURL = urlFormatter({
  table: '/api/v1/admin/invoices',
  markAsPaid: '/api/v1/admin/invoices/{id}/mark-as-paid',
});

export default {
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('table');
    const response = await http.get(`${endpoint}?${requestBody}`, {
      applyCancelAdapter: cancelTokenNames.INVOICE_TABLE,
    });

    return {
      items: response.data.data.map(createInvoiceFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  markAsPaid(id, formData) {
    const endpoint = getURL('markAsPaid', { id });

    return http.post(endpoint, {
      amount: formData.amount,
    })
      .catch((error) => handleErrors(error));
  },
  getURL,
};
