const statusColors = {
  light: {
    statusActive: '#36B368',
    statusFrozen: '#94C7F8',
    statusDeactivated: '#B33A21',
    statusInProgress: '#3C83E0',
    statusInModerating: '#FF9100',
    statusCompleted: '#36B368',
    statusStopped: '#FFD74F',
    statusCanceled: '#B33A21',
    statusPaid: '#36B368',
    statusUnpaid: '#737373',
    statusArchived: '#94C7F8',
    statusScheduled: '#B33A21',
    statusPending: '#ff9100',
    statusApproved: '#7c4dff',
    statusRejected: '#bf360c',
    statusDisabled: '#bf360c',
    statusError: '#EA5230',
  },
  dark: {
    statusActive: '#36B368',
    statusFrozen: '#94C7F8',
    statusDeactivated: '#B33A21',
    statusInProgress: '#3C83E0',
    statusInModerating: '#FF9100',
    statusCompleted: '#36B368',
    statusStopped: '#FFD74F',
    statusCanceled: '#B33A21',
    statusPaid: '#36B368',
    statusUnpaid: '#9B9B9B',
    statusArchived: '#94C7F8',
    statusScheduled: '#B33A21',
    statusPending: '#ff9100',
    statusApproved: '#7c4dff',
    statusRejected: '#bf360c',
    statusDisabled: '#bf360c',
    statusError: '#EA5230',
  },
};
const checkboxesColor = {
  light: {
    checkboxesSimpleDefault: '#D5D8DF',
  },
  dark: {
    checkboxesSimpleDefault: '#363636',
  },
};
const dateDialogColors = {
  light: {
    dataPickerBg: '#FFF',
    dataSelectionHoverDate: '#F2F3F5',
    datePickerAccent: '#FFEDB3',
    datePickerYearsAccent: '#FFC400',
  },
  dark: {
    dataPickerBg: '#212121',
    dataSelectionHoverDate: '#2C2C2C',
    datePickerAccent: '#FFEDB3',
    datePickerYearsAccent: '#FFC400',
  },
};
const baseChipColors = {
  light: {
    baseChipAccentColor: '#737373',
    baseChipBgcColor: '#FFF',
    baseChipDisabledCaret: '#B2B3BD',
  },
  dark: {
    baseChipAccentColor: '#9B9B9B',
    baseChipBgcColor: '#212121',
    baseChipDisabledCaret: '#9B9B9B',
  },
};

export default {
  light: {
    primary: '#8B76DC',
    secondary: '#1B1D21',
    accent: '#8B76DC',
    lightBlack: '#000',
    lightWhite: '#fff',
    navigationDrawerCurrent: '#513CCC',
    applicationBorderColor: '#E3E6EC',
    mainBackgroundColor: '#F9F9F9',
    navigationDrawerBorderColor: '#E4E4E4',
    navigationDrawerBgc: '#FFF',
    navigationDrawerBackgroundColorHovered: '#DBD3F6',
    navigationDrawerBackgroundColorInner: '#F2EEFC',
    filterBuilderSecondaryText: '#60636A',
    baseDataTableRowStripeOdd: '#F7F7F7',
    baseDataTableRowStripeEven: '#FFF',
    baseDataTableRowGreyText: '#60636A',
    baseDataTableHeaderText: '#737373',
    baseDataTableHeaderIcon: '#D5D8DF',
    disabledDottedText: '#B2B3BD',
    toolbarBgc: '#FFF',
    inputBorder: '#D5D8DF',
    inputText: '#60636A',
    cardBgc: '#FFF',
    inputBgc: '#FFF',
    paginatorBgc: '#FFF',
    baseSelectContentOdd: '#FFF',
    baseSelectContentEven: '#E9E6F9',
    baseNoDataBgc: '#FFF',
    baseMenuBorderContainerColor: '#D5D8DF',
    cellActionOpened: '#513CCC',
    scrollBarColor: '#D5D8DF',
    baseDataTableRowSelected: '#E9E6F9',
    linkHover: '#3F90EE',
    tooltibBackground: '#393B3F',
    multuipleSelectedActionText: '#60636A',
    invokedDialogRed: '#DC4C2B',
    invokedDialogGreen: '#36B368',
    shownHeadersBgc: '#CFCFCF',
    shownHeadersTitleText: '#333333',
    cellRunsBorder: '#D5D8DF',
    ...statusColors.light,
    ...checkboxesColor.light,
    ...dateDialogColors.light,
    ...baseChipColors.light,
  },
  dark: {
    primary: '#8B76DC',
    secondary: '#1B1D21',
    accent: '#8B76DC',
    lightBlack: '#D2D2D2',
    lightWhite: '#000',
    navigationDrawerCurrent: '#7058D4',
    applicationBorderColor: '#333333',
    mainBackgroundColor: '#121212',
    navigationDrawerBorderColor: '#555555',
    navigationDrawerBgc: '#212121',
    navigationDrawerBackgroundColorHovered: '#363636',
    navigationDrawerBackgroundColorInner: '#2C2C2C',
    filterBuilderSecondaryText: '#60636A',
    baseDataTableRowStripeOdd: '#2C2C2C',
    baseDataTableRowStripeEven: '#212121',
    baseDataTableRowGreyText: '#9B9B9B',
    baseDataTableHeaderText: '#9B9B9B',
    baseDataTableHeaderIcon: '#4A4A4A',
    disabledDottedText: '#6B6B6B',
    toolbarBgc: '#212121',
    inputBorder: '#363636',
    inputText: '#9B9B9B',
    cardBgc: '#212121',
    inputBgc: '#212121',
    paginatorBgc: '#212121',
    baseSelectContentOdd: '#212121',
    baseSelectContentEven: '#2C2C2C',
    baseNoDataBgc: '#212121',
    baseMenuBorderContainerColor: '#D5D8DF',
    cellActionOpened: '#513CCC',
    scrollBarColor: '#363636',
    baseDataTableRowSelected: '#363636',
    linkHover: '#008BE1',
    tooltibBackground: '#F2F3F5',
    multuipleSelectedActionText: '#B5B5B5',
    invokedDialogRed: '#DC4C2B',
    invokedDialogGreen: '#36B368',
    shownHeadersBgc: '#363636',
    shownHeadersTitleText: '#FFFFFF',
    cellRunsBorder: '#4A4A4A',
    ...statusColors.dark,
    ...checkboxesColor.dark,
    ...dateDialogColors.dark,
    ...baseChipColors.dark,
  },
};
