import dateFormatter from '@/services/functions/date-filter';
import createGradeTranslations from './createGradeTranslations';

export default function createGrade(data) {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    name: data.name,
    icon: data.icon,
    createdAt: dateFormatter(data.created_at),
    usersCount: data.users_count,
    discountPercent: Math.round(data.discount_percent * 100),
    thumbnailPeriod: data.thumbnail_period,
    consultationChatPeriod: data.consultation_chat_period,
    availableClosedSales: data.available_closed_sales,
    availablePersonalManager: data.available_personal_manager,
    spendingGoal: data.spending_goal,
    actions: data.actions,
    translations: createGradeTranslations(data.translations),
    isArchived: data.is_archived,
  };
}
