import ValidationError from '@/classes/errors/ValidationError';

export default {
  openImportServicesModal(
    { dispatch },
    {
      id,
      type,
    },
  ) {
    if (id) {
      dispatch('setProviderId', id);
      dispatch('setType', type);
      dispatch('getExternalPanelServicesForImport', id);
    }

    dispatch('setIsShown', true);
  },
  closeImportServicesModal({ dispatch }) {
    dispatch('setIsShown', false);
    dispatch('setClearState');
  },
  setProviderId({ commit }, value) {
    commit('SET_PROVIDER_ID', value);
  },
  setIsShown({ commit }, value) {
    commit('SET_IS_SHOWN', value);
  },
  setClearState({ commit }) {
    commit('SET_CLEAR_STATE');
  },
  setType({ commit }, value) {
    commit('SET_TYPE', value);
  },
  createMutableImportableServices({ commit }, value) {
    commit('SET_SERVICES', value);
  },
  setAllNetworks({ commit }, value) {
    commit('SET_ALL_NETWORKS', value);
  },
  setAllServiceTypes({ commit }, value) {
    commit('SET_ALL_SERVICE_TYPES', value);
  },
  getExternalPanelServicesForImport({
    commit,
    dispatch,
    rootGetters,
  }, id) {
    commit('SET_LOADING', true);

    Promise.all([
      rootGetters['providers/getRepository'].getExternalPanelServicesForImport({ externalPanelId: id }),
      rootGetters['providers/getNetworksRepository'].list(),
      rootGetters['providers/getServiceTypesRepository'].list(),
    ])
      .then((response) => {
        const [
          externalPanelServices,
          allNetworks,
          allServicesTypes,
        ] = response;

        dispatch('createMutableImportableServices', externalPanelServices);
        dispatch('setAllNetworks', allNetworks);
        dispatch('setAllServiceTypes', allServicesTypes);
      })
      .catch((error) => {
        const errorMessage = error instanceof Error ? error.message : error;

        commit('SET_ERRORS_MESSAGE', errorMessage);
        commit('SET_ERRORS_NOTIFY', true);
      })
      .finally(() => {
        commit('SET_ERRORS_NOTIFY', false);
        commit('SET_LOADING', false);
      });
  },
  setSelectedNetworkId({ commit }, value) {
    commit('SET_SELECTED_NETWORK_ID', value);
  },
  setSearch({ commit }, value) {
    commit('SET_SEARCH', value);
  },
  setSelectedServiceTypeId({ commit }, value) {
    commit('SET_SELECTED_SERVICE_TYPE_ID', value);
  },
  setSelectedServices({ commit }, value) {
    commit('SET_SELECTED_SERVICE', value);
  },
  setMultiplier({ commit }, value) {
    commit('SET_MULTIPLIER', value);
  },
  setTableOptions({ commit }, value) {
    commit('SET_TABLE_OPTIONS', value);
  },
  createErrorsByServiceId(errors) {
    return Object.entries(errors)
      .reduce((acc, [key, messages]) => {
        const keyMatchResult = key.match(/^(\d+)\.(.+)/i);

        if (!keyMatchResult) {
          return acc;
        }

        const [serviceIndex] = keyMatchResult.slice(1);
        const service = this.selectedServices[+serviceIndex];

        if (!service) {
          return acc;
        }

        return {
          ...acc,
          [service.id]: messages,
        };
      }, {});
  },
  storeImportedService({
    commit,
    state,
    rootGetters,
    dispatch,
  }, { errorNotify }) {
    commit('SET_ERRORS', {});
    commit('SET_SAVE_BTN_LOADING', true);
    rootGetters['providers/getRepository'].storeImportedService(state.providerId, state.selectedServices)
      .then(() => {
        dispatch('providers/table/fetch', null, { root: true });
        dispatch('closeImportServicesModal');
      })
      .catch((error) => {
        const errorMessage = error instanceof Error ? error.message : error;

        commit('SET_ERRORS_MESSAGE', errorMessage);

        if (error instanceof ValidationError) {
          commit('SET_ERRORS', dispatch('createErrorsByServiceId', error.messages));
        }

        if (errorNotify) {
          errorNotify();
        }
      })
      .finally(() => {
        commit('SET_SAVE_BTN_LOADING', false);
      });
  },
};
