import { gettersNamer } from '@/store/utils/storeNamer';

export default (state) => {
  const newGetters = {};

  Object.keys(state.state()).forEach((propName) => {
    newGetters[gettersNamer(propName)] = (getterState) => getterState[propName];
  });

  return {
    ...newGetters,
    ...state.getters,
  };
};
