import { defaultState } from './state';

export default {
  SET_CLEAR_STATE: (state) => {
    Object.assign(state, defaultState());
  },
  SET_PROVIDER_ID: (state, value) => { state.providerId = value; },
  SET_IS_SHOWN: (state, value) => { state.innerIsShown = value; },
  SET_TYPE: (state, value) => { state.type = value; },
  SET_LOADING: (state, value) => { state.loading = value; },
  SET_TABLE_OPTIONS: (state, value) => { state.tableOptions = value; },
  SET_ALL_NETWORKS: (state, value) => { state.allNetworks = value; },
  SET_ALL_SERVICE_TYPES: (state, value) => { state.allServicesTypes = value; },
  SET_SERVICES: (state, value) => {
    state.services = value.map((obj) => ({
      id: obj.id,
      name: obj.name,
      externalPanelPrice: obj.externalPanelPrice,
      providerPrice: obj.providerPrice,
      price: obj.externalPanelPrice,
      networkId: obj.networkId,
      serviceTypeId: obj.serviceTypeId,
      syncParams: true,
      syncTextFields: true,
      syncDescriptionField: true,
      syncShortDescriptionField: true,
      syncReviewsField: true,
      syncLabels: true,
      syncPrices: true,
      promotableCheckerGroupId: null,
    }));
  },
  SET_SELECTED_NETWORK_ID: (state, value) => { state.selectedNetworkId = value; },
  SET_SEARCH: (state, value) => { state.search = value; },
  SET_SELECTED_SERVICE_TYPE_ID: (state, value) => { state.selectedServiceTypeId = value; },
  SET_SELECTED_SERVICE: (state, value) => { state.selectedServices = value; },
  SET_MULTIPLIER: (state, value) => { state.multiplier = value; },
  SET_ERRORS_NOTIFY: (state, value) => { state.errorsNotify = value; },
  SET_ERRORS_MESSAGE: (state, value) => { state.errors = value; },
  SET_ERRORS: (state, value) => { state.errors = value; },
  SET_SAVE_BTN_LOADING: (state, value) => { state.saveBtnLoading = value; },
};
