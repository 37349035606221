import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createExternalPanelService from '@/classes/createExternalPanelService';
import createValidationError from '@/services/functions/create-validation-error';
import ValidationError from '@/classes/errors/ValidationError';
import createExternalPanelBillingFromResource from '@/classes/createExternalPanelBillingForm';
import handleErrors from '@/services/handle-errors';
import createBillingsStoreRequest
  from '@/services/repositories/external-panels-repository/create-billings-store-request';
import createBillingsStoreErrors
  from '@/services/repositories/external-panels-repository/create-billings-store-errors';
import createExternalPanelFromResource from '@/classes/createExternalPanel';
import createExternalPanelCollections from '@/classes/createExternalPanelCollections';
import createExternalPanelOrderFromResource from '@/classes/createExternalPanelOrder';
import createExternalPanelServicesFromResource from './createExternalPanelServices';
import urlFormatter from '../../url-formatter';
import http, { cancelTokenNames } from '../../http';

const getURL = urlFormatter({
  show: '/api/v1/admin/external-panels/{id}',
  orders: '/api/v1/admin/external-panel-orders',
  table: '/api/v1/admin/external-panels',
  search: '/api/v1/admin/external-panels?columns[0][data]=name&columns[0][search][value]={query}',
  collections: '/api/v1/admin/external-panels/collections',
  store: '/api/v1/admin/external-panels',
  update: '/api/v1/admin/external-panels/{id}',
  recreatable: '/api/v1/admin/external-panel-orders/{id}/recreate',
  services: '/api/v1/admin/external-panels/{id}/services',
  createServices: '/api/v1/admin/external-panels/{externalPanelId}/services',
  availableServices: '/api/v1/admin/external-panels/{externalPanelId}/available-services',
  getExternalPanelServicesForImport: 'api/v1/admin/external-panels/{externalPanelId}/import-services',
  storeImportedService: 'api/v1/admin/external-panels/{externalPanelId}/import-services',
  presets: '/api/v1/admin/preset-panels',
  billings: '/api/v1/admin/external-panels/{externalPanelId}/external-panel-payments',
  storeBilling: '/api/v1/admin/external-panels/{externalPanelId}/external-panel-payments',
  updateBilling: '/api/v1/admin/external-panels/{externalPanelId}/external-panel-payments/{billingId}',
  removeBilling: '/api/v1/admin/external-panels/{externalPanelId}/external-panel-payments/{billingId}',
  storeImportedProviders: 'api/v1/admin/external-panels/{externalPanelId}/import-providers',
  archive: '/api/v1/admin/external-panels/{externalPanelId}/archive',
  unarchive: '/api/v1/admin/external-panels/{externalPanelId}/unarchive',
  createAccount: '/api/v1/admin/external-panels/create-account',
  validate: '/api/v1/admin/external-panels/validate',
});

function externalPanelSaveRequest({
  name,
  endpoint,
  apiToken,
  currency,
  syncServices,
  syncPrices,
  syncLabels,
  syncTextFields,
  syncDescriptionField,
  syncShortDescriptionField,
  syncReviewsField,
  syncParams,
  resaleMarginPercent,
  autoRecreateOrdersIsAllowed,
}) {
  return {
    api_token: apiToken || null,
    name,
    endpoint,
    currency,
    sync_services: Boolean(syncServices),
    sync_prices: Boolean(syncPrices),
    sync_labels: Boolean(syncLabels),
    sync_text_fields: Boolean(syncTextFields),
    sync_description_field: Boolean(syncDescriptionField),
    sync_short_description: Boolean(syncShortDescriptionField),
    sync_reviews: Boolean(syncReviewsField),
    sync_params: Boolean(syncParams),
    resale_margin_percent: resaleMarginPercent,
    auto_recreate_orders_is_allowed: Boolean(autoRecreateOrdersIsAllowed),
  };
}

export default {
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('table');
    const response = await http.get(`${endpoint}?${requestBody}`, {
      applyCancelAdapter: cancelTokenNames.PROVIDERS_TABLE,
    });

    return {
      items: response.data.data.map(createExternalPanelFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },

  async billings(queryParams, externalPanelId) {
    const endpoint = getURL('billings', { externalPanelId });
    const response = await http.get(`${endpoint}/?${datatableFormaterStringified(queryParams)}`);

    return {
      items: response.data.data.map(createExternalPanelBillingFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },

  async storeBilling(formData, externalPanelId) {
    try {
      const endpoint = getURL('storeBilling', { externalPanelId });

      return await http.post(endpoint, createBillingsStoreRequest(formData));
    } catch (error) {
      return handleErrors(error, createBillingsStoreErrors);
    }
  },

  async updateBilling(billingId, externalPanelId, formData) {
    try {
      const endpoint = getURL('updateBilling', {
        externalPanelId,
        billingId,
      });

      return await http.put(endpoint, createBillingsStoreRequest(formData));
    } catch (error) {
      return handleErrors(error, createBillingsStoreErrors);
    }
  },

  async removeBilling(billingId, externalPanelId) {
    try {
      const endpoint = getURL('removeBilling', {
        externalPanelId,
        billingId,
      });

      return await http.delete(endpoint);
    } catch (error) {
      return handleErrors(error);
    }
  },

  async getExternalPanelOrders(queryParams) {
    const endpoint = getURL('orders');
    const response = await http.get(`${endpoint}/?${datatableFormaterStringified(queryParams)}`, {
      cancelToken: new http.CancelToken((abort) => {
        this.getExternalPanelOrders.abort = abort;
      }),
    });

    return {
      items: response.data.data.map(createExternalPanelOrderFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },

  async getExternalPanelServicesForImport({ externalPanelId }) {
    try {
      const endpoint = getURL('getExternalPanelServicesForImport', { externalPanelId });
      const { data } = await http.get(endpoint);

      return data.data.map(createExternalPanelService);
    } catch (error) {
      return createValidationError()(error);
    }
  },

  async storeImportedService(externalPanelId, importableServices) {
    try {
      const endpoint = getURL('storeImportedService', { externalPanelId });

      await http.post(endpoint, {
        services: importableServices.map((importableService) => ({
          id: importableService.id,
          price: importableService.price,
          service_type_id: importableService.serviceTypeId,
          sync_params: importableService.syncParams,
          sync_prices: importableService.syncPrices,
          sync_labels: importableService.syncLabels,
          sync_text_fields: importableService.syncTextFields,
          sync_description_field: importableService.syncDescriptionField,
          sync_short_description: importableService.syncShortDescriptionField,
          sync_reviews: importableService.syncReviewsField,
          promotable_checker_group_id: importableService.promotableCheckerGroupId,
        })),
      });

      return null;
    } catch (error) {
      if (!error.response || !error.response.data || !error.response.data.errors) {
        throw error;
      }

      const serverErrors = error.response.data.errors;
      const localErrors = Object.entries(serverErrors)
        .reduce((acc, [serverParam, messages]) => {
          const localParam = serverParam
            .replace(/services\./g, '')
            .replace(/service_type_id/g, 'serviceTypeId')
            .replace(/promotable_checker_group_id/g, 'promotableCheckerGroupId');

          return {
            ...acc,
            [localParam]: messages,
          };
        }, {});

      throw new ValidationError(localErrors);
    }
  },
  async services(id, params) {
    const requestBody = datatableFormaterStringified(params);
    const endpointUrl = getURL('services', { id });
    const response = await http.get(`${endpointUrl}?${requestBody}`);

    return { items: response.data.data.map(createExternalPanelServicesFromResource) };
  },
  recreatable({ id }) {
    const endpointUrl = getURL('recreatable', { id });

    return http.post(endpointUrl);
  },
  createService({
    externalPanelId,
    selectedExternalPanelService,
    selectedProvider,
  }) {
    const endpointUrl = getURL('createServices', { externalPanelId });

    return http.post(endpointUrl, {
      external_panel_service_id: selectedExternalPanelService,
      provider_id: selectedProvider,
    })
      .catch(createValidationError());
  },
  availableServices({ externalPanelId }) {
    const endpointUrl = getURL('availableServices', { externalPanelId });

    return http.get(endpointUrl)
      .then((response) => {
        if (response.data.error) {
          return Promise.reject(response.data.error);
        }

        return response.data.data;
      });
  },
  show({ id }) {
    const endpointUrl = getURL('show', { id });

    return http.get(endpointUrl)
      .then((response) => (
        createExternalPanelFromResource(response.data.data)
      ));
  },
  collections() {
    const endpoint = getURL('collections');

    return http.get(endpoint)
      .then((response) => (
        createExternalPanelCollections(response.data.data)
      ));
  },
  store(formData) {
    const endpointUrl = getURL('store');

    return http.post(endpointUrl, externalPanelSaveRequest(formData))
      .catch(createValidationError());
  },
  update(id, formData) {
    const endpointUrl = getURL('update', { id });

    return http.patch(endpointUrl, externalPanelSaveRequest(formData))
      .catch(createValidationError());
  },
  search(query) {
    const endpointUrl = getURL('search', {
      query: encodeURIComponent(query),
    });

    return http.get(`${endpointUrl}`)
      .then((response) => response.data.data);
  },
  getPresetSettings() {
    const endpoint = getURL('presets');

    return http.get(endpoint)
      .then((response) => response.data.data.map((item) => ({
        ...createExternalPanelFromResource(item),
        endpoint: item.backend_domain,
        currency: item.default_currency,
        favicon: item.favicon,
        name: item.name,
      })));
  },
  async storeImportedProviders({
    externalPanelId,
    importableServicesIds,
  }) {
    const endpoint = getURL('storeImportedProviders', { externalPanelId });

    await http.post(endpoint, { services: importableServicesIds });
  },
  async archive(externalPanelId) {
    const endpoint = getURL('archive', { externalPanelId });

    try {
      const { data } = await http.post(endpoint);

      return data.data.map(createExternalPanelFromResource);
    } catch (error) {
      return createValidationError();
    }
  },
  async unarchive(externalPanelId) {
    const endpoint = getURL('unarchive', { externalPanelId });

    try {
      const { data } = await http.post(endpoint);

      return data.data.map(createExternalPanelFromResource);
    } catch (error) {
      return createValidationError();
    }
  },
  createAccount(obj) {
    const endpoint = getURL('createAccount');

    return http.post(endpoint, obj)
      .then((response) => ({
        apiToken: response.data.data.api_token,
        endpoint: response.data.data.endpoint,
      }))
      .catch(createValidationError());
  },
  validate(obj) {
    const endpoint = getURL('validate');

    return http.post(endpoint, obj)
      .then((response) => ({
        isOurPanel: response.data.is_our_panel,
        endpoint: response.data.endpoint,
      }))
      .catch(createValidationError());
  },
  getURL,
};
