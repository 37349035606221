import Vue from 'vue';
import StackdriverErrorReporter from 'stackdriver-errors-js';
import settings from '@/settings';

export default function initLogger() {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const errorHandler = new StackdriverErrorReporter();

  errorHandler.start({
    key: settings.googleErrorReportingKey,
    projectId: settings.googleErrorReportingProjectId,
    service: settings.googleErrorReportingServiceName,
  });

  Vue.config.errorHandler = (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    errorHandler.report(error);
  };
}
