export default {
  isShown: (state) => state.isShown,
  collections: (state) => state.collections,
  errors: (state) => state.errors,
  errorMessage: (state) => state.errorMessage,
  paymentMethodId: (state) => state.paymentMethodId,
  selectedMethodInner: (state) => state.selectedMethodInner,
  selectedLocaleInner: (state) => state.selectedLocaleInner,
  selectedWithdrawLocaleInner: (state) => state.selectedWithdrawLocaleInner,
  selectedTranslation: (state) => state.selectedTranslation,
  selectedWithdrawTranslation: (state) => state.selectedWithdrawTranslation,
  paymentMethod: (state) => state.paymentMethod,
  typeModal: (state) => state.typeModal,
  availableForWithdrawal: (state) => state.paymentMethod?.withdrawalOptions?.availableForWithdrawal,
  withdrawalOptions: (state) => state.paymentMethod?.withdrawalOptions,
  allowQrCode: (state) => state.paymentMethod?.allowQrCode,
  isAllowedForNewUser: (state) => state.paymentMethod?.isAllowedForNewUser,
  previewIcon: (state) => state.previewIcon,
  previewQrCode: (state) => state.previewQrCode,
  iconName: (state) => state.iconName,
  qrCodeName: (state) => state.qrCodeName,
  paymentQrCode: (state) => state.paymentMethod?.paymentQrCode,
  paymentPosition: (state) => state.paymentMethod?.position,
  paymentMethodList: (state) => state.paymentMethodList,
  icon: (state) => state.paymentMethod?.icon,
  description: (state) => state.selectedTranslation?.description,
};
