import fieldMapper from '@/services/helpers/fieldMapper';

export default class Translations {
  #fieldMap;

  constructor(data, fieldMap) {
    this.#fieldMap = fieldMap;

    Object.entries(data).forEach(([key, localeTranslation]) => {
      this[key] = fieldMapper.receive(localeTranslation, fieldMap);
    });
  }

  getDataForRequest() {
    return Object.entries(this).reduce((acc, [key, localeTranslation]) => {
      acc[key] = fieldMapper.send(localeTranslation, this.#fieldMap);

      return acc;
    }, {});
  }
}
