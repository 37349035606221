import { createTargetType } from '@/classes/targets/createTargetTypes';

export function createTargets(data) {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    name: data.name,
    description: data.description,
    placeholder: data.placeholder,
    selectableValues: data.selectable_values,
    isVisibleDefaultValues: data.is_visible_default_values,
    targetType: data.target_type,
    translations: data.translations,
  };
}

export function createTargetList(data) {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    name: data.name,
    placeholder: data.placeholder,
    selectableValues: data.selectable_values,
    targetType: createTargetType(data.target_type),
    translations: data.translations,
  };
}
