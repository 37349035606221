import dateFormatter from '@/services/functions/date-filter';

export default function createStaffFromResource(data) {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    createdAt: dateFormatter(data.created_at),
    name: data.name,
    email: data.email,
    isActive: data.is_active,
    permissions: data.permissions.map(
      (permission) => ({
        id: permission.id,
        name: permission.name,
        group: permission.group,
      }),
    ),
    roles: data.roles.map(
      (role) => ({
        id: role.id,
        name: role.name,
      }),
    ),
  };
}
