export const defaultState = () => ({
  couponId: null,
  locale: 'en',
  formData: {
    displayable: false,
    useWithOnlyUnpaidOrders: false,
    name: '',
    code: '',
    validFrom: '',
    validTo: '',
    usageLimit: '',
    bonusAmountCalculationType: '',
    bonusType: '',
    bonusPercentage: '',
    bonusAmount: '',
    type: '',
    minAmount: '',
    affiliate: null,
    translations: {
      en: {
        name: '',
        description: '',
      },
    },
    workWithBalance: false,
    workWithServiceDiscount: false,
    workWithUserCustomPrice: false,
  },
  errors: {},
});

export default () => ({
  isShown: false,
  loading: false,
  ...defaultState(),
});
