import createUserFromResource from '@/classes/createUser';
import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  index: '/api/v1/admin/admin-notifications',
  update: '/api/v1/admin/admin-notifications',
});

export default {
  index() {
    const endpoint = getURL('index');

    return http.get(endpoint)
      .then((response) => response.data.data.map(createUserFromResource));
  },
  update(users) {
    const endpoint = getURL('update');

    return http.put(endpoint, {
      users: users.map((user) => user.id),
    });
  },
  getURL,
};
