import settings from './settings';

const keys = {
  isEnabled: 'is_enabled',
  isArchived: 'is_archived',
};

export default function createBulkServicesEditRequest(packages, data) {
  const requestData = {
    package_ids: packages.map((item) => item.id),
    ...Object.entries(data).reduce((acc, [key, value]) => {
      if (keys[key] && value !== null) {
        acc[keys[key]] = value;
      }

      return acc;
    }, {}),
  };

  // TODO
  if (data.discountPercent) {
    requestData.discount_percent = settings.prices.discountPercent.converting(data.discountPercent);
  }

  return requestData;
}
