import { defaultState } from './state';

export default {
  SET_CLEAR_STATE: (state) => {
    Object.assign(state, defaultState());
  },
  SET_DISCOUNT_ID: (state, val) => { state.discountId = val; },
  SET_SHOWN: (state, val) => { state.isShown = val; },
  SET_LOADING: (state, val) => { state.discountLoading = val; },
  SET_NAME: (state, name) => { state.formData.name = name; },
  SET_SERVICES: (state, services) => {
    state.formData.services = services.map((discountService) => ({
      ...discountService,
      isSavedOnServer: true,
    }));
  },
  SET_VALID_FROM: (state, val) => { state.formData.validFrom = val; },
  SET_VALID_TO: (state, val) => { state.formData.validTo = val; },
  SET_CREATED_AT: (state, val) => { state.formData.createdAt = val; },
  SET_ERRORS: (state, val) => { state.errors = val; },
};
