const getFlattedObject = (obj, prefixes = []) => {
  let newObj = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null && !(value instanceof File)) {
      newObj = {
        ...newObj,
        ...getFlattedObject(value, prefixes.concat(key)),
      };
    } else {
      const joinedPrefixes = prefixes.length > 1
        ? `${prefixes.slice(0, 1) + prefixes.slice(1).map((prefix) => `[${prefix}]`).join('')}`
        : `${prefixes.slice(0, 1) ?? ''}`;

      newObj[`${joinedPrefixes}${joinedPrefixes ? `[${key}]` : key}`] = value;
    }
  });

  return newObj;
};
const getFormData = (data) => {
  const flattedData = getFlattedObject(data);
  const formData = new FormData();

  Object.entries(flattedData).forEach(([key, value]) => {
    formData.append(key, (value instanceof File || value === null)
      ? value
      : String(value ?? null));
  });

  return formData;
};

export default getFormData;
