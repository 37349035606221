<template>
  <v-dialog
    v-model="Value"
    v-bind="$attrs"
    content-class="base-dialog"
    v-on="$listeners"
  >
    <template
      v-for="(index, name) in $scopedSlots"
      #[name]="data"
    >
      <slot
        :name="name"
        v-bind="data"
      />
    </template>
  </v-dialog>
</template>

<script>
import useVModel from '@/mixins/useVModel';

export default {
  mixins: [useVModel()],
  props: {
    value: Boolean,
  },
};
</script>
