import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import {
  createTargetType,
  createFieldsTypes,
  createShowTargetType,
  createTargetResponse,
} from '@/classes/targets/createTargetTypes';
import http from '../../http';
import urlFormatter from '../../url-formatter';

const getURL = urlFormatter({
  list: '/api/v1/admin/target-types',
  show: '/api/v1/admin/target-types/{id}',
  fieldsTypes: '/api/v1/admin/target-types/view-types',
  dictionary: '/api/v1/admin/dictionaries/list',
  update: '/api/v1/admin/target-types/{id}',
  create: '/api/v1/admin/target-types',
});

export default {
  async table(queryParams) {
    const endpoint = getURL('list');
    const { data } = await http.get(`${endpoint}/?${datatableFormaterStringified(queryParams)}`);

    return {
      items: data.data.map(createTargetType),
      columns: data.input.columns,
      totalItemsCount: data.recordsFiltered,
    };
  },
  async show(id) {
    const endpoint = getURL('show', { id });
    const { data } = await http.get(endpoint);

    return createShowTargetType(data.data);
  },
  async getFieldsTypes() {
    const endpoint = getURL('fieldsTypes');
    const { data } = await http.get(endpoint);

    return createFieldsTypes(data.data);
  },
  async getDictionary() {
    const endpoint = getURL('dictionary');
    const { data } = await http.get(endpoint);

    return data.data;
  },
  async updateTargetType(obj) {
    const endpoint = getURL('update', { id: obj.id });
    const { data } = await http.put(endpoint, {
      title: obj.title,
      view_type: obj.viewType,
      selectable: obj.selectable,
      enabled_for_service: obj.enabledForService,
    });

    return data;
  },
  async addTargetType(obj) {
    const endpoint = getURL('create');
    const { data } = await http.post(endpoint, createTargetResponse(obj));

    return data;
  },
  getURL,
};
