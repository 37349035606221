import invokeDialog from '@/components/services';
import waiter from '@/store/utils/waiter';

export default {
  async confirmedCancel({ dispatch }, dripFeedId) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('cancel', dripFeedId);
      },
      amount: 1,
    }, 'cancelDripFeed');
  },
  async cancel({
    dispatch, getters, commit,
  }, dripFeedId) {
    await waiter('dripFeed/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.cancel({ dripFeedId });
    });
  },
};
