import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createFreeViewFromResource from '@/classes/createFreeView';
import createFreeTrialsList from '@/classes/createFreeTrialsList';
import createFreeTrialsDetail from '@/classes/createFreeTrialsDetail';
import createFreeTrialsUpdateData from '@/classes/createFreeTrialsUpdateData';
import http from '../http';
import urlFormatter from '../url-formatter';
import handleErrors from '../handle-errors';

const getURL = urlFormatter({
  list: '/api/v1/admin/free-trial-quests',
  show: '/api/v1/admin/free-trial-quests/{questId}',
  store: '/api/v1/admin/free-trial-quests/',
  update: '/api/v1/admin/free-trial-quests/{questId}',
  table: '/api/v1/admin/free-trials',
  approve: '/api/v1/admin/free-trials/{requestId}/approve',
  reject: '/api/v1/admin/free-trials/{requestId}/reject',
  archive: 'api/v1/admin/free-trial-quests/{questId}/archive',
});

export default {
  async archive(id) {
    const endpoint = getURL('archive', { questId: id });

    await http.post(endpoint);
  },
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('table');
    const response = await http.get(`${endpoint}?${requestBody}`);

    return {
      items: response.data.data.map(createFreeViewFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  async list({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('list');
    const response = await http.get(`${endpoint}?${requestBody}`);

    return {
      items: response.data.data.map(createFreeTrialsList),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  async show(id) {
    const endpoint = getURL('show', { questId: id });
    const response = await http.get(endpoint);
    const { data } = response.data;

    return createFreeTrialsDetail(data);
  },
  async update(freeTrial) {
    try {
      const endpoint = getURL('update', { questId: freeTrial.id });

      return await http.put(endpoint, createFreeTrialsUpdateData(freeTrial));
    } catch (e) {
      return e.response.data;
    }
  },

  async store(freeTrial) {
    const endpoint = getURL('store');

    try {
      return await http.post(endpoint, createFreeTrialsUpdateData(freeTrial));
    } catch (e) {
      return e.response.data;
    }
  },
  approve(requestId, { quantity }) {
    const endpoint = getURL('approve', { requestId });

    return http.post(endpoint, {
      quantity,
    });
  },
  reject(requestId) {
    const endpoint = getURL('reject', { requestId });

    return http.post(endpoint)
      .catch((error) => handleErrors(error));
  },
  getURL,
};
