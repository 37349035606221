function createTranslationsErrors(errors) {
  const translationFieldsDictionary = {
    name: 'name',
    description: 'description',
    short_description: 'shortDescription',
    reviews: 'reviews',
    speed: 'speed',
    start_time: 'startTime',
    example_link: 'exampleLink',
    geo: 'geo',
    requirements: 'requirements',
    guarantee: 'guarantee',
    meta_title: 'metaTitle',
  };

  return Object.entries(errors).reduce((acc, [key, errorMessages]) => {
    const match = key.match(/^translations\.(.{2})\.(.+)/);

    if (!match) {
      return acc;
    }

    const locale = match[1];
    const property = match[2];
    const processedKey = `translations.${locale}.${translationFieldsDictionary[property]}`;

    return {
      ...acc,
      [processedKey]: errorMessages,
    };
  }, {});
}

export default function createServiceStoreErrors(data) {
  return {
    admin_note: data.adminNote,
    name: data.name,
    description: data.description,
    minQuantity: data.min_quantity,
    maxQuantity: data.max_quantity,
    quantityStep: data.quantity_step,
    minPrice: data.min_price,
    pricingPer: data.pricing_per,
    defaultPrice: data.default_price,
    autoRefillAfterHours: data.auto_refill_after_hours,
    autoRefillIsAllowed: data.auto_refill_is_allowed,
    autoRefillOnceFillPercent: data.auto_refill_once_fill_percent,
    autoRefillMinDropPercent: data.auto_refill_min_drop_percent,
    autoRefillMaxDropPercent: data.auto_refill_max_drop_percent,
    autoRefillMaxTotalRefilledPercent: data.auto_refill_max_total_refilled_percent,
    orderQuantityRatio: data.order_quantity_ratio,
    hasRefill: data.has_refill,
    apiAccess: data.api_access,
    restrictedAccess: data.restricted_access,
    isEnabled: data.is_enabled,
    isArchived: data.is_archived,
    urlIsRequired: data.url_is_required,
    moderationIsEnabled: data.moderation_is_enabled,
    cancelByClientIsAllowed: data.cancel_by_client_is_allowed,
    autoCancel: data.auto_cancel,
    gatherStatistic: data.gather_statistic,
    calculatableQuantity: data.calculable_quantity,
    serviceType: data.service_type_id,
    defaultQuantityForWidget: data.default_quantity_for_order_widget,
    budgetMultiplier: data.budget_multiplier,
    syncParams: data.sync_params,
    syncPrices: data.sync_prices,
    syncTextFields: data.sync_text_fields,
    syncDescriptionField: data.sync_description_field,
    syncShortDescriptionField: data.sync_short_description,
    syncReviwsField: data.sync_reviews,
    dripFeedIsAllowed: data.drip_feed_is_allowed,
    resaleMarginPercent: data.resale_margin_percent,
    promotableCheckerGroupId: data.promotable_checker_group_id,
    providers: data.providers,
    fullDescriptionLocation: data.full_description_location,
    slug: data.slug,
    ...createTranslationsErrors(data),
    liveStreamSupportMinutes: data.live_stream_support_minutes,
    liveStreamRetentionQuantityMultiplier: data.live_stream_retention_quantity_multiplier,
    forbidsOrdersWithSameUrl: data.forbids_orders_with_same_url,
  };
}
