<template>
  <div @click.stop="$emit('click')">
    <v-text-field
      v-bind="$attrs"
      ref="element"
      dense
      outlined
      :class="[
        'base-text-field',
        {
          'persistent-border': isPersistentBorder,
        }]"
      :clear-icon="clearIcon"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  props: {
    clearIcon: {
      type: String,
      default: '$icon-clear',
    },
    persistentBorder: Boolean,
  },
  computed: {
    isPersistentBorder() {
      return this.persistentBorder && this.$attrs.value && !!this.$attrs.value.length;
    },
  },
  methods: {
    blur() {
      this.$refs.element.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
.v-text-field__slot{
  @extend .p-16-r;
}
}
</style>
