export default function createPackageContent(data) {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    cost: data.cost,
    isArchived: data.is_archived,
    isEnabled: data.is_enabled,
    name: data.name,
    network: data.network,
    price: data.price,
    quantity: data.quantity,
    slug: data.slug,
    isDisplayable: data.is_displayable,
  };
}
