import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createCouponFromResource from '@/classes/createCoupon';
import urlFormatter from '@/services/url-formatter';
import handleErrors from '@/services/handle-errors';
import http, { cancelTokenNames } from '@/services/http';
import createCouponsStoreErrors from './createCouponsStoreErrors';
import createCouponsStoreRequest from './createCouponsStoreRequest';

const getURL = urlFormatter({
  list: '/api/v1/admin/coupons',
  collections: '/api/v1/admin/coupons/collections',
  update: '/api/v1/admin/coupons/{id}',
  store: '/api/v1/admin/coupons',
  statistics: '/api/v1/admin/coupons/statistics',
  statisticsByDate: '/api/v1/admin/coupons/statistic-by-date',
  archive: '/api/v1/admin/coupons/{id}/archive',
  unarchive: '/api/v1/admin/coupons/{id}/unarchive',
});

export default {
  async table({
    headers,
    options,
    search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const statusAll = search.status === '';
    const endpoint = getURL('list');
    const response = await http.get(`${endpoint}?${requestBody}&is_all=${statusAll}`, {
      applyCancelAdapter: cancelTokenNames.COUPONS_TABLE,
    });

    return {
      items: response.data.data.map(createCouponFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  async statistics({
    headers,
    options,
    search,
    groupBy,
    startDate,
    endDate,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('statistics');
    const response = await http.post(`${endpoint}?${requestBody}`, null, {
      applyCancelAdapter: cancelTokenNames.STATISTICS_TABLE,
      params: {
        group_by: groupBy,
        start_date: startDate,
        end_date: endDate,
      },
    });

    return {
      items: response.data.data,
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  collections() {
    const endpoint = getURL('collections');

    return http.get(endpoint)
      .then((response) => ({
        paymentTypes: response.data.data.payment_types,
        couponsBonusTypes: response.data.data.bonus_types,
        bonusAmountCalculationTypes: response.data.data.bonus_amount_calculation_types,
      }));
  },
  update(couponId, formData) {
    const endpoint = getURL('update', {
      id: couponId,
    });

    return http.patch(endpoint, createCouponsStoreRequest(formData))
      .then((response) => createCouponFromResource(response.data.data))
      .catch((error) => handleErrors(error, createCouponsStoreErrors));
  },
  store(formData) {
    const endpoint = getURL('store');

    return http.post(endpoint, createCouponsStoreRequest(formData))
      .then((response) => createCouponFromResource(response.data.data))
      .catch((error) => handleErrors(error, createCouponsStoreErrors));
  },
  async statisticsDate(value) {
    const endpoint = getURL('statisticsByDate');

    return http.post(endpoint, null, {
      params: {
        statistic_date: value,
      },
    })
      .then((response) => response.data.data);
  },
  async archive(id) {
    const endpoint = getURL('archive', {
      id,
    });

    await http.post(endpoint);
  },
  async unarchive(id) {
    const endpoint = getURL('unarchive', {
      id,
    });

    await http.post(endpoint);
  },
  getURL,
};
