<template>
  <v-date-picker
    v-model="Value"
    class="base-date-picker"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template
      v-for="(index, name) in $scopedSlots"
      #[name]="data"
    >
      <slot
        :name="name"
        v-bind="data"
      />
    </template>
  </v-date-picker>
</template>

<script>
import useVModel from '@/mixins/useVModel';

export default {
  mixins: [useVModel()],
  props: {
    value: {
      type: [Array, String],
      default: '',
    },
  },
};
</script>
