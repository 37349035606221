import {
  createResetButton,
} from '../builder-definition/filters'; import { createPlainText } from '../builder-definition/filters/page';

export default {
  commonSearch: null,
  id: '',
  user: '',
  type: '',
  transactionType: '',
  paymentMethod: '',
  orderId: '',
  amount: '',
  createdAt: '',
};

export const config = {
  defaultSearchPropName: 'id',
  exceptionFilters: ['status'],
  rows: [
    [
      createPlainText({
        filterProp: 'commonSearch',
        placeholder: 'Search',
      }),
      createResetButton(),
    ],
  ],
};
