import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  list: '/api/v1/admin/roles',
  store: '/api/v1/admin/roles',
  show: '/api/v1/admin/roles/{id}',
  update: '/api/v1/admin/roles/{id}',
  delete: '/api/v1/admin/roles/{id}',
});

export default {
  list() {
    const endpoint = getURL('list');

    return http.get(endpoint)
      .then((response) => response.data.data);
  },
  show({ id }) {
    const endpoint = getURL('show', { id });

    return http.get(endpoint)
      .then((response) => response.data.data);
  },
  store({
    name,
    permissions,
  }) {
    const endpoint = getURL('store');

    return http.post(endpoint, {
      name,
      permissions,
    });
  },
  update({
    id,
    name,
    permissions,
  }) {
    const endpoint = getURL('update', { id });

    return http.patch(endpoint, {
      name,
      permissions,
    });
  },
  delete({ id }) {
    const endpoint = getURL('delete', { id });

    return http.delete(endpoint);
  },
  getURL,
};
