export default function createStoreTransactionRequest(data) {
  if (!data) return null;

  return {
    user_id: data.userId,
    transaction_type: data.transactionType,
    amount: data.amount,
    comment: data.comment,
    balance_type: data.balanceType,
  };
}
