export default function createAvailableProviderFromResource(data) {
  if (!data) {
    return null;
  }

  return {
    id: data.id || null,
    panelId: data.panel_id,
    name: data.name,
    sourcePrice: {
      amount: data.source_price?.amount || null,
      currency: data.source_price?.currency || null,
    },
    sourcePricingPer: data.source_pricing_per || null,
    sourceEvent: data.source_event || null,
    targetEvent: data.target_event || null,
    type: data.type || null,
    createdAt: data.created_at || null,
    updatedAt: data.updated_at || null,
    lastStatsGatherPeriod: data.last_stats_gather_period || null,
    lastAvailableAt: data.last_available_at || null,
  };
}
