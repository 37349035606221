export default function createBulkPackagesEditErrors(data) {
  return {
    translations: Object.entries(data.translations).reduce((acc, [key, translation]) => {
      acc[key] = {
        name: translation.name,
      };

      return acc;
    }, {}),
    icon: data.icon,
    spending_goal: data.spendingGoal,
    discount_percent: (Number(data.discountPercent) / 100).toFixed(3),
    thumbnail_period: data.thumbnailPeriod,
    consultation_chat_period: data.consultationChatPeriod,
    available_closed_sales: Boolean(data.availableClosedSales),
    available_personal_manager: Boolean(data.availablePersonalManager),
  };
}
