import bulkRecreateDefaultState from '@/store/defaultStates/orders/recreateModal/bulk';
import singleRecreateDefaultState from '@/store/defaultStates/orders/recreateModal/single';
import commonRecreateDefaultState from '@/store/defaultStates/orders/recreateModal/common';
import RepositoryFactory from '@/services/repository-factory';

const providersRepository = RepositoryFactory.get('providers');

export default () => ({
  providersRepository,
  bulk: bulkRecreateDefaultState(),
  single: singleRecreateDefaultState(),
  common: commonRecreateDefaultState(),
});
