export default {
  openModal({
    commit, dispatch, state, services,
  }, {
    id, isShown, name, isArchive,
  }) {
    if (id) {
      commit('SET_OFFER_ID', id);
      dispatch('setName', {
        ...state.translations,
        [state.selectedLocale]: {
          name,
        },
      });
      dispatch('addServices', services || []);
      dispatch('setId', id);
      dispatch('setIsArchive', isArchive);
    }

    commit('SET_IS_SHOWN', isShown);
  },
  modalClose({
    commit, dispatch,
  }) {
    commit('SET_IS_SHOWN', false);
    dispatch('clearState');
  },
  setNetwork({ commit }, value) {
    commit('SET_NETWORK', value);
  },
  setIsArchive({ commit }, value) {
    commit('SET_IS_ARCHIVE', value);
  },
  clearState({ commit }) {
    commit('SET_CLEAR_STATE');
  },
  setId({
    commit, dispatch,
  }, value) {
    commit('SET_ID', value);
    dispatch('getOffer', value);
  },
  clearError({ commit }, value) {
    commit('CLEAR_ERROR', value);
  },
  setName({ commit }, value) {
    commit('SET_TRANSLATION', value);
  },
  setLocale({ commit }, value) {
    commit('SET_LOCALE', value);
  },
  addFilters({ commit }, value) {
    commit('SET_FILTERS', value);
  },
  setIsEnabled({ commit }, value) {
    commit('SET_IS_ENABLED', value);
  },
  async create({
    commit, state, rootGetters, dispatch,
  }, { errorNotify }) {
    const body = {
      place: state.place,
      position: state.position,
      services: state.selectedServices.map((service) => service.id),
      is_enabled: state.isEnabled,
      translations: state.translations,
      network_id: state.network,
    };

    await rootGetters['specialOffers/getRepository'].create(body)
      .then(() => {
        dispatch('modalClose');
        dispatch('specialOffers/table/fetch', null, { root: true });
      })
      .catch((data) => {
        commit('SET_ERRORS', data.errors);

        if (errorNotify) {
          errorNotify();
        }
      });
  },
  async delete({
    rootGetters, dispatch,
  }, id) {
    await rootGetters['specialOffers/getRepository'].delete(id)
      .then(() => {
        dispatch('clearState');
        dispatch('specialOffers/table/fetch', null, { root: true });
      });
  },
  async update({
    commit, state, rootGetters, dispatch,
  }, { errorNotify }) {
    const body = {
      services: state.selectedServices.map((service) => service.id),
      translations: state.translations,
      place: state.place,
      position: state.position,
      id: state.id,
      is_enabled: state.isEnabled,
      network_id: state.network?.id || state.network,
    };

    await rootGetters['specialOffers/getRepository'].update(body)
      .then(() => {
        dispatch('modalClose');
        dispatch('specialOffers/table/fetch', null, { root: true });
      })
      .catch((data) => {
        commit('SET_ERRORS', data.errors);

        if (errorNotify) {
          errorNotify();
        }
      });
  },
  async archive({
    state, rootGetters, dispatch,
  }) {
    if (state.isArchive) {
      await rootGetters['specialOffers/getRepository'].unarchive(state.id)
        .then(() => {
          dispatch('specialOffers/table/fetch', null, { root: true });
          dispatch('modalClose');
        });
    } else {
      await rootGetters['specialOffers/getRepository'].archive(state.id)
        .then(() => {
          dispatch('specialOffers/table/fetch', null, { root: true });
          dispatch('modalClose');
        });
    }
  },
  enable({
    rootGetters, dispatch,
  }, id) {
    rootGetters['specialOffers/getRepository'].enable(id)
      .then(() => {
        dispatch('specialOffers/table/fetch', null, { root: true });
      });
  },
  disable({
    rootGetters, dispatch,
  }, id) {
    rootGetters['specialOffers/getRepository'].disable(id)
      .then(() => {
        dispatch('specialOffers/table/fetch', null, { root: true });
      });
  },
  save({
    state, dispatch,
  }, notify) {
    if (state.id) {
      dispatch('update', notify);
    } else {
      dispatch('create', notify);
    }
  },
  setPlace({ commit }, value) {
    commit('SET_PLACE', value);
  },
  setPosition({ commit }, value) {
    commit('SET_POSITION', value);
  },
  addServices({ commit }, value) {
    commit('SET_SELECTED_SERVICES', value);
  },
  async fetchCollection({
    commit, rootGetters,
  }) {
    try {
      const {
        places, positions,
      } = await rootGetters['specialOffers/getRepository'].collection();

      commit('SET_PLACES', places);
      commit('SET_POSITIONS', positions);
    } catch (e) {
      commit('SET_ERRORS', e);
    }
  },
  async fetchServices({
    commit, state, rootGetters, dispatch,
  }, value) {
    commit('SET_LOADING', true);

    try {
      const services = await rootGetters['specialOffers/getRepository'].services({
        networkId: state.id && state.network?.id ? state.network.id : state.network,
        page: state.pageServices,
        search: value,
      });

      if (services.length === 0) {
        commit('SET_CAN_MOREL_LOAD_SERVICES', false);

        return;
      }

      dispatch('setServices', services);
    } catch (e) {
      commit('SET_ERRORS', e);
      commit('SET_LOADING', false);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  setServices({ commit }, newServices) {
    commit('SET_SERVICES', newServices);
  },
  setPageService({ commit }) {
    commit('SET_PAGE_SERVICES');
  },
  resetPageService({ commit }) {
    commit('RESET_PAGE_SERVICES', 1);
  },
  resetArrayServices({ commit }) {
    commit('RESET_SERVICES');
  },
  async fetchNetworks({
    commit, rootGetters,
  }) {
    try {
      const networks = await rootGetters['specialOffers/getRepository'].networks();

      commit('SET_NETWORKS', networks);
    } catch (e) {
      commit('SET_ERRORS', e);
    }
  },
  async getOffer({
    commit, dispatch, rootGetters,
  }, id) {
    try {
      const offer = await rootGetters['specialOffers/getRepository'].getOffer(id);

      dispatch('setNetwork', offer.network);
      dispatch('addServices', offer.services);
      dispatch('setPosition', offer.position);
      dispatch('setIsEnabled', offer.is_enabled);
      dispatch('setPlace', offer.place);
    } catch (e) {
      commit('SET_ERRORS', e);
    }
  },
  resetState({ dispatch }) {
    dispatch('clearState');
  },
};
