import { render, staticRenderFns } from "./BaseChip.vue?vue&type=template&id=8574c2fc&"
import script from "./BaseChip.vue?vue&type=script&lang=js&"
export * from "./BaseChip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports