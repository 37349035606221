import {
  createTextFilter,
  createProvidersTypeFilter,
  createProvidersFilter,
} from '../builder-definition/filters/table';
import {
  createCellPlainStatuses,
  createCellActions,
} from '../builder-definition/tables';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      name: 'providers.id',
      width: '68px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
      ...createCellPlainStatuses({
        mapper: 'providersServices',
        statusProp: 'status',
      }),
    },
    {
      value: 'name',
      name: 'name',
      text: 'Name',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search name' }),
    },
    {
      value: 'type',
      name: 'type',
      text: 'Type',
      sortable: false,
      width: '200px',
      isShown: true,
      customFilter: createProvidersTypeFilter(),
    },
    {
      text: 'Provider',
      value: 'externalPanel.name',
      name: 'external_panel',
      width: '200px',
      sortable: false,
      isShown: true,
      customFilter: createProvidersFilter(),
    },
    {
      text: 'Source price',
      value: 'sourcePrice',
      name: 'source_price',
      width: '200px',
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search source price' }),
    },
    {
      text: 'Source price per',
      value: 'sourcePricingPer',
      name: 'source_pricing_per',
      width: '200px',
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search source price per' }),
    },
    {
      text: 'Status',
      value: 'status',
      isHidden: true,
    },
    {
      value: 'actions',
      text: 'Actions',
      width: '60px',
      searchable: false,
      sortable: false,
      isShown: true,
      align: 'end',
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Edit',
              value: 'editable',
              prop: 'editable',
              callBack: (item, store) => {
                store.dispatch('providersServices/editorDialog/openProvidersService', item.id);
              },
            },
            {
              text: 'Archive',
              value: 'archivable',
              prop: 'archivable',
              callBack: (item, store) => {
                store.dispatch('providersServices/editorDialog/archiveService', item.id);
              },
            },
            {
              text: 'Restore',
              value: 'restore',
              prop: 'restore',
              callBack: (item, store) => {
                store.dispatch('providersServices/editorDialog/unarchiveService', item.id);
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;

            return tableElement.actions[prop]
              ? tableElement.actions[prop]
              : false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
