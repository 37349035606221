<template>
  <!-- eslint-disable max-len -->
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.42843 18.7856H15.9284C16.3332 18.7856 16.5356 18.988 16.5356 19.3928C16.5356 19.7975 16.3332 19.9999 15.9284 19.9999H7.42843C7.02367 19.9999 6.82129 19.7975 6.82129 19.3928C6.82129 18.988 7.02367 18.7856 7.42843 18.7856Z" fill="currentColor" />
    <path d="M17.1429 9.67858C17.1429 8.22936 16.5672 6.83949 15.5424 5.81474C14.5177 4.78999 13.1278 4.21429 11.6786 4.21429C10.2294 4.21429 8.83949 4.78999 7.81474 5.81474C6.78999 6.83949 6.21429 8.22936 6.21429 9.67858C6.21429 11.9201 8.00779 14.8417 11.6786 18.3413C15.3494 14.8417 17.1429 11.9201 17.1429 9.67858ZM11.6786 20C7.22579 15.9528 5 12.5115 5 9.67858C5 7.90731 5.70363 6.20859 6.95611 4.95611C8.20859 3.70363 9.90731 3 11.6786 3C13.4498 3 15.1486 3.70363 16.401 4.95611C17.6535 6.20859 18.3572 7.90731 18.3572 9.67858C18.3572 12.5115 16.1314 15.9528 11.6786 20Z" fill="currentColor" />
    <path d="M12.2864 9.0714H14.1078C14.2689 9.0714 14.4233 9.13536 14.5372 9.24922C14.651 9.36308 14.715 9.51751 14.715 9.67854C14.715 9.83956 14.651 9.99399 14.5372 10.1079C14.4233 10.2217 14.2689 10.2857 14.1078 10.2857H12.2864V12.1071C12.2864 12.2681 12.2224 12.4226 12.1086 12.5364C11.9947 12.6503 11.8403 12.7143 11.6793 12.7143C11.5182 12.7143 11.3638 12.6503 11.25 12.5364C11.1361 12.4226 11.0721 12.2681 11.0721 12.1071V10.2857H9.2507C9.08967 10.2857 8.93524 10.2217 8.82138 10.1079C8.70752 9.99399 8.64355 9.83956 8.64355 9.67854C8.64355 9.51751 8.70752 9.36308 8.82138 9.24922C8.93524 9.13536 9.08967 9.0714 9.2507 9.0714H11.0721V7.24997C11.0721 7.08894 11.1361 6.93451 11.25 6.82065C11.3638 6.70679 11.5182 6.64282 11.6793 6.64282C11.8403 6.64282 11.9947 6.70679 12.1086 6.82065C12.2224 6.93451 12.2864 7.08894 12.2864 7.24997V9.0714Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'IconDanger',
};
</script>
