export default class CreateWithdrawalOptionsFromResource {
  constructor(data) {
    this.minAmount = data?.min_amount || 0;
    this.maxAmount = data?.max_amount || 0;
    this.minFee = data?.min_fee || 0;
    this.feePercent = Number(data?.fee_percent || 0) * 100;
    this.availableForWithdrawal = data?.available_for_withdrawal || false;
  }

  getDataForRequest() {
    return {
      min_amount: this.minAmount,
      max_amount: this.maxAmount,
      min_fee: this.minFee,
      fee_percent: (Number(this.feePercent) / 100).toFixed(3),
      available_for_withdrawal: this.availableForWithdrawal,
    };
  }
}
