import dateFilter from '@/services/functions/date-filter';
import createPaymentMethodBonusFromResource from '@/classes/createPaymentMethodBonus';
import createPaymentMethodTranslations from '@/classes/createPaymentMethodTranslitions';
import CreateWithdrawalOptionsFromResource from '@/classes/createWithdrawalOptions';
import createPaymentMethodPublicFieldsFromResource from './createPaymentMethodPublicFields';

export default function createPaymentMethodFromResource(data) {
  return {
    actions: {
      disable: data?.actions?.disable || false,
      edit: data?.actions?.edit || false,
      enable: data?.actions?.enable || false,
    },
    createdAt: dateFilter(data.created_at),
    chargeCurrency: data.charge_currency,
    allowQrCode: data.allow_qr_code,
    paymentQrCode: data.payment_qr_code,
    chargeType: data.charge_type,
    isEnabled: data.is_enabled,
    feePercent: data.fee_percent * 100,
    icon: data.icon,
    id: data.id,
    isAllowedForNewUser: data.is_allowed_for_new_user,
    maxAmount: data.max_amount,
    minAmount: data.min_amount,
    name: data.name,
    paymentMethodBonuses: (data.payment_method_bonuses || [])
      .map(createPaymentMethodBonusFromResource),
    position: data.sorting,
    strategy: data.strategy,
    status: data.status,
    type: data.type,
    description: data.description,
    translations: createPaymentMethodTranslations(data.translations),
    withdrawalOptions: new CreateWithdrawalOptionsFromResource(data.withdrawal_options),
    publicFields: createPaymentMethodPublicFieldsFromResource(data.public_fields || {}),
  };
}
