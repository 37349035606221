export default {
  isShown: (state) => state.isShown,
  providerId: (state) => state.providerId,
  providers: (state) => state.providers,
  loading: (state) => state.loading,
  isOurPanel: (state) => state.isOurPanel,
  providerName: (state) => state.formData.name,
  providerEndPoint: (state) => state.formData.endpoint,
  providerApiToken: (state) => state.formData.apiToken,
  providerCurrency: (state) => state.formData.currency,
  syncServices: (state) => state.formData.syncServices,
  resaleMarginPercent: (state) => state.formData.resaleMarginPercent,
  syncPrices: (state) => state.formData.syncPrices,
  syncParams: (state) => state.formData.syncParams,
  syncLabels: (state) => state.formData.syncLabels,
  syncTextFields: (state) => state.formData.syncTextFields,
  syncDescriptionField: (state) => state.formData.syncDescriptionField,
  isArchived: (state) => state.formData.isArchived,
  autoRecreateOrdersIsAllowed: (state) => state.formData.autoRecreateOrdersIsAllowed,
  errors: (state) => state.errors,
  errorsMessage: (state) => state.errorsMessage,
};
