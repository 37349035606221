import invokeDialog from '@/components/services';
import waiter from '@/store/utils/waiter';

export default {
  async confirmedComplete({ dispatch }, comboOrderId) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('complete', comboOrderId);
      },
      amount: 1,
    }, 'completeComboOrder');
  },
  async complete({
    dispatch, getters, commit,
  }, comboOrderId) {
    await waiter('comboOrders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.complete({ comboOrderId });
    });
  },
};
