<template>
  <ThemeRenderless
    v-slot="{ isDark }"
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!--eslint-disable -->
      <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M7.87743 13.2062C4.03343 13.2062 0.750427 13.7872 0.750427 16.1152C0.750427 18.4422 4.01243 19.0452 7.87743 19.0452C11.7214 19.0452 15.0044 18.4632 15.0044 16.1362C15.0044 13.8092 11.7424 13.2062 7.87743 13.2062Z" :stroke="isDark ? '#FFFFFF' : '#11142D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M7.87745 9.88588C10.4134 9.88588 12.4454 7.85288 12.4454 5.31788C12.4454 2.78188 10.4134 0.749878 7.87745 0.749878C5.34245 0.749878 3.30945 2.78188 3.30945 5.31788C3.30945 7.85288 5.34245 9.88588 7.87745 9.88588Z" :stroke="isDark ? '#FFFFFF' : '#11142D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.7867 7.25692L18.6222 10.0924" stroke="#FF754C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.651 7.22797L15.7589 10.12" stroke="#FF754C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <!--eslint-enable -->
    </svg>
  </ThemeRenderless>
</template>

<script>
import ThemeRenderless from '@/components/ThemeRenderless.vue';

export default {
  components: {
    ThemeRenderless,
  },
};
</script>
