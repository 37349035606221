export default {
  SET_NEW_STATUSES_COUNTERS(state, {
    counterValue, counterName,
  }) {
    state.statusesCounters
      .filter((c) => c.name === counterName)
      .forEach((c) => {
        c.value = counterValue;
      });
  },
  SET_ALL_STATUSES_COUNTERS(state, payload) {
    Object.entries(payload).forEach(([key, val]) => {
      state.statusesCounters.filter((counter) => counter.name === key)
        .forEach((counter) => {
          counter.value = val;
        });
    });
  },
};
