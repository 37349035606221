import waiter from '@/store/utils/waiter';

export default {
  async confirmedDisable({
    dispatch, getters, commit,
  }, id) {
    await waiter('paymentMethods/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.disable(id);
    });
  },
  async confirmedEnable({
    dispatch, getters, commit,
  }, id) {
    await waiter('paymentMethods/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.enable(id);
    });
  },
};
