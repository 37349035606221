import fieldMapper from '@/services/helpers/fieldMapper';
import Translations from '@/classes/CreateTranslationByFieldMap';

const translationsFieldMap = {
  name: 'name',
};
const menuItemFieldMap = {
  id: 'id',
  icon: 'icon',
  url: 'url',
  openInNewTab: {
    receive: {
      name: 'open_in_new_tab',
      handler: Boolean,
    },
    send: 'open_in_new_tab',
  },
  showInInternal: {
    receive: {
      name: 'show_in_internal',
      handler: Boolean,
    },
    send: 'show_in_internal',
  },
  showInPublic: {
    receive: {
      name: 'show_in_public',
      handler: Boolean,
    },
    send: 'show_in_public',
  },
  translations: {
    receive: {
      name: 'translations',
      handler: (translations) => new Translations(translations, translationsFieldMap),
    },
    send: {
      name: 'translations',
      handler: (translations) => translations.getDataForRequest(),
    },
  },
};

export default class CustomMenuItem {
  constructor(customMenuItem, useMap = true) {
    if (useMap) {
      Object.assign(this, fieldMapper.receive(customMenuItem, menuItemFieldMap));
    } else {
      Object.assign(this, customMenuItem);
    }
  }

  getDataForRequest() {
    return fieldMapper.send(this, menuItemFieldMap);
  }
}
