<template>
  <v-dialog
    v-model="isShown"
    max-width="600"
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text>
        {{ body }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          text
          @click="action(false)"
        >
          {{ disagreeButtonText }}
        </v-btn>

        <v-btn
          class="primary"
          @click="action(true)"
        >
          {{ agreeButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import eventBus, { eventTypes } from '@/eventBus';

const initialState = () => ({
  isShown: false,
  title: '',
  body: '',
  disagreeButtonText: 'Disagree',
  agreeButtonText: 'Agree',
  callback: () => {},
});

export default {
  data: () => initialState(),
  created() {
    eventBus.$on(eventTypes.CONFIRM, ({
      title,
      body,
      disagreeButtonText,
      agreeButtonText,
      callback = () => {},
    } = {}) => {
      this.title = title || this.title;
      this.body = body || this.body;
      this.disagreeButtonText = disagreeButtonText || this.disagreeButtonText;
      this.agreeButtonText = agreeButtonText || this.agreeButtonText;
      this.callback = callback;
      this.isShown = true;
    });
  },
  methods: {
    action(confirm) {
      if (confirm) {
        this.callback();
      }

      this.reset();
    },
    reset() {
      Object.entries(initialState())
        .forEach(([key, value]) => {
          this[key] = value;
        });
    },
  },
};
</script>
