export default class ServiceTypeTranslation {
  constructor(data) {
    this.fullDescription = data?.full_description || '';
    this.metaTitle = data?.meta_title || '';
    this.metaDescription = data?.meta_description || '';
    this.description = data?.description || '';
    this.name = data?.name || '';
  }

  getDataForRequest() {
    return {
      full_description: this.fullDescription,
      meta_title: this.metaTitle,
      meta_description: this.metaDescription,
      description: this.description,
      name: this.name,
    };
  }
}
