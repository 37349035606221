import plainMutationsApplier from '@/store/utils/plainMutationsApplier';
import plainActionsApplier from '@/store/utils/plainActionsApplier';
import plainGettersApplier from '@/store/utils/plainGettersApplier';

export default (state) => ({
  ...state,
  mutations: plainMutationsApplier(state),
  actions: plainActionsApplier(state),
  getters: plainGettersApplier(state),
});
