import RepositoryFactory from '@/services/repository-factory';

const freeTrialsRepository = RepositoryFactory.get('freeTrials');

export default {
  namespaced: true,
  state: {
    errors: {
      store: {},
    },
    freeTrialsList: [],
    updateTrialsList: {},
  },
  getters: {
    freeTrialsList: (state) => state.freeTrialsList,
    updateTrialsList: (state) => state.updateTrialsList,
    errors: (state) => state.errors,
  },
  mutations: {
    SET_FREE_TRIALS(state, value) {
      state.freeTrialsList = value;
    },
    SET_FREE_TRIAL(state, value) {
      state.updateTrialsList = {
        ...state.updateTrialsList,
        ...value,
      };
    },
    SET_ERRORS(state, value) {
      state.errors = value;
    },
    CLEAR_FREE_TRIAL(state, value) {
      delete state.updateTrialsList[value];
    },
    FREE_TRIALS_ARCHIVE(state, value) {
      state.freeTrialsList = state.freeTrialsList.filter((freeTrial) => freeTrial.id !== value.id);
    },
    UPDATE_FREE_TRIALS(state, value) {
      state.freeTrialsList = state.freeTrialsList.filter((freeTrial) => freeTrial.id !== value.id);
    },
  },
  actions: {
    async create({ commit }, payload) {
      try {
        const response = await freeTrialsRepository.store(payload);

        commit('SET_FREE_TRIALS', response.data.data);
      } catch (e) {
        if (e.response?.data?.errors) {
          commit('SET_ERRORS', { store: e.response.data.errors });
        }
      }
    },
    async archive({ commit }, id) {
      await freeTrialsRepository.archive(id);
      commit('FREE_TRIALS_ARCHIVE', { id });
      commit('CLEAR_FREE_TRIAL', id);
    },
    getFreeTrials({ commit }) {
      freeTrialsRepository.list()
        .then((freeTrials) => {
          commit('SET_FREE_TRIALS', freeTrials);
        });
    },
    getFreeTrial({ commit }, id) {
      freeTrialsRepository.show(id)
        .then((freeTrial) => {
          commit('SET_FREE_TRIAL', { [id]: freeTrial });
        });
    },
    clearFreeTrial({ commit }, id) {
      commit('CLEAR_FREE_TRIAL', id);
    },
    async updateFreeTrials({ commit }, payload) {
      try {
        const response = await freeTrialsRepository.update(payload);

        commit('UPDATE_FREE_TRIALS', response);
      } catch (e) {
        if (e.response?.data?.errors) {
          commit('SET_ERRORS', { [payload.id]: e.response.data.errors });
        }
      }
    },
  },
};
