export default ({
  to,
  textProp,
}) => ({
  componentName: 'cell-name-info',
  componentArgs: {
    to,
    textProp,
  },
});
