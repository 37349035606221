import settings from '@/settings';
import {
  createDateFilter,
  createTextFilter,
  createPaymentMethodsFilter,
} from '../builder-definition/filters/table';
import {
  createCellPlainStatuses, createCellActions, createCellLink, createCellMoney,
} from '../builder-definition/tables';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      width: '68px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
      ...createCellPlainStatuses({
        mapper: 'invoices',
        statusProp: 'status',
      }),
    },
    {
      value: 'user',
      name: 'users.email',
      text: 'Users',
      width: '61px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search User' }),
      ...createCellLink({
        textProp: 'user.email',
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.user.id,
          },
        }),
      }),
    },
    {
      value: 'paymentMethod.name',
      text: 'Payment method',
      name: 'paymentMethod.type',
      width: '132px',
      sortable: false,
      isShown: true,
      permission: settings.permissions.viewPaymentMethods,
      customFilter: createPaymentMethodsFilter(),
    },
    {
      value: 'amount',
      text: 'Amount',
      width: '72px',
      isShown: true,
      ...createCellMoney(),
    },
    {
      value: 'shortName',
      text: 'Name invoice',
      name: 'short_name',
      width: '106px',
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search name' }),
    },
    {
      value: 'paidAt',
      text: 'Paid at',
      name: 'invoices.paid_at',
      width: '88px',
      isShown: true,
      customFilter: createDateFilter(),
    },
    {
      name: 'invoices.created_at',
      value: 'createdAt',
      text: 'Created at',
      width: '90px',
      isShown: true,
      customFilter: createDateFilter(),
    },
    {
      text: 'Status',
      value: 'status',
      isHidden: true,
    },
    {
      value: 'actions',
      text: 'Actions',
      width: '60px',
      searchable: false,
      sortable: false,
      isShown: true,
      align: 'end',
      permission: settings.permissions.manageInvoices,
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Mark as paid',
              value: 'canBeMarkedAsPaid',
              prop: 'canBeMarkedAsPaid',
              callBack: (item, store) => {
                store.dispatch('invoices/cancelModal/setMarkAsPaidModalOpen', {
                  id: item.id,
                  amount: item.amount,
                  isShown: true,
                });
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;

            return tableElement.actions[prop]
              ? tableElement.actions[prop]
              : false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
