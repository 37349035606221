import waiter from '@/store/utils/waiter';

export default {
  async fetchsubscriptionsCollections({
    getters, commit,
  }) {
    commit('SET_SUBSCRIPTIONS_COLLECTIONS_IS_LOADING', true);

    const collections = await getters.getRepository.collections();

    commit('SET_SUBSCRIPTIONS_COLLECTIONS_IS_LOADING', false);
    commit('SET_SUBSCRIPTIONS_COLLECTIONS', collections.billingTypes);
  },
  checksubscriptionsCollections({
    getters, dispatch,
  }) {
    if (getters.getSubscriptionsCollections) return;
    dispatch('fetchsubscriptionsCollections');
  },
  async cancelSubscription({
    getters, dispatch, commit,
  }, subscriptionId) {
    await waiter('subscriptions/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.cancel(subscriptionId);
    });
  },
};
