export default {
  async setQueryParams({ commit }, queries) {
    commit('SET_QUERIES', queries);
  },
  async discardQueries({
    commit, dispatch,
  }) {
    commit('SET_QUERIES', {});
    dispatch('fetch');
  },
};
