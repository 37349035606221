import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createService from '@/classes/createService';
import handleErrors from '@/services/handle-errors';
import http from '../../http';
import urlFormatter from '../../url-formatter';
import createServiceStoreErrors from './create-service-store-errors';
import createServiceStoreRequest from './create-service-store-request';
import createBulkServicesEditRequest from './create-bulk-services-edit-request';
import createBulkServicesEditErrors from './create-bulk-services-edit-errors';

const getURL = urlFormatter({
  list: '/api/v1/admin/services',
  store: '/api/v1/admin/services',
  show: '/api/v1/admin/services/{serviceId}',
  update: '/api/v1/admin/services/{serviceId}',
  bulkUpdate: '/api/v1/admin/services/bulk',
  archive: '/api/v1/admin/services/{serviceId}/archive',
  unarchive: '/api/v1/admin/services/{serviceId}/unarchive',
  copy: '/api/v1/admin/services/{serviceId}/copy',
});
const servicesRepository = {
  async all() {
    const endpoint = getURL('list');

    try {
      const { data } = await http.get(endpoint);

      return data.data.map(createService);
    } catch (error) {
      return handleErrors(error, createServiceStoreErrors);
    }
  },
  async list(queryParams) {
    const endpoint = getURL('list');
    const { data } = await http.get(
      `${endpoint}?${datatableFormaterStringified(queryParams)}`,
      { params: { is_archived: Number(queryParams.search.isArchived) } },
    );

    return {
      items: data.data.map(createService),
      totalItemsCount: data.recordsFiltered,
    };
  },
  async show(serviceId) {
    const endpoint = getURL('show', { serviceId });
    const { data } = await http.get(endpoint);

    return createService(data.data);
  },
  async store(data) {
    const endpoint = getURL('store');

    try {
      const response = await http.post(endpoint, createServiceStoreRequest(data));

      return createService(response.data.data);
    } catch (error) {
      return handleErrors(error, createServiceStoreErrors);
    }
  },
  async update(data) {
    const endpoint = getURL('update', { serviceId: data.id });

    try {
      const response = await http.put(endpoint, createServiceStoreRequest(data));

      return createService(response.data.data);
    } catch (error) {
      return handleErrors(error, createServiceStoreErrors);
    }
  },
  async bulkUpdate(services, formData) {
    const endpoint = getURL('bulkUpdate');

    try {
      return await http.patch(endpoint, createBulkServicesEditRequest(services, formData));
    } catch (error) {
      return handleErrors(error, createBulkServicesEditErrors);
    }
  },
  async archive(serviceId) {
    const endpoint = getURL('archive', { serviceId });

    try {
      const { data } = await http.post(endpoint);

      return createService(data.data);
    } catch (error) {
      return handleErrors(error, createServiceStoreErrors);
    }
  },
  async unarchive(serviceId) {
    const endpoint = getURL('unarchive', { serviceId });

    try {
      const { data } = await http.post(endpoint);

      return createService(data.data);
    } catch (error) {
      return handleErrors(error, createServiceStoreErrors);
    }
  },
  async copy(serviceId) {
    const endpoint = getURL('copy', { serviceId });

    try {
      const { data } = await http.post(endpoint);

      return createService(data.data);
    } catch (error) {
      return handleErrors(error, createServiceStoreErrors);
    }
  },
};

export default servicesRepository;
