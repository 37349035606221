export default function createAutoRefillFromResource(data) {
  return {
    id: data.id,
    autoRefillIsAllowed: data.auto_refill_is_allowed,
    brokenPipes: data.broken_pipes,
    dropQuantity: data.drop_quantity,
    orderCurrentRefilledQuantity: data.order_current_refilled_quantity,
    orderQuantity: data.order_quantity,
    ordersWithSamePromotable: Object.values(data.orders_with_same_promotable),
    refillQuantity: data.refill_quantity,
  };
}
