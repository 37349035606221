import createServiceFromResource from '@/classes/createService';

export default function createSubscriptionServiceByResource(data) {
  return {
    cost: data.cost,
    countries: data.countries || [],
    quantity: data.quantity,
    service: createServiceFromResource(data.service),
  };
}
