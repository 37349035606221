import stateExtender from '@/store/utils/stateExtender';
import RepositoryFactory from '@/services/repository-factory';
import datatableModule from '@/store/builder/datatableModule';
import table from '@/components/builders/configs/tables/withdrawalTable';
import filters from '@/components/builders/configs/filters/withdrawalFilters';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import messageModal from './submodules/messageModal';

const withdrawalRepository = RepositoryFactory.get('finances');
const state = {
  repository: withdrawalRepository,
  error: null,
};

export default stateExtender({
  namespaced: true,
  state: () => state,
  modules: {
    table: datatableModule({
      fetch: withdrawalRepository.withdrawalsTable,
      headers: table.headers.filter((header) => header.isShown),
      allHeaders: table.headers,
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 30,
      },
      search: filters,
    }),
    messageModal,
  },
  getters,
  mutations,
  actions,
});
