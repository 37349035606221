import createPaymentMethodTranslationsRequest from '@/classes/createPaymentMethodTranslationsRequest';

export default function createPaymentRequestBody(formData) {
  const requestBody = {
    fee_percent: formData.feePercent / 100,
    icon: formData.icon,
    allow_qr_code: formData.allowQrCode,
    payment_qr_code: formData.paymentQrCode,
    id: formData.id,
    is_allowed_for_new_user: formData.isAllowedForNewUser,
    max_amount: formData.maxAmount,
    min_amount: formData.minAmount,
    name: formData.name,
    sorting: formData.position,
    type: formData.type,
    is_enabled: formData.isEnabled,
    translations: createPaymentMethodTranslationsRequest(formData.translations),
    secrets: formData.secrets.reduce((acc, value) => ({
      ...acc,
      [value.key]: value.default || null,
    }), {}),
    withdrawal_options: {
      available_for_withdrawal: formData.withdrawalOptions.availableForWithdrawal,
      fee_percent: (Number(formData.withdrawalOptions.feePercent) / 100).toFixed(3),
      max_amount: formData.withdrawalOptions.maxAmount,
      min_amount: formData.withdrawalOptions.minAmount,
      min_fee: formData.withdrawalOptions.minFee,
    },
    public_fields: {
      is_user_comment: formData.publicFields?.isUserComment,
      qr_code: formData.publicFields?.qrCode,
    },
  };

  if (typeof formData.paymentQrCode === 'string' && formData.paymentQrCode.startsWith('http')) {
    delete requestBody.payment_qr_code;
  }

  if (typeof formData.icon === 'string' && formData.icon.startsWith('http')) {
    delete requestBody.icon;
  }

  return requestBody;
}
