export default {
  SET_SINGLE_RECREATE_STATE: (state, val) => { state.single = val; },
  SET_BULK_RECREATE_STATE: (state, val) => { state.bulk = val; },
  SET_RECREATE_MODAL_STATE: (state, val) => { state.common = val; },

  SET_AVAILABLE_PROVIDERS: (state, val) => {
    state.common.availableProviders.items = val;
  },
  SET_AVAILABLE_FOUND_PROVIDERS: (state, val) => {
    state.common.availableProviders.found = val;
  },
  SET_AVAILABLE_PROVIDERS_LOADING: (state, val) => {
    state.common.availableProviders.loading = val;
  },

  SET_BULK_RECREATE_IDS: (state, val) => { state.bulk.ids = val; },
  SET_SINGLE_RECREATE_ID: (state, val) => { state.single.id = val; },

  SET_CURRENT_PAGE: (state, val) => { state.common.availableProviders.currentPage = val; },
  SET_LAST_PAGE: (state, val) => { state.common.availableProviders.lastPage = val; },
  SET_SELECTED_PROVIDER_ID: (state, val) => { state.common.selectedProviderId = val; },

  SET_SEARCH_STRING: (state, val) => {
    state.common.availableProviders.searchString = val;
  },
  SET_RECREATE_QUANTITY: (state, val) => {
    state.single.quantity = val;
  },
  SET_RECREATE_REFRESH_TABLE: (state, val) => { state.common.refreshTable = val; },
  SET_IS_RECREATING_PROCESS: (state, val) => { state.common.isRecreatingProcess = val; },
  SET_RECREATE_ERRORS: (state, val) => { state.common.errors = val; },
  SET_RECREATE_QUANTITY_ERROR: (state, val) => { state.common.errors.quantity = val; },
  SET_RECREATE_PROVIDER_ID_ERROR: (state, val) => { state.common.errors.providerId = val; },
};
