import { defaultState } from './state';

export default {
  SET_CLEAR_STATE: (state) => {
    Object.assign(state, defaultState());
  },
  SET_PROVIDER_ID: (state, value) => { state.providerId = value; },
  SET_IS_SHOWN: (state, value) => { state.isShown = value; },
  SET_LOADING: (state, value) => { state.loading = value; },
  SET_ERRORS: (state, value) => { state.errors = value; },
  SET_ERRORS_MESSAGE: (state, value) => { state.errorsMessage = value; },
  SET_OUR_PANEL: (state, value) => { state.isOurPanel = value; },
  SET_PROVIDERS: (state, value) => {
    state.providers = value.map((provider) => ({
      ...provider,
      createAccount: provider.isUsed,
    }));
  },
  RESET_PROVIDERS: (state) => {
    state.providers = state.providers.map((provider) => ({
      ...provider,
      createAccount: false,
    }));
  },
  SET_ACCOUNT_CREATED: (state, index) => {
    state.providers[index].createAccount = true;

    state.providers.forEach((provider, i) => {
      if (i !== index && !provider.isUsed) {
        provider.createAccount = false;
      }
    });
  },
  SET_FORM_DATA: (state, value) => { state.formData = { ...value }; },
  SET_PROVIDER_NAME: (state, value) => { state.formData.name = value; },
  SET_PROVIDER_END_POINT: (state, value) => { state.formData.endpoint = value; },
  SET_PROVIDER_API_TOKEN: (state, value) => { state.formData.apiToken = value; },
  SET_PROVIDER_CURRENCY: (state, value) => { state.formData.currency = value; },
  SET_PROVIDER_SYNC_SERVICES: (state, value) => { state.formData.syncServices = value; },
  SET_PROVIDER_SYNC_PRICES: (state, value) => { state.formData.syncPrices = value; },
  SET_PROVIDER_RESALE_MARGIN_PERCENT: (state, value) => {
    state.formData.resaleMarginPercent = value;
  },
  SET_PROVIDER_AUTO_RECREATE_ORDERS_IS_ALLOWED: (state, value) => {
    state.formData.autoRecreateOrdersIsAllowed = value;
  },
};
