export default function createBulkServicesEditErrors(errors) {
  return {
    isEnabled: errors?.['services.0.is_enabled'] || [],
    isArchived: errors?.['services.0.is_archived'] || [],
    dripFeedIsAllowed: errors?.['services.0.drip_feed_is_allowed'] || [],
    apiAccess: errors?.['services.0.api_access'] || [],
    restrictedAccess: errors?.['services.0.restricted_access'] || [],
    moderationIdEnabled: errors?.['services.0.moderation_is_enabled'] || [],
    hasRefill: errors?.['services.0.has_refill'] || [],
    syncPrices: errors?.['services.0.sync_prices'] || [],
    syncParams: errors?.['services.0.sync_params'] || [],
    syncTextFields: errors?.['services.0.sync_text_fields'] || [],
    resaleMarginPercent: errors?.['services.0.resale_margin_percent'] || [],
    cancelByClientIsAllowed: errors?.['services.0.cancel_by_client_is_allowed'] || [],
    autoCancel: errors?.['services.0.auto_cancel'] || [],
    forbidsOrdersWithSameUrl: errors?.['services.0.forbids_orders_with_same_url'] || [],
  };
}
