export default {
  SET_SINGLE_CANCEL_STATE: (state, val) => { state.single = val; },
  SET_BULK_CANCEL_STATE: (state, val) => { state.bulk = val; },
  SET_BULK_CANCEL_MODAL_STATE: (state, val) => { state.common.modal = val; },

  SET_IS_SAVING_PROCESS: (state, val) => { state.common.modal.isSavingProcess = val; },
  SET_DEFAULT_AMOUNT_LOADING: (state, val) => {
    state.single.isDefaultAmountLoading = val;
  },
  SET_CANCELLING_ERRORS: (state, val) => { state.common.modal.errors = val; },

  SET_AVAILABLE_CANCEL_REASONS: (state, val) => { state.common.availableReasons = val; },
  SET_AVAILABLE_CANCEL_REASONS_LOADING: (state, val) => {
    state.common.availableReasonsLoading = val;
  },

  SET_BULK_CANCEL_IDS: (state, val) => { state.bulk.ids = val; },
  SET_SINGLE_CANCEL_ID: (state, val) => { state.single.id = val; },

  SET_CANCEL_REASON: (state, val) => { state.common.modal.reason = val; },
  SET_CANCEL_REASON_ERROR: (state, val) => { state.common.modal.errors.cancelReason = val; },

  SET_CANCEL_DESCRIPTION: (state, val) => { state.common.modal.description = val; },
  SET_CANCEL_DESCRIPTION_ERROR: (state, val) => {
    state.common.modal.errors.cancelDescription = val;
  },

  SET_CANCEL_AMOUNT: (state, val) => { state.single.amount = val; },
  SET_CANCEL_AMOUNT_ERROR: (state, val) => {
    state.common.modal.errors.amount = val;
  },

  SET_IS_REFUND_NEEDED: (state, val) => { state.common.modal.isRefundNeeded = val; },
  SET_SEND_EMAIL_NOTIFICATION: (state, val) => { state.common.modal.sendEmailNotification = val; },
  SET_REFRESH_TABLE: (state, val) => { state.common.refreshTable = val; },
};
