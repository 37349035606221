export default function createPromotableCheckerGroup(data) {
  return {
    id: data.id,
    name: data.name,
    serviceTypeType: data.service_type_type,
    promotableCheckers: (data.promotable_checkers || [])
      .map((checker) => ({
        id: checker.id,
        name: checker.name,
      })),
  };
}
