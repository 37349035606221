<template>
  <div>
    <div class="prepended-row">
      <slot name="prepend-footer-row" />
    </div>

    <div class="d-flex align-center base-paginator__container px-6">
      <div class="paginator">
        <div class="base-paginator__text d-inline-block mr-6 ml-n6">
          Rows per page:
        </div>

        <div class="select d-inline-block">
          <base-select
            :value="dataOptions.itemsPerPage"
            :items="paginationOptions"
            @change="changeItemsPerPage"
          />
        </div>

        <div class="base-paginator__text d-inline-block mx-6">
          {{ pagination.pageStart }}-{{ pagination.pageStop }} of {{ pagination.itemsLength }}
        </div>

        <div class="base-paginator d-inline-block">
          <base-btn
            icon
            plain
            text
            class="mr-8"
            :disabled="!pagination.pageStart"
            @click="changePage(-1)"
          >
            <v-icon class="rotated-right">
              $icon-caret-down
            </v-icon>
          </base-btn>

          <base-btn
            icon
            plain
            text
            :disabled="pagination.pageStop === pagination.itemsLength"
            @click="changePage(1)"
          >
            <v-icon class="rotated-left">
              $icon-caret-down
            </v-icon>
          </base-btn>
        </div>
      </div>

      <div class="prepend">
        <slot name="paginator.prepend" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseItemsPerPageOptions from '../Base/models/BaseDataTable';

export default {
  props: {
    itemsPerPageText: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
    itemsPerPage: {
      type: Array,
      default: () => BaseItemsPerPageOptions,
    },
  },
  data() {
    return {
      dataOptions: {
        itemsPerPage: 10,
        page: 1,
        ...this.options,
      },
    };
  },
  computed: {
    paginationOptions() {
      return this.itemsPerPage.map((el) => ({
        value: el,
        text: el > 0 ? el : 'All',
      }));
    },
  },
  watch: {
    options(newVal) {
      this.dataOptions = {
        ...this.dataOptions,
        ...newVal,
      };
    },
  },
  methods: {
    changePage(val) {
      this.dataOptions.page += val;
      this.update();
    },
    update() {
      this.$emit('update:options', this.dataOptions);
    },
    changeItemsPerPage(val) {
      this.dataOptions.itemsPerPage = val;
      this.update();
    },
  },
};
</script>

<style lang="scss">
.rotated-left{
  transform: rotate(-90deg);
}
.rotated-right{
  transform: rotate(90deg);
}
.select{
    max-width: 92px;
  }
.base-paginator {
  &__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #B2B3BD;
  }
  &__container{
    background-color: var(--v-paginatorBgc-base);
    height: 72px;
    flex-direction: row-reverse;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
  }
}
.prepended-row {
  background-color: transparent;
}
</style>
