import stateExtender from '@/store/utils/stateExtender';
import RepositoryFactory from '@/services/repository-factory';
import datatableModule from '@/store/builder/datatableModule';
import table from '@/components/builders/configs/tables/discountTable';
import filters from '@/components/builders/configs/filters/discountFilters';
import discountModal from './submodules/discountModal';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const discountRepository = RepositoryFactory.get('discounts');
const state = {
  repository: discountRepository,
  error: null,
};

export default stateExtender({
  namespaced: true,
  state: () => state,
  modules: {
    table: datatableModule({
      fetch: discountRepository.table,
      headers: table.headers.filter((header) => header.isShown),
      allHeaders: table.headers,
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 30,
      },
      search: filters,
    }),
    discountModal,
  },
  getters,
  mutations,
  actions,
});
