import RepositoryFactory from '@/services/repository-factory';

const targetAttachmentsRepository = RepositoryFactory.get('targets');

export default {
  namespaced: true,

  state: {
    targets: [],
    types: [],
  },

  mutations: {
    SET_TARGET_ATTACHMENTS: (state, value) => {
      state.targets = value;
    },
    SET_TARGET_ATTACHMENT_TYPES: (state, value) => { state.types = value; },
  },

  actions: {
    fetchTargets({ commit }) {
      return targetAttachmentsRepository.listTargets()
        .then((targets) => {
          commit('SET_TARGET_ATTACHMENTS', targets);
        });
    },
    fetchTypes({ commit }) {
      return targetAttachmentsRepository.listTypes()
        .then((targetAttachmentTypes) => {
          commit('SET_TARGET_ATTACHMENT_TYPES', targetAttachmentTypes);
        });
    },
  },

  getters: {},
};
