export default {
  openProviderModal({ dispatch }, id) {
    if (id) {
      dispatch('setProviderId', id);
      dispatch('getProvider', id);
    }

    dispatch('getCollections');
    dispatch('setIsShown', true);
  },
  closeProviderModal({
    dispatch,
    commit,
  }) {
    dispatch('setIsShown', false);
    commit('SET_CLEAR_STATE');
  },
  setIsShown({ commit }, bool) {
    commit('SET_IS_SHOWN', bool);
  },
  setProviderId({ commit }, id) {
    commit('SET_PROVIDER_ID', id);
  },
  async getProvider({
    commit,
    rootGetters,
    dispatch,
  }, id) {
    await rootGetters['providers/getRepository'].show({ id })
      .then((response) => {
        commit('SET_FORM_DATA', response);
        dispatch('validateProvider');
      });
  },
  getCollections({
    commit,
    rootGetters,
  }) {
    rootGetters['providers/getRepository'].collections()
      .then((response) => {
        commit('SET_PROVIDERS', response.topProviders);
      });
  },
  setProviderName({ commit }, value) {
    commit('SET_PROVIDER_NAME', value);
  },
  setProviderEndPoint({ commit }, value) {
    commit('SET_PROVIDER_END_POINT', value);
  },
  setProviderApiToken({ commit }, value) {
    commit('SET_PROVIDER_API_TOKEN', value);
  },
  setProviderCurrency({ commit }, value) {
    commit('SET_PROVIDER_CURRENCY', value);
  },
  setProviderAutoRecreateOrdersIsAllowed({ commit }, value) {
    commit('SET_PROVIDER_AUTO_RECREATE_ORDERS_IS_ALLOWED', value);
  },
  setProviderSyncServices({ commit }, value) {
    commit('SET_PROVIDER_SYNC_SERVICES', value);
  },
  setProviderResaleMarginPercent({ commit }, value) {
    commit('SET_PROVIDER_RESALE_MARGIN_PERCENT', value);
  },
  setProviderSyncPrices({ commit }, value) {
    commit('SET_PROVIDER_SYNC_PRICES', value);
  },
  setIsOurPanel({ commit }, value) {
    commit('SET_OUR_PANEL', value);
  },
  createAccount({
    commit,
    rootGetters,
    dispatch,
  }, obj) {
    commit('SET_ERRORS', {});
    rootGetters['providers/getRepository'].createAccount({ endpoint: obj.url })
      .then((response) => {
        dispatch('setProviderApiToken', response.apiToken);
        dispatch('setProviderEndPoint', response.endpoint);
        dispatch('setProviderName', obj.name);
        dispatch('setProviderCurrency', 'USD');
        commit('SET_ACCOUNT_CREATED', obj.index);
        dispatch('validateProvider');

        if (obj.successNotify) {
          obj.successNotify();
        }
      });
  },
  validateProvider({
    state,
    rootGetters,
    dispatch,
    commit,
  }) {
    return new Promise((resolve, reject) => {
      rootGetters['providers/getRepository'].validate({ endpoint: state.formData.endpoint })
        .then((response) => {
          dispatch('setIsOurPanel', response.isOurPanel);

          if (response.isOurPanel) {
            dispatch('setProviderEndPoint', response.endpoint);
          } else {
            if (response.endpoint !== 'https://') {
              dispatch('setProviderEndPoint', response.endpoint);
            }

            dispatch('setProviderSyncServices', false);
          }

          resolve();
        })
        .catch((error) => {
          const errorMessage = error instanceof Error ? error.message : error;

          commit('SET_ERRORS', error.messages);
          commit('SET_ERRORS_MESSAGE', errorMessage);
          reject(error);
        });
    });
  },
  send({
    commit,
    state,
    rootGetters,
    dispatch,
  }, { errorNotify }) {
    commit('SET_LOADING', true);

    const request = state.providerId
      ? rootGetters['providers/getRepository'].update(state.providerId, state.formData)
      : rootGetters['providers/getRepository'].store(state.formData);

    request
      .then(() => {
        dispatch('providers/table/fetch', null, { root: true });
        dispatch('closeProviderModal');
      })
      .catch((error) => {
        const errorMessage = error instanceof Error ? error.message : error;

        if (error.messages) {
          commit('SET_ERRORS', error.messages);
          commit('SET_ERRORS_MESSAGE', errorMessage);
        }

        if (errorNotify) {
          errorNotify(errorMessage);
        }
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
};
