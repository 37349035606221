export default {
  SET_IS_SHOWN: (state, boolean) => { state.isShown = boolean; },
  SET_PAYMENT_METHOD_ID: (state, id) => { state.paymentMethodId = id; },
  SET_PAYMENT_METHOD_BONUSES: (state, val) => { state.paymentMethodBonuses = [...val]; },
  SET_ERRORS: (state, val) => { state.errors = val; },
  SET_ERRORS_MESSAGE: (state, val) => { state.errorsMessages = val; },
  ADD_BONUS: (state, val) => {
    state.paymentMethodBonuses.push(val);
  },
  REMOVE_BONUS: (state, index) => {
    state.paymentMethodBonuses.splice(index, 1);
  },
};
