export default {
  SET_SHOWN_EDITOR_DIALOG: (state, val) => { state.shownEditorDialog = val; },
  SET_CREATE_EDITOR_DIALOG: (state, val) => { state.isCreated = val; },
  SET_ERRORS: (state, val) => { state.errors = val; },
  CLEAR_ERRORS: (state, key) => { state.errors[key] = []; },
  CLEAR_ALL_ERRORS: (state) => { state.errors = {}; },
  SET_LOADED_GRADE: (state, value) => { state.grade = { ...value }; },
  ADD_NEW_TRANSLATION: (state, {
    translationKey,
    data,
  }) => {
    state.grade.translations = {
      ...state.grade.translations,
      [translationKey]: data,
    };
  },
  SET_TRANSLATIONS: (state, data) => { state.grade.translations[data.lang][data.key] = data.value; },
  SET_ICON: (state, value) => { state.grade.icon = value; },
  SET_SPENDING_GOAL: (state, value) => { state.grade.spendingGoal = value; },
  SET_DISCOUNT_PERCENT: (state, value) => { state.grade.discountPercent = value; },
  SET_THUMBNAIL_PERIOD: (state, value) => { state.grade.thumbnailPeriod = value; },
  SET_CONSULTATION_CHAT_PERIOD: (state, value) => { state.grade.consultationChatPeriod = value; },
  SET_AVAILABLE_CLOSED_SALES: (state, value) => { state.grade.availableClosedSales = value; },
  SET_AVAILABLE_PERSONAL_MANAGER: (state, value) => { state.grade.availablePersonalManager = value; },
};
