import stateExtender from '@/store/utils/stateExtender';
import RepositoryFactory from '@/services/repository-factory';
import datatableModule from '@/store/builder/datatableModule';
import table from '@/components/builders/configs/tables/providersTable';
import filters from '@/components/builders/configs/filters/providersFilters';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import providersModal from './submodules/editorDialog';
import importServices from './submodules/ImportServices';
import importProvidersDialog from './submodules/ImportProvidersDialog';
import BillingDialog from './submodules/BillingDialog';

const providersRepository = RepositoryFactory.get('externalPanels');
const networksRepository = RepositoryFactory.get('networks');
const serviceTypesRepository = RepositoryFactory.get('serviceTypes');
const state = {
  repository: providersRepository,
  networksRepository,
  serviceTypesRepository,
  error: null,
};

export default stateExtender({
  namespaced: true,
  state: () => state,
  modules: {
    table: datatableModule({
      fetch: providersRepository.table,
      headers: table.headers.filter((header) => header.isShown),
      allHeaders: table.headers,
      options: {
        sortBy: ['name'],
        sortDesc: [true],
        itemsPerPage: 30,
      },
      search: filters,
    }),
    providersModal,
    importServices,
    importProvidersDialog,
    BillingDialog,
  },
  getters,
  mutations,
  actions,
});
