import RepositoryFactory from '@/services/repository-factory';

const featuresRepository = RepositoryFactory.get('features');
const defaultState = () => ({
  id: '',
  icon: '',
  selectedLocale: 'en',
  attaches: [],
  translations: {
    en: {
      name: '',
    },
  },
});

export default {
  namespaced: true,
  state: {
    isEdit: false,
    isFeatureEditor: false,
    searchFeature: '',
    availableLabels: [],
    labels: [],
    selectedLabels: [],
    errors: {},
    ...defaultState(),
  },
  getters: {
    name: (state) => state.translations[state.selectedLocale]?.name,
    filteredAvailableLabels: (state) => state.availableLabels.map((availableLabel) => {
      const filteredElements = availableLabel.labels
        .filter((label) => {
          if (!state.searchFeature) {
            return true;
          }

          return label.name.toLowerCase()
            .includes(state.searchFeature.toLowerCase());
        });

      if (filteredElements.length > 0) {
        return availableLabel;
      }

      return null;
    })
      .filter((availableLabel) => availableLabel !== null),
    locale: (state) => state.editData.selectedLocale,
    errors: (state) => state.errors,
    labels: (state) => state.labels,
  },
  mutations: {
    SET_ATTACHED_SERVICES: (state, value) => { state.attaches = value; },
    SET_DEFAULT: (state) => { Object.assign(state, defaultState()); },
    SET_ICON: (state, labels) => { state.icon = labels; },
    SET_ID: (state, value) => { state.id = value; },
    SET_LABELS: (state, labels) => { state.labels = [...labels]; },
    SET_SELECTED_LABELS: (state, labels) => { state.selectedLabels = labels; },
    SET_AVAILABLE_LABELS: (state, labels) => { state.availableLabels = labels; },
    SET_ERRORS: (state, labels) => { state.errors = labels; },
    SET_OPEN_FEATURE_EDITOR: (state, value) => { state.isFeatureEditor = value; },
    SET_SEARCH_FEATURE: (state, value) => { state.searchFeature = value; },
    SET_TRANSLATION: (state, value) => { state.translations = value; },
    SET_FEATURE_LOCALE: (state, value) => { state.selectedLocale = value; },
    SET_IS_EDIT: (state, value) => { state.isEdit = value; },
  },
  actions: {
    setIcon({ commit }, value) {
      commit('SET_ICON', value);
    },
    setOpenFeatureEditor({
      commit, state,
    }, {
      isOpen, value,
    }) {
      commit('SET_OPEN_FEATURE_EDITOR', isOpen);

      if (value) {
        commit('SET_ICON', value.icon);
        commit('SET_TRANSLATION', {
          [state.selectedLocale]: {
            name: value.name,
          },
        });
        commit('SET_ID', value.id);
      }

      commit('SET_IS_EDIT', Boolean(value));
    },
    chooseFeature({
      commit, state,
    }, label) {
      let availableLabels = [...state.selectedLabels];

      const isLabelSelected = availableLabels.some((item) => item.id === label.id);

      if (isLabelSelected) {
        availableLabels = availableLabels.filter((item) => item.id !== label.id);
      } else {
        availableLabels.push(label);
      }

      commit('SET_SELECTED_LABELS', availableLabels);
    },
    setSearchFeature({ commit }, value) {
      commit('SET_SEARCH_FEATURE', value);
    },
    setName({ commit }, value) {
      commit('SET_TRANSLATION', value);
    },
    setFeatureLocale({ commit }, value) {
      commit('SET_FEATURE_LOCALE', value);
    },
    clearCurrentLabel({
      commit,
      state,
      dispatch,
    }, value) {
      const labels = state.labels.filter((label) => label.id !== value.id);

      commit('SET_LABELS', labels);
      dispatch('clearCurrentSelectedLabel', value);
    },
    clearCurrentSelectedLabel({
      commit,
      state,
    }, value) {
      const labels = state.selectedLabels.filter((label) => label.id !== value.id);

      commit('SET_SELECTED_LABELS', labels);
    },
    clearFeatureState({ commit }) {
      commit('SET_DEFAULT');
    },
    async create({
      dispatch, state,
    }) {
      const body = {
        icon: state.icon,
        translations: state.translations,
      };

      await featuresRepository.create(body);

      dispatch('fetchLabels');
    },
    async save({
      dispatch, state,
    }) {
      const body = {
        icon: state.icon,
        translations: state.translations,
        id: state.id,
      };

      await featuresRepository.update(body);

      dispatch('fetchLabels');
    },
    saveToLabels({
      commit, state,
    }) {
      commit('SET_LABELS', state.selectedLabels);
    },
    clearAllLabels({
      commit, dispatch,
    }) {
      commit('SET_LABELS', []);
      dispatch('clearAllSelectedLabels');
    },
    clearAllSelectedLabels({
      commit,
    }) {
      commit('SET_SELECTED_LABELS', []);
    },
    setSelectedLabels({
      commit,
    }, value) {
      commit('SET_SELECTED_LABELS', value);
    },
    async fetchLabels({ commit }) {
      try {
        const labels = await featuresRepository.fetch();

        commit('SET_AVAILABLE_LABELS', labels);
      } catch (e) {
        commit('SET_ERRORS', e);
      }
    },
    fetchLoadedLabels({ commit }, labels) {
      commit('SET_SELECTED_LABELS', labels);
      commit('SET_LABELS', labels);
    },
    async delete({
      dispatch, state,
    }) {
      await featuresRepository.delete(state.id);

      dispatch('fetchLabels');
    },
    async attached({
      commit, state,
    }) {
      const data = await featuresRepository.attached(state.id);

      commit('SET_ATTACHED_SERVICES', data);
    },
    updateLabelsOrder({ commit }, newValue) {
      commit('SET_LABELS', newValue);
    },
  },
};
