import tableSubscribers from '@/store/subscribers/tableSubscribers';

export default (store) => (mutation) => {
  const pieces = mutation.type.split('/');
  const mutationName = pieces.pop();
  const fullName = pieces.join('/');
  const moduleName = pieces[pieces.length - 1];

  tableSubscribers(store, mutation, {
    mutationName,
    fullName,
    moduleName,
  });
};
