import createWebhookTypeFromResource from './createWebhookType';

export default function createWebhookFromResource(data) {
  return {
    id: data.id,
    url: data.url,
    secret: data.secret,
    isActive: data.is_active,
    webhookTypes: (data.webhook_types || []).map(createWebhookTypeFromResource),
  };
}
