import { mutationNamer } from '@/store/utils/storeNamer';

export default (state) => {
  const newMutations = {};

  Object.keys(state.state()).forEach((prop) => {
    newMutations[mutationNamer(prop)] = (mutationState, payload) => {
      mutationState[prop] = payload;
    };
  });

  return {
    ...newMutations,
    ...state.mutations,
  };
};
