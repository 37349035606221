import createWebsocket from '@/services/create-websocket';
import statusCounters from '@/store/modules/websockets/types/counters-names';

const EventsPrefixed = (eventName) => `Broadcast\\${eventName}`;

export default class StatusesCountersSocket {
  countersHandlers = new Map();

  socket = null;

  constructor(options) {
    this.options = options;
  }

  createSocket() {
    this.socket = createWebsocket();
  }

  applicationCountsUpdated(path) {
    this.socket
      .private(path)
      .listen(EventsPrefixed('OrdersInProcessingCountChanged'), (event) => {
        this.triggerSubscribers(statusCounters.orderInProcessing, event.count);
      })
      .listen(EventsPrefixed('OrdersInModerationCountChanged'), (event) => {
        this.triggerSubscribers(statusCounters.orderInModeration, event.count);
      })
      .listen(EventsPrefixed('OrdersWithNoActiveProvidersCountChanged'), (event) => {
        this.triggerSubscribers(statusCounters.orderWithNoActiveProviders, event.count);
      })
      .listen(EventsPrefixed('FreeTrialsPendingCountChanged'), (event) => {
        this.triggerSubscribers(statusCounters.freeTrials, event.count);
      });
  }

  subscribeToCounter(counter, cb) {
    const subscribers = this.countersHandlers.get(counter) || [];

    this.countersHandlers.set(counter, [...subscribers, cb]);
  }

  triggerSubscribers(counterName, value) {
    (this.countersHandlers.get(counterName || []).forEach((subscriber) => {
      subscriber(value);
    }));
  }

  unsubscribeFromCounters() {
    this.countersHandlers.clear();
  }

  init() {
    this.createSocket();
  }

  disconnect() {
    this.socket.disconnect();
  }
}
