import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  fetch: '/api/v1/admin/permissions',
});

export default {
  fetch() {
    const endpoint = getURL('fetch');

    return http.get(endpoint)
      .then((response) => response.data.data.map(
        (permission) => ({
          id: permission.id,
          name: permission.name,
          group: permission.group,
        }),
      ));
  },
  getURL,
};
