const allLocales = {
  en: {
    label: 'English',
  },
  ru: {
    label: 'Russian',
  },
  es: {
    label: 'Spanish',
  },
  fr: {
    label: 'French',
  },
  de: {
    label: 'German',
  },
  hi_IN: {
    label: 'Hindi',
  },
  ko: {
    label: 'Korean',
  },
  pt_BR: {
    label: 'Portuguese',
  },
  tr: {
    label: 'Turkish',
  },
  zh_CN: {
    label: 'Chinese',
  },
  ja: {
    label: 'Japanese',
  },
  vi: {
    label: 'Vietnamese',
  },
};
const aplications = {
  user: {
    active: {
      label: 'Active',
      color: 'success',
      textColor: 'white',
    },
    frozen: {
      label: 'Frozen',
      color: 'warning',
      textColor: 'white',
    },
    deactivated: {
      label: 'Deactivated',
      color: 'error',
      textColor: 'white',
    },
    default: {
      color: 'default',
    },
  },

  locales: {
    en: { ...allLocales.en },
    ru: { ...allLocales.ru },
    es: { ...allLocales.es },
    fr: { ...allLocales.fr },
    de: { ...allLocales.de },
    hi_IN: { ...allLocales.hi_IN },
    ko: { ...allLocales.ko },
    pt_BR: { ...allLocales.pt_BR },
    tr: { ...allLocales.tr },
    zh_CN: { ...allLocales.zh_CN },
    ja: { ...allLocales.ja },
    vi: { ...allLocales.vi },
  },

  autoTranslation: {
    en: { ...allLocales.en },
    ru: { ...allLocales.ru },
    es: { ...allLocales.es },
    fr: { ...allLocales.fr },
    de: { ...allLocales.de },
    hi_IN: { ...allLocales.hi_IN },
    ko: { ...allLocales.ko },
    pt_BR: { ...allLocales.pt_BR },
    tr: { ...allLocales.tr },
    zh_CN: { ...allLocales.zh_CN },
    ja: { ...allLocales.ja },
    vi: { ...allLocales.vi },
  },
};

function getBuildedAplication({
  data,
  aplicationName,
}) {
  return (data || []).reduce((acc, name) => {
    acc[name] = aplications[aplicationName][name]
      ? {
        ...aplications[aplicationName][name],
        value: name,
      }
      : {
        ...aplications[aplicationName].default,
        label: name,
        value: name,
      };

    return acc;
  }, {});
}

export default function createAplications(data) {
  return {
    user: getBuildedAplication({
      data: data.user.statuses,
      aplicationName: 'user',
    }),
    locales: getBuildedAplication({
      data: data.locales,
      aplicationName: 'locales',
    }),
    autoTranslation: getBuildedAplication({
      data: data.auto_translation.available_locales,
      aplicationName: 'autoTranslation',
    }),
  };
}
