export default {
  isShown: (state) => state.isShown,
  couponId: (state) => state.couponId,
  couponLocale: (state) => state.locale,
  displayable: (state) => state.formData.displayable,
  bonusAmountCalculationType: (state) => state.formData.bonusAmountCalculationType,
  useWithOnlyUnpaidOrders: (state) => state.formData.useWithOnlyUnpaidOrders,
  workWithServiceDiscount: (state) => state.formData.workWithServiceDiscount,
  workWithUserCustomPrice: (state) => state.formData.workWithUserCustomPrice,
  workWithBalance: (state) => state.formData.workWithBalance,
  validFrom: (state) => state.formData.validFrom,
  validTo: (state) => state.formData.validTo,
  code: (state) => state.formData.code,
  usageLimit: (state) => state.formData.usageLimit,
  bonusType: (state) => state.formData.bonusType,
  bonusPercentage: (state) => state.formData.bonusPercentage,
  bonusAmount: (state) => state.formData.bonusAmount,
  paymentType: (state) => state.formData.paymentType,
  minAmount: (state) => state.formData.minAmount,
  affiliate: (state) => state.formData.affiliate,
  translations: (state) => state.formData.translations,
  name: (state) => state.formData.translations[state.locale].name,
  description: (state) => state.formData.translations[state.locale].description,
  errors: (state) => state.errors,
};
