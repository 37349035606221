export default function createProfileFromResource(data) {
  return {
    id: data.id,
    email: data.email,
    name: data.name,
    balance: data.balance,
    accountType: data.account_type,
    companyName: data.company_name,
    companyAddress: data.company_address,
    companySite: data.company_site,
    vat: data.vat,
    allPermissions: data.all_permissions,
    permissions: data.permissions,
    roles: data.roles,
    createdAt: data.created_at,
    referralProgramPercent: data.referral_program_percent,
    affiliateCode: data.affiliate_code,
    intercomHash: data.intercom_hash,
    googleAnalyticsClientId: data.google_analytics_client_id,
    yandexMetrikaClientId: data.yandex_metrika_client_id,
    notificationsAboutOrderActions: data.notifications_about_order_actions,
    notificationsAboutPaymentActions: data.notifications_about_payment_actions,
    unsubscribeSignature: data.unsubscribe_signature,
    youtubeAccountConnected: data.youtube_account_connected,
    frozenAt: data.frozen_at,
  };
}
