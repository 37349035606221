import bulkCancelDefaultState from '@/store/defaultStates/orders/cancelModal/bulk';
import singleCancelDefaultState from '@/store/defaultStates/orders/cancelModal/single';
import cancelModalDefaultState from '@/store/defaultStates/orders/cancelModal/modal';

export default {
  setBulkCancelState({ commit }, val) {
    commit('SET_BULK_CANCEL_STATE', val);
  },
  setSingleCancelState({ commit }, val) {
    commit('SET_SINGLE_CANCEL_STATE', val);
  },
  setCancelModalState({ commit }, val) {
    commit('SET_BULK_CANCEL_MODAL_STATE', val);
  },

  async fetchAvailableCancelReasonsIfNeeded({
    getters, rootGetters, commit,
  }) {
    if (getters.availableCancelReasons.length > 0) {
      return;
    }

    commit('SET_AVAILABLE_CANCEL_REASONS_LOADING', true);

    await rootGetters['orders/getRepository'].fetchAvailableCancelReasons()
      .then((reasons) => {
        commit('SET_AVAILABLE_CANCEL_REASONS', reasons);
      })
      .finally(() => {
        commit('SET_AVAILABLE_CANCEL_REASONS_LOADING', false);
      });
  },
  fetchDefaultRefundAmount({
    getters,
    rootGetters,
    commit,
    dispatch,
  }) {
    commit('SET_DEFAULT_AMOUNT_LOADING', true);

    return rootGetters['orders/getRepository'].getDefaultRefundAmount({ orderId: getters.singleCancelId })
      .then((response) => {
        dispatch('setCancelAmount', Number(response.amount.replaceAll(' ', '')));
      })
      .finally(() => {
        commit('SET_DEFAULT_AMOUNT_LOADING', false);
      });
  },

  setCancellingErrors({ commit }, val) {
    commit('SET_CANCELLING_ERRORS', val);
  },

  setIsRefundNeeded({ commit }, val) {
    commit('SET_IS_REFUND_NEEDED', val);
  },

  setCancelReason({ commit }, val) {
    commit('SET_CANCEL_REASON', val);
  },
  clearCancelReasonError({ commit }) {
    commit('SET_CANCEL_REASON_ERROR', null);
  },

  setCancelDescription({ commit }, val) {
    commit('SET_CANCEL_DESCRIPTION', val);
  },
  clearCancelDescriptionError({ commit }) {
    commit('SET_CANCEL_DESCRIPTION_ERROR', null);
  },

  setCancelAmount({ commit }, val) {
    commit('SET_CANCEL_AMOUNT', val);
  },
  clearCancelAmountError({ commit }) {
    commit('SET_CANCEL_AMOUNT_ERROR', null);
  },

  setSingleCancelId({ commit }, orderId) {
    commit('SET_SINGLE_CANCEL_ID', orderId);
  },
  setBulkCancelIds({ commit }, ordersIds) {
    commit('SET_BULK_CANCEL_IDS', ordersIds);
  },
  setRefreshTable({ commit }, val) {
    commit('SET_REFRESH_TABLE', val);
  },
  setSendEmailNotification({ commit }, val) {
    commit('SET_SEND_EMAIL_NOTIFICATION', val);
  },

  async showSingleCancelModal({ dispatch }, {
    orderId, refreshTable,
  }) {
    dispatch('fetchAvailableCancelReasonsIfNeeded');
    await dispatch('setRefreshTable', Boolean(refreshTable));
    await dispatch('setSingleCancelId', orderId);
    await dispatch('fetchDefaultRefundAmount');
  },
  async showMultipleCancelModal({ dispatch }, ordersIds) {
    await dispatch('setRefreshTable', true);
    await dispatch('fetchAvailableCancelReasonsIfNeeded');
    await dispatch('setBulkCancelIds', ordersIds);
  },
  async hideCancelModal({ dispatch }) {
    await dispatch('setCancelModalState', cancelModalDefaultState());
    await dispatch('setSingleCancelState', singleCancelDefaultState());
    await dispatch('setBulkCancelState', bulkCancelDefaultState());
  },
  sendSingleCancelRequest({
    getters, rootGetters,
  }) {
    return rootGetters['orders/getRepository'].singleCancel({
      orderId: getters.singleCancelId,
      cancelReason: getters.cancelReason,
      cancelDescription: getters.cancelDescription,
      returnMoney: getters.isRefundNeeded,
      amount: getters.cancelAmount,
      sendEmailNotification: getters.sendEmailNotification,
    });
  },
  async sendMultipleCancelRequest({
    getters, rootGetters,
  }) {
    await rootGetters['orders/getRepository'].multipleCancel({
      ordersIds: getters.bulkCancelIds,
      cancelReason: getters.cancelReason,
      cancelDescription: getters.cancelDescription,
      returnMoney: getters.isRefundNeeded,
      sendEmailNotification: getters.sendEmailNotification,
    });
  },
  async sendCancelRequest({
    getters, commit, dispatch,
  }) {
    const savingActionName = getters.isSingleCancelling
      ? 'sendSingleCancelRequest'
      : 'sendMultipleCancelRequest';

    commit('SET_IS_SAVING_PROCESS', true);

    await dispatch(savingActionName)
      .then(() => {
        dispatch('hideCancelModal');
      })
      .catch((error) => {
        if (error.messages) {
          dispatch('setCancellingErrors', error.messages);
        }

        throw error;
      })
      .finally(() => {
        commit('SET_IS_SAVING_PROCESS', false);

        if (getters.refreshTable) {
          dispatch('setRefreshTable', false);
          dispatch('orders/table/clearSelected', null, { root: true });
          dispatch('orders/table/fetch', null, { root: true });
        }
      });
  },
};
