import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import {
  createTargets,
  createTargetList,
} from '@/classes/targets/createTargets';
import http from '../../http';
import urlFormatter from '../../url-formatter';

const getURL = urlFormatter({
  list: '/api/v1/admin/targets/list',
  index: '/api/v1/admin/targets',
  show: '/api/v1/admin/targets/{id}',
  create: '/api/v1/admin/targets',
  update: '/api/v1/admin/targets/{id}',
  listTypes: '/api/v1/admin/target-types',
  dictionaries: '/api/v1/admin/dictionaries',
});

export default {
  async table(queryParams) {
    const endpoint = getURL('index');
    const response = await http.get(`${endpoint}/?${datatableFormaterStringified(queryParams)}`);

    return {
      items: response.data.data.map(createTargets),
      columns: response.data.input.columns,
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  async show(id) {
    const endpoint = getURL('show', { id });
    const { data } = await http.get(endpoint);

    return createTargets(data.data);
  },
  async listTargets() {
    const endpoint = getURL('list');
    const response = await http.get(endpoint);

    return response.data.data.map((item) => createTargetList(item));
  },
  async listTargetTypes() {
    const endpoint = getURL('listTypes');
    const { data } = await http.get(endpoint);

    return data.data;
  },
  async getDictionary() {
    const endpoint = getURL('dictionaries');
    const { data } = await http.get(endpoint);

    return data.data;
  },
  async update(obj) {
    const endpoint = getURL('update', { id: obj.id });

    await http.put(endpoint, {
      translations: obj.translations,
      is_visible_default_values: obj.isVisibleDefaultValues,
      target_type_id: obj.targetTypeId,
      selectable_values: obj.selectableValues,
    });
  },
  async create(obj) {
    const endpoint = getURL('create');

    await http.post(endpoint, {
      translations: obj.translations,
      target_type_id: obj.targetTypeId,
      is_visible_default_values: obj.isVisibleDefaultValues,
      selectable_values: obj.selectableValues,
    });
  },
  getURL,
};
