<template>
  <v-autocomplete
    v-bind="$attrs"
    dense
    outlined
    :clear-icon="clearIcon"
    :append-icon="actualIcon"
    attach
    :menu-props="{
      contentClass: 'base-autocomplete__content',
    }"
    :class="[
      'base-autocomplete',
      {
        'persistent-border': isPersistedBorders,
        medium: size === 'm',
        small: size === 's',
      },
    ]"
    v-on="$listeners"
  >
    <template
      v-for="(index, name) in $scopedSlots"
      #[name]="data"
    >
      <slot
        :name="name"
        v-bind="data"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import useVModel from '@/mixins/useVModel';

export default {
  mixins: [useVModel('Value', 'value', 'update:value')],
  props: {
    size: {
      type: String,
      default: 'm',
    },
    appendIcon: {
      type: String,
      default: '$icon-caret-down',
    },
    clearIcon: {
      type: String,
      default: '$icon-clear',
    },
    persistentBorder: {
      type: Boolean,
    },
  },
  computed: {
    actualIcon() {
      return this.size === 'm' ? this.appendIcon : '$icon-caret-down-slim';
    },
    contentClass() {
      return `next base-select-content${this.size === 'm' ? '' : '--small'}`;
    },
    isPersistedBorders() {
      return this.persistentBorder && !!this.$attrs.value.length;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep{
  .v-text-field__details{
    display: none;
  }
  .v-input__slot {
    min-height: auto!important;
    padding: 0 16px!important;
    margin: 0!important;
    @extend .p-16-r;
  }
}
.medium::v-deep {
  .v-input__slot {
    padding: 0 16px!important;
    @extend .p-16-r;
  }
  .v-list-item__title {
    @extend .p-16-r;
  }
}
.small::v-deep {
  .v-input__slot {
    max-height: 24px;
    padding: 0 4px 0 8px!important;
    @extend .p-16-r;
    font-size: 12px!important;
  }
  .v-list-item__title {
    @extend .p-16-r;
    font-size: 8px!important;
  }
  .v-input__append-inner{
    margin: 0!important;
  }
}
</style>
