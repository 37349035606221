import {
  createResetButton,
} from '../builder-definition/filters'; import { createPlainText } from '../builder-definition/filters/page';

const urlParams = new URLSearchParams(window.location.search);

export default {
  commonSearch: urlParams.has('search') ? urlParams.get('search') : null,
  id: '',
  user: '',
  paymentMethod: '',
  amount: '',
  shortName: '',
  paidAt: '',
  createdAt: '',
  status: '',
};

export const config = {
  defaultSearchPropName: 'id',
  exceptionFilters: ['status'],
  rows: [
    [
      createPlainText({
        filterProp: 'commonSearch',
        placeholder: 'Search',
      }),
      createResetButton(),
    ],
  ],
};
