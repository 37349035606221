import { defaultDialogSetupBuilder } from '@/components/services/dialog/dialogSetup';

export default (invokeArgs = {}) => {
  const dialogSubject = 'Drip-feed';

  defaultDialogSetupBuilder(
    {
      isModalShown: true,
      confirmTitle: `Cancel ${dialogSubject}`,
      confirmText: `Are you sure you want to cancel ${dialogSubject}? This action cannot be undone.`,
      confirmBtnColor: 'invokedDialogRed',
      confirmBtnText: `Cancel ${dialogSubject}`,
      ...invokeArgs,
    },
  );
};
