export const defaultState = () => ({
  discountId: null,
  errors: {},
  formData: {
    name: '',
    validFrom: '',
    validTo: '',
    createdAt: '',
    services: [],
  },
});

export default () => ({
  isShown: false,
  discountLoading: false,
  time: '',
  ...defaultState(),
});
