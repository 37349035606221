import stateExtender from '@/store/utils/stateExtender';
import RepositoryFactory from '@/services/repository-factory';
import datatableModule from '@/store/builder/datatableModule';
import table from '@/components/builders/configs/tables/ordersTable';
import filters from '@/components/builders/configs/filters/ordersFilters';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const ordersRepository = RepositoryFactory.get('orders');
const usersTableState = {
  queries: {},
};
const usersTableStoreContext = {
  state: usersTableState,
  actions,
  getters,
  mutations,
};
const ordersDatatableModule = datatableModule({
  fetch: ordersRepository.table,
  headers: table.headers.filter((header) => header.isShown),
  allHeaders: table.headers,
  options: {
    sortBy: ['id'],
    sortDesc: [true],
    itemsPerPage: 30,
  },
  search: filters,
}, usersTableStoreContext);

export default stateExtender({
  ...ordersDatatableModule,
});
