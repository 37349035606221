import fieldMapper from '@/services/helpers/fieldMapper';

const socialsFieldMap = {
  twitterLink: 'twitter_link',
  vkGroupLink: 'vk_group_link',
  facebookLink: 'facebook_link',
  youtubeChannelLink: 'youtube_channel_link',
  telegramLink: 'telegram_link',
  linkedinLink: 'linkedin_link',
};

export default class PanelSocials {
  constructor(socials = {}) {
    Object.assign(this, fieldMapper.receive(socials, socialsFieldMap));
  }

  getDataForRequest() {
    return fieldMapper.send(this, socialsFieldMap);
  }
}
