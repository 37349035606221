import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  create: '/api/v1/admin/services/labels',
  fetch: '/api/v1/admin/services/available-labels',
  update: '/api/v1/admin/services/labels/{id}',
  delete: '/api/v1/admin/services/labels/{id}',
  attached: '/api/v1/admin/services/labels/used-services/{id}',

});

export default {
  async create(body) {
    const endpoint = getURL('create');

    await http.post(endpoint, body);
  },
  async fetch() {
    const endpoint = getURL('fetch');
    const { data } = await http.get(endpoint);

    return data.data;
  },
  async update(body) {
    const endpoint = getURL('update', { id: body.id });
    const { data } = await http.put(endpoint, body);

    return data.data;
  },
  async delete(id) {
    const endpoint = getURL('delete', { id });

    await http.delete(endpoint);
  },
  async attached(id) {
    const endpoint = getURL('attached', { id });
    const { data } = await http.get(endpoint);

    return data.data;
  },
};
