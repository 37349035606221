import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import http from '@/services/http';
import urlFormatter from '@/services/url-formatter';
import createAuditLogItemFromResource from '@/classes/createAuditLogItem';

const getURL = urlFormatter({
  table: '/api/v1/admin/audits',
});

export default {
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('table');
    const response = await http.get(`${endpoint}?${requestBody}`);

    return {
      items: response.data.data.map(createAuditLogItemFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
};
