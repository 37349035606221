export default function createDocumentsListFromResource(data) {
  if (!data) {
    return null;
  }

  return [
    {
      label: 'Card files',
      files: data.documents['card-photos'],
    },
    {
      label: 'ID files',
      files: data.documents['id-photos'],
    },
    {
      label: 'Selfie files',
      files: data.documents['selfie-photos'],
    },
    {
      label: 'Additional files',
      files: data.documents['additional-photos'],
    },
  ];
}
