import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createComboOrder from '@/classes/createComboOrder';
// import createAvailableServices from '@/classes/createAvailableServices';
// import createPackagesTotalCost from '@/classes/createPackagesTotalCost';
import handleErrors from '@/services/handle-errors';
import http from '../../http';
import urlFormatter from '../../url-formatter';
// import settings from './settings';
// import {
//   createPackagesStoreErrors,
//   createPackagesTotalCostErrors,
// } from './create-packages-errors';
// import createPackageStoreRequest from './create-package-store-request';
// import createBulkServicesEditRequest from './create-bulk-services-edit-request';
// import createBulkServicesEditErrors from './create-bulk-services-edit-errors';

const getURL = urlFormatter({
  list: '/api/v1/admin/combo-orders',
  complete: '/api/v1/admin/combo-orders/{comboOrderId}/complete',
});
const comboOrdersRepository = {
  async table(queryParams) {
    const endpoint = getURL('list');
    const { data } = await http.get(
      `${endpoint}?${datatableFormaterStringified(queryParams)}`,
    );

    return {
      items: data.data.map(createComboOrder),
      totalItemsCount: data.recordsFiltered,
    };
  },
  async complete({ comboOrderId }) {
    const endpoint = getURL('complete', { comboOrderId });

    try {
      return await http.post(endpoint);
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default comboOrdersRepository;
