import settings from '@/settings';
import {
  // createDateFilter,
  createTextFilter,
} from '../builder-definition/filters/table';
import {
  createCellActions,
  createCellMoney,
} from '../builder-definition/tables';
import { createCellPercent } from '../builder-definition/tables/plain-builders';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      name: 'grades.id',
      width: '90px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
    },
    {
      value: 'name',
      text: 'Grade’s name',
      name: 'grade_translations.name',
      hideable: false,
      isShown: true,
      sortable: false,
      customFilter: createTextFilter({ placeholder: 'Search Name' }),
    },
    {
      value: 'spendingGoal',
      text: 'Spending goal',
      name: 'spending_goal',
      width: '125px',
      isShown: true,
      ...createCellMoney(),
    },
    {
      value: 'discountPercent',
      name: 'discount_percent',
      text: 'Discount',
      width: '125px',
      isShown: true,
      ...createCellPercent(),
    },
    {
      value: 'usersCount',
      name: 'usersCount',
      text: 'Users count',
      width: '125px',
      isShown: true,
      sortable: false,
    },
    {
      value: 'actions',
      text: 'Actions',
      width: '60px',
      searchable: false,
      sortable: false,
      isShown: true,
      permission: settings.permissions.manageGrades,
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Edit',
              value: 'editable',
              prop: 'editable',
              callBack: (item, store) => {
                store.dispatch('grades/editorDialog/showEditorDialog', item.id);
              },
            },
            {
              text: 'Archive',
              value: 'archival',
              prop: 'archival',
              callBack: (item, store) => {
                store.dispatch('grades/archiveGrade', item.id);
              },
            },
            {
              text: 'Restore',
              value: 'restore',
              prop: 'restore',
              callBack: (item, store) => {
                store.dispatch('grades/restoreGrade', item.id);
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;

            return tableElement.actions[prop]
              ? tableElement.actions[prop]
              : false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
