export default function createPaymentMethodPublicFieldsFromResource(data) {
  if (!data) {
    return null;
  }

  return {
    isUserComment: data.is_user_comment,
    qrCode: data.qr_code,
  };
}
