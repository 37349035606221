import fieldMapper from '@/services/helpers/fieldMapper';

const bannersFieldMap = {
  dashboardHeader: 'dashboard_header',
  dashboardTile: 'dashboard_tile',
  ordersHeader: 'orders_header',
  orderHeader: 'order_header',
  dripFeedHeader: 'drip_feed_header',
  subscriptionHeader: 'subscription_header',
  servicesHeader: 'services_header',
  servicesFooter: 'services_footer',
  profileHeader: 'profile_header',
  tasksHeader: 'tasks_header',
  createOrderHeader: 'create_order_header',
  sidebar: 'sidebar',
  servicesMain: 'services_main',
  servicesLong: 'services_long',
  servicesLittleLeft: 'services_little_left',
  servicesLittleRight: 'services_little_right',
};

export default class PanelBanners {
  constructor(banners = {}) {
    Object.assign(this, fieldMapper.receive(banners, bannersFieldMap));
  }

  getDataForRequest() {
    return fieldMapper.send(this, bannersFieldMap);
  }
}
