import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import globalIcons from '@/components/icons/globalIcons';
import themes from './colors/themes';

const icons = globalIcons();

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: themes.light,
      dark: themes.dark,
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: 'mdi',
    values: {
      checkboxOn: {
        component: 'icon-checkbox-on',
      },
      checkboxOff: {
        component: 'icon-checkbox',
      },
      ...icons,
    },
  },
});
