import Vue from 'vue';

export const trimFileNames = (str) => str.replace(/^.*[\\/]/, '').replace(/\.\w+$/, '');

export const kebab = (str) => str
  .replace(/\b([A-Z][a-z]*)+\b/g, (n) => n.replace(/([A-Z])/g, '-$1')
    .replace(/^-/, '')
    .toLowerCase());

export default function registerComponents(requireComponent) {
  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);

    Vue.component(trimFileNames(fileName), componentConfig.default || componentConfig);
  });
}

export const registerSingleComponent = (fileName, requireComponent) => {
  const componentConfig = requireComponent(fileName);

  Vue.component(trimFileNames(fileName), componentConfig.default || componentConfig);
};
