import bulkCancelDefaultState from '@/store/defaultStates/orders/cancelModal/bulk';
import singleCancelDefaultState from '@/store/defaultStates/orders/cancelModal/single';
import cancelModalDefaultState from '@/store/defaultStates/orders/cancelModal/modal';

export default () => ({
  bulk: bulkCancelDefaultState(),
  single: singleCancelDefaultState(),
  common: {
    availableReasons: [],
    availableReasonsLoading: false,
    refreshTable: false,
    modal: cancelModalDefaultState(),
  },
});
