<template>
  <!-- eslint-disable max-len -->
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99992 0.333496C3.31778 0.333496 0.333252 3.31802 0.333252 7.00016C0.333252 10.6816 3.31774 13.6668 6.99992 13.6668C10.6814 13.6668 13.6666 10.6816 13.6666 7.00016C13.6666 3.31798 10.6814 0.333496 6.99992 0.333496ZM6.99666 3.96956C7.27281 3.96956 7.49666 4.19342 7.49666 4.46956V7.41556C7.49666 7.69171 7.27281 7.91556 6.99666 7.91556C6.72052 7.91556 6.49666 7.69171 6.49666 7.41556V4.46956C6.49666 4.19342 6.72052 3.96956 6.99666 3.96956ZM6.99666 8.86418C6.62847 8.86418 6.33 9.16265 6.33 9.53084C6.33 9.89903 6.62847 10.1975 6.99666 10.1975H7.00333C7.37152 10.1975 7.67 9.89903 7.67 9.53084C7.67 9.16265 7.37152 8.86418 7.00333 8.86418H6.99666Z" fill="#EA5230" />
  </svg>
</template>

<script>
export default {
  name: 'IconDanger',
};
</script>
