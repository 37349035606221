import moment from 'moment';

export default (minutes) => {
  if (!minutes) {
    return '';
  }

  const duration = moment.duration(minutes, 'minutes');

  if (duration.days()) {
    return `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m`;
  }

  if (duration.hours()) {
    return `${duration.hours()}h ${duration.minutes()}m`;
  }

  return `${duration.minutes()}m`;
};
