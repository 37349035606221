export default function createDiscountStoreErrors({
  errors, message,
}) {
  return {
    name: errors.name,
    validTo: errors.valid_to,
    validFrom: errors.valid_from,
    ...Object.entries(errors)
      .reduce((acc, [key, value]) => {
        if (!key.match(/^services/)) {
          return acc;
        }

        return {
          ...acc,
          [key]: value,
        };
      }, {}),
    message,
  };
}
