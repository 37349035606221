import {
  createNetworksFilter,
} from '../builder-definition/filters/table';
import {
  createCellActions, createCellNameAndIcon,
} from '../builder-definition/tables';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      name: 'id',
      width: '200px',
      hideable: false,
      isShown: true,
      sortable: false,
      customFilter: createNetworksFilter(),
      ...createCellNameAndIcon(),
    },
    {
      value: 'transactionType',
      name: 'transaction_type',
      text: 'Service type',
      width: '300px',
      hideable: false,
      isShown: true,
      sortable: false,
    },
    {
      value: 'actions',
      text: 'Actions',
      searchable: false,
      sortable: false,
      hideable: false,
      isShown: true,
      align: 'end',
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Edit service positions',
              callBack: (item, store) => {
                store.dispatch('networks/networksModal/openEditServiceTypesPositionModal', {
                  id: item.id,
                });
              },
            },
            {
              text: 'Edit networks positions',
              callBack: (item, store) => {
                store.dispatch('networks/networksModal/openEditNetworksPositionModal', {
                  id: item.id,
                });
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;

            return tableElement.actions[prop]
              ? tableElement.actions[prop]
              : false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
