import bulkRecreateDefaultState from '@/store/defaultStates/orders/recreateModal/bulk';
import singleRecreateDefaultState from '@/store/defaultStates/orders/recreateModal/single';
import commonRecreateDefaultState from '@/store/defaultStates/orders/recreateModal/common';

export default {
  setBulkRecreateState({ commit }, val) {
    commit('SET_BULK_RECREATE_STATE', val);
  },
  setSingleRecreateState({ commit }, val) {
    commit('SET_SINGLE_RECREATE_STATE', val);
  },
  setRecreateModalState({ commit }, val) {
    commit('SET_RECREATE_MODAL_STATE', val);
  },

  setRecreateRefreshTable({ commit }, val) {
    commit('SET_RECREATE_REFRESH_TABLE', val);
  },
  setSingleRecreateId({ commit }, orderId) {
    commit('SET_SINGLE_RECREATE_ID', orderId);
  },
  setBulkRecreateIds({ commit }, val) {
    commit('SET_BULK_RECREATE_IDS', val);
  },
  setRecreateQuantity({ commit }, val) {
    commit('SET_RECREATE_QUANTITY', val);
  },
  setRecreateErrors({ commit }, val) {
    commit('SET_RECREATE_ERRORS', val);
  },

  async showSingleRecreateModal({ dispatch }, {
    orderId, refreshTable,
  }) {
    await dispatch('fetchAvailableProvidersByPage');
    await dispatch('setRecreateRefreshTable', Boolean(refreshTable));
    await dispatch('setSingleRecreateId', orderId);
  },
  async showMultipleRecreateModal({ dispatch }, ordersIds) {
    await dispatch('fetchAvailableProvidersByPage');
    await dispatch('setRecreateRefreshTable', true);
    await dispatch('setBulkRecreateIds', ordersIds);
  },

  async hideRecreateModal({ dispatch }) {
    await dispatch('setBulkRecreateState', bulkRecreateDefaultState());
    await dispatch('setSingleRecreateState', singleRecreateDefaultState());
    await dispatch('setRecreateModalState', commonRecreateDefaultState());
  },
  setSelectedProviderId({ commit }, id) {
    commit('SET_SELECTED_PROVIDER_ID', id);
  },
  setSearchString({ commit }, val) {
    commit('SET_SEARCH_STRING', val);
  },
  setIsRecreatingProcess({ commit }, val) {
    commit('SET_IS_RECREATING_PROCESS', val);
  },
  clearRecreateQuantityErrors({ commit }) {
    commit('SET_RECREATE_QUANTITY_ERROR', null);
  },
  clearRecreateProviderIdErrors({ commit }) {
    commit('SET_RECREATE_PROVIDER_ID_ERROR', null);
  },
  async fetchAvailableProviders({
    state, commit,
  }, name = '') {
    commit('SET_AVAILABLE_PROVIDERS_LOADING', true);
    await state.providersRepository.availableForOrderRecreate(name)
      .then((data) => {
        commit('SET_AVAILABLE_FOUND_PROVIDERS', data);
      })
      .finally(() => {
        commit('SET_AVAILABLE_PROVIDERS_LOADING', false);
      });
  },
  async fetchAvailableProvidersByPage({
    state, commit, getters,
  }, page = 1) {
    commit('SET_AVAILABLE_PROVIDERS_LOADING', true);
    await state.providersRepository.availableForOrderRecreateByPage(page)
      .then((data) => {
        commit('SET_AVAILABLE_PROVIDERS', [...(getters.availableProviders ?? []), ...data.items]);
        commit('SET_CURRENT_PAGE', data.currentPage);
        commit('SET_LAST_PAGE', data.lastPage);
      })
      .finally(() => {
        commit('SET_AVAILABLE_PROVIDERS_LOADING', false);
      });
  },
  fetchProvidersNextPage({
    getters, dispatch,
  }) {
    if (
      (getters.availableProvidersLastPage > getters.availableProvidersCurrentPage)
      && !getters.isAvailableProvidersLoading
    ) {
      dispatch('fetchAvailableProvidersByPage', getters.availableProvidersCurrentPage + 1);
    }
  },
  async sendSingleRecreateRequest({
    getters, rootGetters,
  }) {
    await rootGetters['orders/getRepository'].changeProvider({
      orderIds: [getters.singleRecreateId],
      providerId: getters.selectedProviderId,
      quantity: getters.singleRecreateQuantity,
    });
  },
  async sendMultipleRecreateRequest({
    getters, rootGetters, dispatch,
  }) {
    await rootGetters['orders/getRepository'].changeProvider({
      orderIds: getters.bulkRecreateIds,
      providerId: getters.selectedProviderId,
    })
      .then(() => {
        dispatch('orders/table/fetch', null, { root: true });
      });
  },
  async recreateOrders({
    getters, dispatch,
  }) {
    const savingActionName = getters.isSingleRecreating
      ? 'sendSingleRecreateRequest'
      : 'sendMultipleRecreateRequest';

    await dispatch('setIsRecreatingProcess', true);

    await dispatch(savingActionName)
      .then(() => {
        dispatch('hideRecreateModal');
      })
      .catch((error) => {
        if (error.messages) {
          dispatch('setRecreateErrors', error.messages);
        }

        throw error;
      })
      .finally(() => {
        dispatch('setIsRecreatingProcess', false);

        if (getters.recreateRefreshTable) {
          dispatch('setRecreateRefreshTable', false);
          dispatch('orders/table/clearSelected', null, { root: true });
          dispatch('orders/table/fetch', null, { root: true });
        }
      });
  },
};
