const ITEM_TRANSLATION = {
  name: '',
};

export default {
  setErrors({ commit }, errors) {
    commit('SET_ERRORS', errors);
  },
  clearErrors({ commit }, key) {
    commit('CLEAR_ERRORS', key);
  },
  clearAllErrors({ commit }) {
    commit('CLEAR_ALL_ERRORS');
  },
  setShownEdirorDialog({ commit }, value) {
    commit('SET_SHOWN_EDITOR_DIALOG', value);
  },
  setCreateEditorDialog({ commit }, value) {
    commit('SET_CREATE_EDITOR_DIALOG', value);
  },
  setLoadedGrade({ commit }, loadedGrade) {
    commit('SET_LOADED_GRADE', loadedGrade);
  },
  setTranslations({ commit }, {
    lang,
    key,
    value,
  }) {
    commit('SET_TRANSLATIONS', {
      lang,
      key,
      value,
    });
  },
  setIcon({ commit }, value) {
    commit('SET_ICON', value);
  },
  setSpendingGoal({ commit }, value) {
    commit('SET_SPENDING_GOAL', value);
  },
  setDiscountPercent({ commit }, value) {
    commit('SET_DISCOUNT_PERCENT', value);
  },
  setThumbnailPeriod({ commit }, value) {
    commit('SET_THUMBNAIL_PERIOD', value);
  },
  setConsultationChatPeriod({ commit }, value) {
    commit('SET_CONSULTATION_CHAT_PERIOD', value);
  },
  setAvailableClosedSales({ commit }, value) {
    commit('SET_AVAILABLE_CLOSED_SALES', value);
  },
  setAvailablePersonalManager({ commit }, value) {
    commit('SET_AVAILABLE_PERSONAL_MANAGER', value);
  },
  addNewTranslation({
    commit,
    getters,
  }, translationKey) {
    if (translationKey in getters.gradeTranslations) return;

    commit('ADD_NEW_TRANSLATION', {
      translationKey,
      data: { ...ITEM_TRANSLATION },
    });
  },
  createEmptyGrade({ dispatch }) {
    const grade = {
      id: null,
      translations: {
        en: { ...ITEM_TRANSLATION },
      },
      image: null,
      goal: null,
      discount: null,
    };

    dispatch('setLoadedGrade', grade);
  },
  showEditorDialog({
    dispatch,
  }, gardeId) {
    dispatch('setShownEdirorDialog', true);

    if (gardeId) {
      dispatch('setCreateEditorDialog', false);
      dispatch('fetchGrade', gardeId);
    } else {
      dispatch('setCreateEditorDialog', true);
      dispatch('createEmptyGrade');
    }
  },
  async storeGrade({
    state,
    dispatch,
    rootGetters,
  }) {
    return rootGetters['grades/getRepository'].store(state.grade)
      .then(() => {
        dispatch('setShownEdirorDialog', false);
        dispatch('grades/table/fetch', null, { root: true });
      })
      .catch((errors) => {
        dispatch('setErrors', errors.messages);

        return Promise.reject(new Error(errors));
      });
  },
  updateGrade({
    state,
    dispatch,
    rootGetters,
  }) {
    return rootGetters['grades/getRepository'].update(state.grade)
      .then(() => {
        dispatch('setShownEdirorDialog', false);
        dispatch('grades/table/fetch', null, { root: true });
      })
      .catch((errors) => {
        dispatch('setErrors', errors.messages);

        return Promise.reject(new Error(errors));
      });
  },
  async fetchGrade({
    dispatch, rootGetters,
  }, gradeId) {
    try {
      const loadedGrade = await rootGetters['grades/getRepository'].show(gradeId);

      dispatch('setLoadedGrade', loadedGrade);
    } catch (errors) {
      dispatch('setErrors', errors);
    }
  },
};
