<template>
  <!-- eslint-disable max-len -->
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#513CCC" />
    <path d="M4 13.3502L9.48898 18.301L20.0694 7" stroke="white" stroke-width="1.5" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: 'IconCheckbox',
};
</script>
