import ValidationError from '@/classes/errors/ValidationError';

const handleErrors = (error, transformErrorMessages) => {
  if (
    !error.response
    || !error.response.data
    || (!error.response.data.errors && !error.response.data.message)
  ) {
    return Promise.reject(error);
  }

  if (error.response.data.errors) {
    const messages = transformErrorMessages
      ? transformErrorMessages(error.response.data.errors)
      : error.response.data.errors;
    const { message } = error.response.data;

    return Promise.reject(new ValidationError(messages, message));
  }

  return Promise.reject(new Error(error.response.data.message));
};

export default handleErrors;
