import createExternalPanelFromResource from './createExternalPanel';

export default function createProviderFromResource(data) {
  if (!data) {
    return null;
  }

  return {
    actions: {
      archivable: data?.actions?.archivable,
      editable: data?.actions?.editable,
      restore: data?.actions?.restore,
    },
    id: data.id || null,
    name: data.name || null,
    type: data.type || null,
    sourceEvent: data.source_event || null,
    targetEvent: data.target_event || null,
    sourcePrice: data.source_price || null,
    sourcePricingPer: data.source_pricing_per || null,
    externalPanel: createExternalPanelFromResource(data.external_panel) || null,
    isArchived: Boolean(data.is_archived),
    status: data.status,
  };
}
