export default function createNetworkFromResource(data) {
  if (!data) {
    return null;
  }

  const ServiceTypeFromResource = require('@/classes/createServiceType').default;

  return {
    actions: {},
    id: data.id,
    name: data.name,
    icon: data.icon,
    color: data.color,
    serviceTypes: (data.serviceTypes || [])?.map((item) => new ServiceTypeFromResource(item)),
    urlExamples: data.url_examples,
    translations: data.translations,
    sorting: data.sorting,
  };
}
