export default {
  openProvidersService({ dispatch }, id) {
    dispatch('loadCollections');

    if (id) {
      dispatch('setExternalPanelId', id);
      dispatch('loadProvider', id);
    }

    dispatch('setIsShown', true);
  },
  closeProvidersServiceModal({
    dispatch,
    commit,
  }) {
    dispatch('setIsShown', false);
    commit('SET_CLEAR_STATE');
  },
  setIsShown({ commit }, bool) {
    commit('SET_IS_SHOWN', bool);
  },
  loadCollections({
    rootGetters,
    commit,
  }) {
    rootGetters['providersServices/getRepository'].collections()
      .then((response) => {
        commit('SET_СOLLECTIONS', response);
        commit('SET_SELECT_СOLLECTIONS', response);
      });
  },
  loadProvider({
    commit,
    rootGetters,
    state,
  }, id) {
    commit('SET_LOADING', true);

    rootGetters['providersServices/getRepository'].show(id)
      .then((provider) => {
        commit('SET_EDITING_PROVIDER', provider);
        commit('SET_FORM_DATA', {
          ...state.formData,
          ...provider,
        });
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  setSourcePricingPer({ commit }, value) {
    commit('SET_SOURCE_PRICING_PER', value);
  },
  setSourcePrice({ commit }, value) {
    commit('SET_SOURCE_PRICE', value);
  },
  setExternalPanelServiceId({ commit }, value) {
    commit('SET_EXTERNAL_PANEL_SERVICE_ID', value);
  },
  setExternalPanelId({ commit }, id) {
    commit('SET_EXTERNAL_PANEL_ID', id);
  },
  setType({ commit }, value) {
    commit('SET_TYPE', value);
  },
  setName({ commit }, value) {
    commit('SET_NAME', value);
  },
  setIsArchived({ commit }, value) {
    commit('SET_IS_ARCHIVED', value);
  },
  searchAvailableServices({ rootGetters }, externalPanelId) {
    return rootGetters['providersServices/getExternalPanelsRepository']
      .availableServices({ externalPanelId });
  },
  searchExternalPanels({ rootGetters }, query) {
    return rootGetters['providersServices/getExternalPanelsRepository'].search(query);
  },
  archiveService({
    rootGetters,
    dispatch,
  }, id) {
    rootGetters['providersServices/getRepository'].archive(id)
      .then(() => {
        dispatch('closeProvidersServiceModal');
        dispatch('providersServices/table/fetch', null, { root: true });
      });
  },
  unarchiveService({
    rootGetters,
    dispatch,
  }, id) {
    rootGetters['providersServices/getRepository'].unarchive(id)
      .then(() => {
        dispatch('closeProvidersServiceModal');
        dispatch('providersServices/table/fetch', null, { root: true });
      });
  },
  update({
    commit,
    rootGetters,
    state,
    dispatch,
  }) {
    commit('SET_LOADING', true);
    rootGetters['providersServices/getRepository'].update(state.editingProvider.id, state.formData)
      .then(() => {
        dispatch('closeProvidersServiceModal');
        dispatch('providersServices/table/fetch', null, { root: true });
      })
      .catch((error) => {
        commit('SET_ERRORS', error.messages);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  store({
    commit,
    rootGetters,
    state,
    dispatch,
  }) {
    commit('SET_LOADING', true);

    rootGetters['providersServices/getRepository'].store(state.formData)
      .then(() => {
        dispatch('closeProvidersServiceModal');
        dispatch('providersServices/table/fetch', null, { root: true });
      })
      .catch((error) => {
        commit('SET_ERRORS', error.messages);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  archiveRequest({
    dispatch,
    state,
    commit,
  }) {
    commit('SET_LOADING', true);

    if (state.formData.isArchived) {
      dispatch('archiveService', state.formData.externalPanelId);
    } else {
      dispatch('unarchiveService', state.formData.externalPanelId);
    }
  },
};
