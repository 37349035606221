import { defaultDialogSetupBuilder } from '@/components/services/dialog/dialogSetup';

export default (invokeArgs = {}) => {
  const dialogSubject = 'Combo-order';

  defaultDialogSetupBuilder(
    {
      isModalShown: true,
      confirmTitle: `Complete ${dialogSubject}`,
      confirmText: `Are you sure you want to complete ${dialogSubject}? This action cannot be undone.`,
      confirmBtnColor: 'invokedDialogRed',
      confirmBtnText: `Complete ${dialogSubject}`,
      ...invokeArgs,
    },
  );
};
