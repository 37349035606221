import stateExtender from '@/store/utils/stateExtender';
import RepositoryFactory from '@/services/repository-factory';
import datatableModule from '@/store/builder/datatableModule';
import table from '@/components/builders/configs/tables/invoiceTable';
import filters from '@/components/builders/configs/filters/invoiceFilters';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import cancelModal from './submodules/cancelModal';

const invoicesRepository = RepositoryFactory.get('invoices');
const state = {
  repository: invoicesRepository,
  error: null,
};

export default stateExtender({
  namespaced: true,
  state: () => state,
  modules: {
    table: datatableModule({
      fetch: invoicesRepository.table,
      headers: table.headers.filter((header) => header.isShown),
      allHeaders: table.headers,
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 30,
      },
      search: filters,
    }),
    cancelModal,
  },
  getters,
  mutations,
  actions,
});
