import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueApexCharts from 'vue-apexcharts';
import VueVirtualScroller from 'vue-virtual-scroller';
import DataTableFormatter from '@clickadilla/datatable-formatter';
import FiltersMemory from '@/components/FiltersMemory.vue';
import axios from '@/services/axios';
import App from './App.vue';
import router from './router/index';
import store from './store';
import vuetify from './plugins/vuetify';
import initLogger from './plugins/initLogger';
import Notification from './plugins/notification';
import settings from './settings';
import httpClient from './services/http';
import authFactory from './auth';
import '@/components/Base/globalComponents';
import '@/components/icons/globalIcons';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

authFactory({
  store,
  router,
  httpClient,
});

Vue.config.productionTip = (process.env.NODE_ENV !== 'development');

Vue.use(VueAxios, axios);
Vue.use(VueApexCharts);
Vue.use(Notification);
Vue.use(VueVirtualScroller);
Vue.component('ApexChart', VueApexCharts);
Vue.component('DataTableFormatter', DataTableFormatter);
Vue.component('FiltersMemory', FiltersMemory);

initLogger();

new Vue({
  provide: {
    settings,
  },
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
