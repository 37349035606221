export default {
  name: (state) => state.translations[state.selectedLocale]?.name,
  locale: (state) => state.selectedLocale,
  isShown: (state) => state.isShown,
  selectedServices: (state) => state.selectedServices,
  services: (state) => state.services,
  places: (state) => state.places,
  place: (state) => state.place,
  positions: (state) => state.positions,
  position: (state) => state.position,
  networks: (state) => state.networks,
  network: (state) => state.network,
  id: (state) => state.id,
  isArchive: (state) => state.isArchive,
  translations: (state) => state.translations,
  isEnabled: (state) => state.isEnabled,
  canMoreLoadServices: (state) => state.canMoreLoadServices,
  errors: (state) => state.errors,
};
