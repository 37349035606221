export default function processServiceDescription(description) {
  if (!description) {
    return description;
  }

  const regexp = /<iframe.+ql-video.+src="(.+?)".+\/iframe>/gim;
  const matches = Array.from(description.matchAll(regexp));
  const newDescription = matches.reduce((acc, match) => {
    const replacingText = match[0];
    const url = match[1];
    const before = acc.substr(0, acc.indexOf(replacingText));
    const after = acc.substr(acc.indexOf(replacingText) + replacingText.length);

    return `${before}
      <div style="position: relative; padding-bottom: 56.25%; height: 0;">
        <iframe
          class="ql-video"
          frameborder="0"
          allowfullscreen="true"
          src="${url}"
          style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; border: 0;"
        ></iframe>
      </div>
    ${after}`;
  }, description);

  return newDescription;
}
