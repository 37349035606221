import { stringify } from 'qs';
import datatableFormater from '@clickadilla/datatable-formatter/src/datatable-formater';

export default {
  setSearch({
    commit, getters,
  }, payload) {
    commit('SET_SEARCH', payload);

    if (getters.getOptions.page !== 1) {
      commit('SET_OPTIONS_PAGE', 1);
    }
  },
  debouncedFetch({
    dispatch, getters, commit,
  }, payload = getters.getUpdateTimeout) {
    if (getters.getTimer) {
      clearTimeout(getters.getTimer);
    }

    commit('SET_TIMER', setTimeout(() => { dispatch('fetch'); }, payload));
  },
  async fetch({
    commit, dispatch, getters,
  }) {
    commit('SET_LOADING', true);

    const requestBody = getters.formattedRequest
      ? stringify(datatableFormater(getters.getRequestBody))
      : getters.getRequestBody;

    try {
      const {
        items, totalItemsCount,
      } = await dispatch('fetchMethod', requestBody);

      commit('INCREMENT_DRAW');
      commit('SET_ITEMS', items);
      commit('SET_TOTAL_ITEMS_COUNT', totalItemsCount);
      commit('SET_LOADING', false);
    } catch (error) {
      if (error.message === 'new request' || error.message === 'canceled') return;
      commit('SET_ERROR', error);
      commit('SET_LOADING', false);
    }
  },
  fetchMethod({ getters }, payload) {
    return getters.getFetch({
      ...payload,
      ...getters,
    });
  },
  setPage({ commit }, payload) {
    commit('SET_OPTIONS_PAGE', payload);
  },
  startLoading({ commit }) {
    commit('START_LOADING');
  },
  endLoading({ commit }) {
    commit('END_LOADING');
  },
  clearSelected({ commit }) {
    commit('CLEAR_SELECTED');
  },
};
