import waiter from '@/store/utils/waiter';

export default {
  async archive({
    dispatch, getters, commit,
  }, discountId) {
    await waiter('discount/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.archive({ discountId });
    });
  },
  async unarchive({
    dispatch, getters, commit,
  }, discountId) {
    await waiter('discount/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.unarchive({ discountId });
    });
  },
  async complete({
    dispatch, getters, commit,
  }, discountId) {
    await waiter('discount/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.complete({ discountId });
    });
  },
};
