export default () => ({
  availableProviders: {
    items: null,
    currentPage: 1,
    lastPage: 1,
    loading: false,
    found: null,
    searchString: null,
  },
  selectedProviderId: null,
  isRecreatingProcess: false,
  refreshTable: false,
  errors: {},
});
