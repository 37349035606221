const KEY = 'redirectAfterLogin';

export function saveRedirectAfterLogin(to) {
  sessionStorage.setItem(KEY, to);
}

export function getSavedRedirectAfterLogin() {
  return sessionStorage.getItem(KEY);
}

export function clearSavedRedirectAfterLogin() {
  sessionStorage.removeItem(KEY);
}
