import waiter from '@/store/utils/waiter';

const refreshModule = 'users/table';

export default {
  async freezeUser({
    dispatch, getters, commit,
  }, id) {
    await waiter(refreshModule, {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.freeze({ userId: id });
    });
  },
  async deactivateUser({
    dispatch, getters, commit,
  }, id) {
    await waiter(refreshModule, {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.deactivate({ userId: id });
    });
  },
  async activateUser({
    dispatch, getters, commit,
  }, id) {
    await waiter(refreshModule, {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.activate({ userId: id });
    });
  },
};
