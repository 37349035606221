export default function createCouponsStoreRequest(formData) {
  return {
    id: formData.id,
    displayable: formData.displayable,
    name: formData.name,
    code: formData.code,
    valid_from: formData.validFrom,
    valid_to: formData.validTo,
    usage_limit: formData.usageLimit,
    bonus_type: formData.bonusType,
    bonus_amount_calculation_type: formData.bonusAmountCalculationType,
    bonus_percentage: (formData.bonusPercentage / 100).toFixed(2),
    bonus_amount: formData.bonusAmount,
    payment_type: formData.paymentType,
    min_amount: formData.minAmount,
    affiliate_id: formData.affiliate?.id || null,
    works_with_service_discount: formData.workWithServiceDiscount,
    works_with_user_custom_price: formData.workWithUserCustomPrice,
    works_with_balance: formData.workWithBalance,
    use_with_only_unpaid_orders: formData.useWithOnlyUnpaidOrders,
    translations: formData.translations,
  };
}
