import createStoreTransactionRequest from '@/classes/createStoreTransactionRequest';
import createStoreTransactionError from '@/classes/createStoreTransactionError';
import http from '../http';
import urlFormatter from '../url-formatter';
import createValidationError from '../functions/create-validation-error';

const getURL = urlFormatter({
  store: '/api/v1/admin/payments',
});

export default {
  store({
    userId,
    transactionType,
    amount,
    comment,
    balanceType,
  }) {
    const endpoint = getURL('store');

    return http.post(endpoint, createStoreTransactionRequest({
      userId,
      transactionType,
      amount,
      comment,
      balanceType,
    }))
      .catch(createValidationError(createStoreTransactionError));
  },
  getURL,
};
