import camelToSnake from '@/utils/camelToSnake';

export function createTargetType(data) {
  if (!data) {
    return null;
  }

  return {
    enabledForService: data.enabled_for_service,
    id: data.id,
    name: data.name,
    selectable: data.selectable,
    title: data.title,
    viewType: data.view_type,
  };
}

export function createTargetTypes(data) {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    name: data.name,
    placeholder: data.placeholder,
    selectableValues: data.selectable_values,
    targetType: createTargetType(data.target_type),
    translations: data.translations,
  };
}

export function createFieldsTypes(data) {
  if (!data) {
    return null;
  }

  return {
    floatInput: data.view_types.float_input,
    input: data.view_types.input,
    multiselect: data.view_types.multiselect,
    numberInput: data.view_types.number_input,
    select: data.view_types.select,
    textarea: data.view_types.textarea,
    urlInput: data.view_types.url_input,
  };
}

export function createShowTargetType(data) {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    name: data.name,
    viewType: data.view_type,
    title: data.title,
    selectableValues: data.selectable_values,
    selectable: data.selectable,
    enabledForService: data.enabled_for_service,
  };
}

export function createTargetResponse(data) {
  return {
    title: data.title,
    view_type: camelToSnake(data.viewType),
    selectable: data.selectable,
    enabled_for_service: data.enabledForService,
  };
}
