export default function createServiceTranslations(translations) {
  if (!translations) {
    return {};
  }

  return Object.entries(translations)
    .reduce((acc, [key, localeTranslation]) => {
      acc[key] = {
        name: localeTranslation?.name ?? '',
      };

      return acc;
    }, {});
}
