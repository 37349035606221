import handleErrors from '@/services/handle-errors';
import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  store: '/api/v1/admin/payment-method-bonuses',
  delete: '/api/v1/admin/payment-method-bonuses/{id}',
});

function createUpdateBonusesRequestBody(formData, paymentMethodId) {
  return {
    payment_method_id: paymentMethodId,
    bonuses: formData.map((bonus) => {
      if (Object.prototype.hasOwnProperty.call(bonus, 'id')) {
        return {
          from_amount: bonus.fromAmount,
          bonus_percent: bonus.bonusPercent / 100,
          is_enabled: bonus.isEnabled,
          id: bonus.id,
        };
      }

      return {
        from_amount: bonus.fromAmount,
        bonus_percent: bonus.bonusPercent / 100,
        is_enabled: bonus.isEnabled,
      };
    }),
  };
}

const createBonus = (bonuses) => (bonuses.map((bonus) => ({
  fromAmount: bonus.from_amount,
  bonusPercent: bonus.bonus_percent,
  isEnabled: bonus.is_enabled,
  id: bonus.id,
})));
const handleUpdateBonusesRequestErrors = (error) => handleErrors(error);

export default {
  store(paymentMethodId, formData) {
    const endpoint = getURL('store');

    return http.post(endpoint, createUpdateBonusesRequestBody(formData, paymentMethodId))
      .then((response) => createBonus(response.data.data))
      .catch(handleUpdateBonusesRequestErrors);
  },
  delete(id) {
    const endpoint = getURL('delete', { id });

    return http.delete(endpoint);
  },
  getURL,
};
