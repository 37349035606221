export default function createAutoRefillFromResource(data) {
  return {
    id: data.id,
    defaultPrice: data.default_price,
    maxQuantity: data.max_quantity,
    minQuantity: data.min_quantity,
    name: data.name,
    pricingPer: data.pricing_per,
    quantityStep: data.quantity_step,
  };
}
