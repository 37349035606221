import http from '../http';
import urlFormatter from '../url-formatter';
import handleErrors from '../handle-errors';

const getURL = urlFormatter({
  fetch: '/api/v1/admin/locales',
  translate: '/api/v1/admin/translate',
});

export default {
  translate({
    fromLanguage,
    toLanguage,
    fields,
  }) {
    const endpoint = getURL('translate');

    return http.post(endpoint, {
      from_language: fromLanguage,
      to_language: toLanguage,
      fields,
    })
      .then((response) => response.data.translations)
      .catch((error) => handleErrors(error));
  },
  getURL,
};
