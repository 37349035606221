import createUserFromResource from '@/classes/createUser';
import createPaymentMethodFromResource from '@/classes/createPaymentMethod';

export default function createOrderWidgetFromResource(orderWidgetResource) {
  return {
    id: orderWidgetResource.id,
    name: orderWidgetResource.name,
    user: createUserFromResource(orderWidgetResource.user),
    isOuter: orderWidgetResource.is_outer,
    minimumPurchaseAmount: orderWidgetResource.minimum_purchase_amount,
    rewardPercent: orderWidgetResource.reward_percent,
    paymentMethods: orderWidgetResource.payment_methods
      .map((paymentMethod) => createPaymentMethodFromResource(paymentMethod)),
  };
}
