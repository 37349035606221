import stateExtender from '@/store/utils/stateExtender';
import RepositoryFactory from '@/services/repository-factory';
import datatableModule from '@/store/builder/datatableModule';
import table from '@/components/builders/configs/tables/paymentMethodsTable';
import filters from '@/components/builders/configs/filters/paymentMethodsFilter';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import paymentMethodModal from './submodules/paymentMethodModal';
import bonusModal from './submodules/bonusModal';
import positionModal from './submodules/positionModal';

const paymentMethodsRepository = RepositoryFactory.get('paymentMethods');
const state = {
  repository: paymentMethodsRepository,
  error: null,
};

export default stateExtender({
  namespaced: true,
  state: () => state,
  modules: {
    table: datatableModule({
      fetch: paymentMethodsRepository.table,
      headers: table.headers.filter((header) => header.isShown),
      allHeaders: table.headers,
      options: {
        sortBy: ['sorting'],
        itemsPerPage: 30,
      },
      search: filters,
    }),
    paymentMethodModal,
    bonusModal,
    positionModal,
  },
  getters,
  mutations,
  actions,
});
