export default function createExternalPanelFromResource(data) {
  if (!data) {
    return null;
  }

  const type = data.type === 'socpanel' ? 'panvy' : data.type;

  return {
    actions: {
      archivable: data?.actions?.archivable,
      editable: data?.actions?.editable,
      restore: data?.actions?.restore,
      showBilling: data?.actions?.show_billing,
    },
    balance: data.balance,
    currency: data.currency,
    endpoint: data.endpoint,
    id: data.id,
    name: data.name,
    resaleMarginPercent: data.resale_margin_percent,
    statisticType: data.statistic_type,
    syncServices: data.sync_services,
    syncParams: data.sync_params,
    favicon: data.favicon,
    syncPrices: data.sync_prices,
    syncTextFields: data.sync_text_fields,
    syncDescriptionField: data.sync_description_field,
    syncShortDescriptionField: data.sync_short_description,
    syncReviewsField: data.sync_reviews,
    syncLabels: data.sync_labels,
    type,
    apiToken: data.api_token,
    autoRecreateOrdersIsAllowed: data.auto_recreate_orders_is_allowed,
    syncFailed: data.sync_failed,
    isArchived: data.is_archived,
    status: data.status,
  };
}
