import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createLogFromResource from '@/classes/createChangeLogs';
import http, { cancelTokenNames } from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  table: 'api/v1/admin/services/logs',
  typeCollections: 'api/v1/admin/services/logs/collections',
});

export default {
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('table');
    const response = await http.get(`${endpoint}?${requestBody}`, {
      applyCancelAdapter: cancelTokenNames.SERVICE_LOGS_TABLE,
    });

    return {
      items: response.data.data.map(createLogFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  async typeCollections() {
    const endpoint = getURL('typeCollections');
    const response = await http.get(endpoint);

    return {
      types: response.data.data.types,
      actionTypes: response.data.data.action_types,
    };
  },
  getURL,
};
