export const getSavedFilters = ({ KEY }) => JSON.parse(sessionStorage.getItem(KEY)) ?? null;

export const rememberedFiltersHasNoEmpty = ({ KEY }) => {
  const filters = getSavedFilters({ KEY });

  return filters
    ? Object.keys(filters).some((value) => !!value)
    : false;
};

export const saveFilters = ({
  KEY, filters,
}) => {
  sessionStorage.setItem(KEY, JSON.stringify(filters));
};
