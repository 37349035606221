import { defaultDialogSetupBuilder } from '@/components/services/dialog/dialogSetup';

export default (invokeArgs = {}) => {
  defaultDialogSetupBuilder(
    {
      isModalShown: true,
      confirmTitle: 'Freeze account',
      confirmText: 'Are you sure you want to freeze account? You will be able to undo this action.',
      confirmBtnColor: 'statusFrozen',
      confirmBtnText: 'Freeze',
      ...invokeArgs,
    },
  );
};
