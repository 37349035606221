export default {
  openPositionalModal({
    commit,
    dispatch,
  }) {
    commit('SET_IS_SHOWN', true);
    dispatch('getPaymentMethodsList');
  },
  closePositionalModal({
    dispatch,
    commit,
  }) {
    commit('SET_IS_SHOWN', false);
    dispatch('resetState', false);
  },
  getPaymentMethodsList({
    rootGetters,
    commit,
  }) {
    rootGetters['paymentMethods/getRepository'].list()
      .then((response) => {
        commit('SET_PAYMENT_METHOD_LIST', response);
      });
  },
  setPaymentMethodsList({ commit }, value) {
    commit('SET_PAYMENT_METHOD_LIST', value);
  },
  savePaymentMethodPositions({
    rootGetters,
    state,
    dispatch,
  }) {
    if (!state.paymentMethodsList.length) return;
    rootGetters['paymentMethods/getRepository'].sort(
      state.paymentMethodsList.map((item) => item.id),
    )
      .then(() => {
        dispatch('paymentMethods/table/fetch', null, { root: true });
        dispatch('closePositionalModal');
      });
  },
  resetState({ commit }) {
    commit('SET_PAYMENT_METHOD_LIST', []);
  },
};
