import settings from '@/settings';
import {
  createTextFilter,
  createEnabledFilter,
} from '../builder-definition/filters/table';
import {
  createCellActions,
  createCellPlainStatuses,
  createCellNameAndType,
} from '../builder-definition/tables';
import {
  createCellImage,
  createCellBonuses,
  createCellBool,
} from '../builder-definition/tables/plain-builders';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      name: 'payment_methods.id',
      width: '58px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
      ...createCellPlainStatuses({
        mapper: 'paymentMethods',
        statusProp: 'status',
      }),
    },
    {
      value: 'sorting',
      text: 'Position',
      name: 'sorting',
      width: '100px',
      hideable: false,
      isShown: false,
    },
    {
      value: 'icon',
      text: 'Icon',
      width: '100px',
      sortable: false,
      isShown: true,
      ...createCellImage(),
    },
    {
      value: 'name',
      text: 'Name',
      name: 'payment_method_translations.name',
      width: '117px',
      customFilter: createTextFilter({ placeholder: 'Search Name' }),
      ...createCellNameAndType({
        typeColumn: 'type',
      }),
      isShown: true,
    },
    {
      value: 'withdrawalOptions.availableForWithdrawal',
      name: 'available_for_withdrawal',
      text: 'Withdrawal',
      width: '94px',
      sortable: false,
      isShown: true,
      customFilter: createEnabledFilter(),
      ...createCellBool(),
    },
    {
      value: 'paymentMethodBonuses',
      name: 'payment_method_bonuses',
      text: 'Bonuses',
      width: '90px',
      isShown: true,
      sortable: false,
      customFilter: createEnabledFilter(),
      ...createCellBonuses(),
    },
    {
      text: 'Status',
      value: 'status',
      isHidden: true,
    },
    {
      value: 'actions',
      text: 'Actions',
      width: '60px',
      searchable: false,
      sortable: false,
      isShown: true,
      permission: settings.permissions.managePaymentMethods,
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Disable',
              value: 'actions.disable',
              prop: 'disable',
              callBack: (item, store) => {
                store.dispatch('paymentMethods/confirmedDisable', item.id);
              },
            },
            {
              text: 'Enable',
              value: 'actions.enable',
              prop: 'enable',
              callBack: (item, store) => {
                store.dispatch('paymentMethods/confirmedEnable', item.id);
              },
            },
            {
              text: 'Edit',
              value: 'actions.edit',
              prop: 'edit',
              callBack: (item, store) => {
                store.dispatch('paymentMethods/paymentMethodModal/setPaymentMethodModalOpen', {
                  id: item.id,
                  isShown: true,
                  type: 'edit',
                });
              },
            },
            {
              text: 'Add Bonus',
              callBack: (item, store) => {
                store.dispatch('paymentMethods/bonusModal/bonusModalOpen', {
                  id: item.id,
                  isShown: true,
                });
              },
            },
            {
              text: 'Edit Payment Method Positions',
              callBack: (item, store) => {
                store.dispatch('paymentMethods/positionModal/openPositionalModal');
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;

            return tableElement.actions[prop]
              ? tableElement.actions[prop]
              : false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
