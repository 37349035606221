import dateFormatter from '@/services/functions/date-filter';
import createUserFromResource from '@/classes/createUser';

function parseJSON(obj) {
  if (Array.isArray(obj)) {
    return {};
  }

  return Object.entries(obj)
    .reduce((acc, [key, value]) => {
      let parsedValue;

      try {
        parsedValue = JSON.parse(value);
      } catch {
        parsedValue = value;
      }

      acc[key] = parsedValue;

      return acc;
    }, {});
}

export default function createAuditLogItemFromResource(data) {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    createdAt: dateFormatter(data.created_at),
    user: createUserFromResource(data.user),
    ipAddress: data.ip_address,
    userAgent: data.user_agent,
    event: data.event,
    auditableType: data.auditable_type,
    auditableId: data.auditable_id,
    oldValues: parseJSON(data.old_values),
    newValues: parseJSON(data.new_values),
  };
}
