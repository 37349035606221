import createServiceFromResource from './createService';

export default function createUserServiceFromResource(data) {
  return {
    id: data.id || 0,
    autoRefillIsAllowed: data.auto_refill_is_allowed || false,
    autoRefillMinDropPercent: data.auto_refill_min_drop_percent || '',
    autoRefillMaxDropPercent: data.auto_refill_max_drop_percent || '',
    autoRefillMaxTotalRefilledPercent: data.auto_refill_max_total_refilled_percent || '',
    autoRefillOnceFillPercent: data.auto_refill_once_fill_percent || '',
    hasRefill: data.has_refill,
    minPrice: data.min_price || '',
    newPrice: data.default_price ? Number(data.default_price.replace(/\s/g, '')) : data.default_price,
    maxOrderQuantityForUseThrottle: data.max_order_quantity_for_use_throttle || '',
    orderQuantityRatio: data.order_quantity_ratio || '',
    service: data.service ? createServiceFromResource(data.service) : null,
  };
}
