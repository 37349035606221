import settings from '@/settings';
import {
  createDateFilter,
  createTextFilter,
  createBonusTypeFilter,
  createPaymentTypeFilter,
  createCouponsFilter,
} from '../builder-definition/filters/table';
import {
  createCellPlainStatuses, createCellActions, createCellLink,
} from '../builder-definition/tables';
import {
  createCellCopy,
} from '../builder-definition/tables/plain-builders';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      name: 'coupons.id',
      width: '70px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
      ...createCellPlainStatuses({
        mapper: 'couponsList',
        statusProp: 'status',
      }),
    },
    {
      value: 'name',
      name: 'coupon_translations.name',
      text: 'Name',
      width: '132px',
      sortable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search name' }),
    },
    {
      value: 'code',
      text: 'Code',
      width: '100px',
      sortable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search code' }),
      ...createCellCopy(),
    },
    {
      value: 'bonusType',
      name: 'bonus_type',
      text: 'Bonus type',
      width: '120px',
      customFilter: createBonusTypeFilter(),
      sortable: false,
      isShown: true,
    },
    {
      name: 'payment_type',
      value: 'paymentType',
      text: 'Payment type',
      customFilter: createPaymentTypeFilter(),
      width: '120px',
      isShown: true,
    },
    {
      value: 'bonusAmountCalculationType',
      name: 'bonus_amount_calculation_type',
      customFilter: createCouponsFilter(),
      text: 'Defined as',
      width: '120px',
      isShown: true,
    },
    {
      value: 'validFrom',
      name: 'valid_from',
      text: 'Valid from',
      width: '200px',
      isShown: true,
      customFilter: createDateFilter(),
    },
    {
      value: 'validTo',
      name: 'valid_to',
      text: 'Valid to',
      width: '200px',
      isShown: true,
      customFilter: createDateFilter(),
    },
    {
      value: 'usageLimit',
      text: 'Usage limit',
      name: 'usage_limit',
      width: '120px',
      isShown: true,
    },
    {
      value: 'minAmount',
      text: 'Min amount',
      name: 'min_amount',
      width: '120px',
      isShown: true,
    },
    {
      value: 'bonusAmount',
      text: 'Bonus amount',
      name: 'bonus_amount',
      width: '120px',
      isShown: true,
    },
    {
      value: 'bonusPercentage',
      text: 'Bonus percentage',
      name: 'bonus_percentage',
      width: '120px',
      isShown: true,
    },
    {
      value: 'affiliate.email',
      text: 'Referral manager',
      name: 'affiliate.email',
      customFilter: createTextFilter({ placeholder: 'Search referral' }),
      ...createCellLink({
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.affiliate?.id,
          },
        }),
      }),
      width: '120px',
      isShown: true,
    },
    {
      text: 'Status',
      value: 'status',
      name: 'status',
      isHidden: true,
    },
    {
      value: 'actions',
      text: 'Actions',
      width: '60px',
      searchable: false,
      sortable: false,
      isShown: true,
      permission: settings.permissions.manageCoupons,
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Archive',
              value: 'archivable',
              prop: 'archivable',
              callBack: (item, store) => {
                store.dispatch('couponsList/couponModal/archive', item.id);
              },
            },
            {
              text: 'Unarchive',
              value: 'restore',
              prop: 'restore',
              callBack: (item, store) => {
                store.dispatch('couponsList/couponModal/unarchive', item.id);
              },
            },
            {
              text: 'Edit',
              value: 'editable',
              prop: 'editable',
              callBack: (item, store) => {
                store.dispatch('couponsList/couponModal/openModal', {
                  id: item.id,
                  isShown: true,
                  formData: item,
                });
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;

            return tableElement.actions[prop]
              ? tableElement.actions[prop]
              : false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
