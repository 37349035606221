export default {
  openBillingModals({
    dispatch,
    commit,
  }, { id }) {
    commit('SET_BILLINGS');

    if (id) {
      dispatch('setId', id);
    }

    dispatch('setIsShown', true);
  },
  setId({ commit }, value) {
    commit('SET_ID', value);
  },
  setIsShown({ commit }, value) {
    commit('SET_IS_SHOWN', value);
  },
  closeBillingModal({
    dispatch,
  }) {
    dispatch('setIsShown', false);
    dispatch('setClearState');
  },
  setClearState({ commit }) {
    commit('SET_CLEAR_STATE');
  },
  setBillings({ commit }, value) {
    commit('SET_BILLINGS', value);
  },
  fetchBillings({
    rootGetters,
    state,
  }, queryParams) {
    if (!state.externalPanelId) {
      return Promise.reject(new Error('External Panel ID is missing'));
    }

    return rootGetters['providers/getRepository'].billings(queryParams, state.externalPanelId);
  },
};
