import {
  clearSavedRedirectAfterLogin, getSavedRedirectAfterLogin,
} from '@/utils/rememberRedirectAfterLogin';
import tokenStoreFactory from './tokenStore';
import getImpersonationToken from './getImpersonationToken';
import routerPermissions from '../router/routerPermissions';

export default function authFactory({
  store,
  router,
  httpClient,
}) {
  const tokenStore = tokenStoreFactory();
  const impersonationToken = getImpersonationToken();

  if (impersonationToken) {
    tokenStore.saveToken(impersonationToken);

    const savedRedirectUrl = getSavedRedirectAfterLogin();

    if (savedRedirectUrl) {
      clearSavedRedirectAfterLogin();
      window.location.assign(savedRedirectUrl);
    }
  }

  store.dispatch('auth/setConfig', {
    router,
    tokenStore,
  });

  const initialization = store.dispatch('auth/initialRefresh');

  httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error && error.response && error.response.status === 401) {
        store.dispatch('auth/logout');
      }

      return Promise.reject(error);
    },
  );

  httpClient.interceptors.request.use((config) => {
    const savedToken = tokenStore.getSavedToken();

    if (!savedToken) {
      return config;
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer ${savedToken}`,
      },
    };
  });

  const emptyPageRouteName = 'Home';
  const getFallbackRouteName = () => {
    const allPermissions = store.getters['auth/allPermissions'];
    const defaultAuthRedirectRoute = {
      name: 'Services',
      roles: routerPermissions.Services,
    };

    return allPermissions.includes(defaultAuthRedirectRoute.roles)
      ? defaultAuthRedirectRoute.name
      : emptyPageRouteName;
  };

  router.beforeEach(async (to, from, next) => {
    await initialization;

    if (to.meta.auth === undefined) {
      next();

      return;
    }

    const requiredAuth = Boolean(to.meta.auth);
    const requiredRole = typeof to.meta.auth === 'object' && to.meta.auth.roles;
    const isLoggedIn = store.getters['auth/isLoggedIn'];
    const fallbackRouteName = getFallbackRouteName();

    if (isLoggedIn && !requiredAuth && !store.state.isLoggingOutInProcess) {
      next({
        name: emptyPageRouteName,
      });

      return;
    }

    if (!isLoggedIn && requiredAuth) {
      next({
        name: 'Login',
      });

      return;
    }

    if (requiredRole) {
      const allPermissions = store.getters['auth/allPermissions'];

      if (allPermissions.some((item) => to.meta.auth.roles.includes(item))) {
        next();

        return;
      }

      next({
        name: fallbackRouteName,
      });
    }

    if (to.name === emptyPageRouteName && fallbackRouteName !== emptyPageRouteName) {
      next({
        name: fallbackRouteName,
      });

      return;
    }

    next();
  });

  return initialization;
}
