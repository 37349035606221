<template>
  <div class="no-data d-flex align-center justify-center flex-column ">
    <icon-search-big />

    <div class="no-data__text">
      {{ text }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      default: 'No data found',
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data{
  background-color: var(--v-baseNoDataBgc-base)!important;
  margin: 0 -16px;
  padding: 82px 0;
  &__text{
    font-weight: 500!important;
    font-size: 24px!important;
    line-height: 32px!important;
    text-align: center!important;
    letter-spacing: 0.01em!important;
    color: #60636A!important;
  }
}
</style>
