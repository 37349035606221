export default {
  openModal({ dispatch }, {
    id, isShown, formData,
  }) {
    if (id) {
      dispatch('setId', id);
      dispatch('setFormData', formData);
    }

    dispatch('createTranslation');

    dispatch('setIsShown', isShown);
  },
  setId({ commit }, id) {
    commit('SET_COUPON_ID', id);
  },
  closeModal({ dispatch }) {
    dispatch('setIsShown', false);
    dispatch('resetState');
  },
  setLocale({ commit }, locale) {
    commit('SET_LOCALE', locale);
  },
  createTranslation({
    dispatch, rootGetters, state,
  }) {
    const translations = { ...state.formData.translations };

    rootGetters['settings/localesValuesArray'].forEach((locale) => {
      const { value } = locale;

      if (!Object.prototype.hasOwnProperty.call(translations, value)) {
        translations[value] = {
          name: '',
          description: '',
        };
      }
    });
    dispatch('setTranslations', translations);
  },
  setTranslations({ commit }, translations) {
    commit('SET_TRANSLATIONS', translations);
  },
  setDisplayable({ commit }, value) {
    commit('SET_DISPLAYABLE', value);
  },
  setUseWithOnlyUnpaidOrders({ commit }, value) {
    commit('SET_USE_WITH_ONLY_UNPAID_ORDERS', value);
  },
  setWorkWithServiceDiscount({ commit }, value) {
    commit('SET_WORK_WITH_SERVICE_DISCOUNT', value);
  },
  setWorkWithUserCustomPrice({ commit }, value) {
    commit('SET_WORK_WITH_USER_CUSTOM_PRICE', value);
  },
  setCode({ commit }, value) {
    commit('SET_CODE', value);
  },
  setValidFrom({ commit }, value) {
    commit('SET_VALID_FROM', value);
  },
  setValidTo({ commit }, value) {
    commit('SET_VALID_TO', value);
  },
  setFormData({ commit }, formData) {
    commit('SET_FORM_DATA', formData);
  },
  setUsageLimit({ commit }, value) {
    commit('SET_USAGE_LIMIT', value);
  },
  setBonusType({ commit }, value) {
    commit('SET_BONUS_TYPE', value);
  },
  setBonusAmountCalculationType({ commit }, value) {
    commit('SET_BONUS_AMOUNT_CALCULATION_TYPE', value);
  },
  setBonusPercentage({ commit }, value) {
    commit('SET_BONUS_PERCENTAGE', value);
  },
  setBonusAmount({ commit }, value) {
    commit('SET_BONUS_AMOUNT', value);
  },
  onlyNumbersValidate(_, e) {
    const char = String.fromCharCode(e.keyCode);

    if (/^[0-9]+$/.test(char)) {
      return;
    }

    e.preventDefault();
  },
  setPaymentType({ commit }, value) {
    commit('SET_PAYMENT_TYPE', value);
  },
  setMinAmount({ commit }, value) {
    commit('SET_MIN_AMOUNT', value);
  },
  setAffiliate({ commit }, value) {
    commit('SET_AFFILIATE', value);
  },
  setName({ commit }, value) {
    commit('SET_TRANSLATIONS_NAME', value);
  },
  setDescription({ commit }, value) {
    commit('SET_DESCRIPTION', value);
  },
  resetState({
    commit, dispatch,
  }) {
    commit('SET_CLEAR_STATE');
    dispatch('createTranslation');
  },
  setErrors({ commit }, errors) {
    commit('SET_ERRORS', errors);
  },
  filterTranslations({ dispatch }, translations) {
    dispatch('setTranslations', Object.keys(translations).reduce((result, key) => {
      if (key === 'en' || translations[key].name !== '') {
        result[key] = translations[key];
      }

      return result;
    }, {}));
  },
  setIsShown({ commit }, value) {
    commit('SET_IS_SHOWN', value);
  },
  setWorkWithBalance({ commit }, value) {
    commit('SET_WORK_WITH_BALANCE', value);
  },
  save({
    state, rootGetters, dispatch, commit,
  }, { errorNotify }) {
    commit('SET_NAME');
    dispatch('setLocale', 'en');
    dispatch('filterTranslations', state.formData.translations);

    if (state.couponId) {
      rootGetters['couponsList/getRepository'].update(state.couponId, state.formData)
        .then(() => {
          dispatch('couponsList/table/fetch', null, { root: true });
          dispatch('closeModal', false);
        })
        .catch((error) => {
          if (error.messages) {
            dispatch('createTranslation');
            dispatch('setErrors', error.messages);
          }

          if (errorNotify) {
            errorNotify();
          }
        });
    } else {
      rootGetters['couponsList/getRepository'].store(state.formData)
        .then(() => {
          dispatch('couponsList/table/fetch', null, { root: true });
          dispatch('closeModal', false);
        })
        .catch((error) => {
          if (error.messages) {
            dispatch('createTranslation');
            dispatch('setErrors', error.messages);
          }

          if (errorNotify) {
            errorNotify();
          }
        });
    }
  },
  async archive({
    rootGetters, dispatch,
  }, id) {
    await rootGetters['couponsList/getRepository'].archive(id)
      .then(() => {
        dispatch('couponsList/table/fetch', null, { root: true });
      });
  },
  async unarchive({
    rootGetters, dispatch,
  }, id) {
    await rootGetters['couponsList/getRepository'].unarchive(id)
      .then(() => {
        dispatch('couponsList/table/fetch', null, { root: true });
      });
  },
};
