import axios from './axios';

const cancelTokenMap = new Map();

export const cancelTokenNames = {
  ORDERS_TABLE: 'ORDERS_TABLE',
  DRIP_FEED_TABLE: 'DRIP_FEED_TABLE',
  INVOICE_TABLE: 'INVOICE_TABLE',
  SUBSCRIPTIONS_TABLE: 'SUBSCRIPTIONS_TABLE',
  TRANSACTIONS_TABLE: 'TRANSACTIONS_TABLE',
  USERS_TABLE: 'USERS_TABLE',
  WITHDRAWAL_TABLE: 'WITHDRAWAL_TABLE',
  SPECIAL_OFFERS_TABLE: 'SPECIAL_OFFERS_TABLE',
  SERVICE_LOGS_TABLE: 'SERVICE_LOGS_TABLE',
  COUPONS_TABLE: 'COUPONS_TABLE',
  STATISTICS_TABLE: 'STATISTICS_TABLE',
  DISCOUNT_TABLE: 'DISCOUNT_TABLE',
  NETWORKS_TABLE: 'NETWORKS_TABLE',
  PACKAGES_TOTAL_COST: 'PACKAGES_TOTAL_COST',
  PAYMENT_METHODS_TABLE: 'PAYMENT_METHODS_TABLE',
  PROVIDERS_TABLE: 'PROVIDERS_TABLE',
  PROVIDERS_SERVICES_TABLE: 'PROVIDERS_SERVICES_TABLE',
};

axios.interceptors.request.use((config) => {
  const mapKey = config.applyCancelAdapter;

  if (!mapKey) return config;

  if (cancelTokenMap.get(mapKey)) {
    cancelTokenMap.get(mapKey).abort('new request');
  }

  cancelTokenMap.set(mapKey, new AbortController());

  return {
    signal: cancelTokenMap.get(mapKey).signal,
    ...config,
  };
});

export default axios;
