<template>
  <v-select
    v-bind="$attrs"
    ref="select"
    outlined
    dense
    :clear-icon="clearIcon"
    :append-icon="actualIcon"
    :menu-props="{
      offsetY: true,
      'content-class': contentClass,
    }"
    :class="[
      'base-select',
      {
        'flat-bottom': focused,
        'persistent-border': isPersistedBorders,
        medium: size === 'm',
        small: size === 's',
      },
    ]"
    v-on="$listeners"
    @click="checkFocusState"
    @blur="checkFocusState"
    @input="checkFocusState"
    @change="checkFocusState"
  />
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'm',
    },
    appendIcon: {
      type: String,
      default: '$icon-caret-down',
    },
    clearIcon: {
      type: String,
      default: '$icon-clear',
    },
    persistentBorder: {
      type: Boolean,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    actualIcon() {
      return this.size === 'm' ? this.appendIcon : '$icon-caret-down-slim';
    },
    contentClass() {
      return `next base-select-content${this.size === 'm' ? '' : '--small'}`;
    },
    isPersistedBorders() {
      return this.persistentBorder && !!this.$attrs?.value?.length;
    },
  },
  mounted() {
    this.checkFocusState();
  },
  methods: {
    checkFocusState() {
      this.$nextTick(() => {
        this.focused = this.$refs.select?.isMenuActive;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep{
  .v-text-field__details{
    display: none;
  }
  .v-input__slot {
    min-height: auto!important;
    padding: 0 16px!important;
    margin: 0!important;
    @extend .p-16-r;
  }
}
.medium::v-deep {
  .v-input__slot {
    padding: 0 16px!important;
    @extend .p-16-r;
  }
  .v-list-item__title {
    @extend .p-16-r;
  }
}
.small::v-deep {
  .v-input__slot {
    max-height: 24px;
    padding: 0 4px 0 8px!important;
    @extend .p-16-r;
    font-size: 12px!important;
  }
  .v-list-item__title {
    @extend .p-16-r;
    font-size: 8px!important;
  }
  .v-input__append-inner{
    margin: 0!important;
  }
}
</style>
