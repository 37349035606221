import {
  createTextFilter,
  createPlacesFilter,
  createPositionsFilter,
} from '../builder-definition/filters/table';
import {
  createCellPlainStatuses, createCellActions, createCellNetworkName, createCellPlace,
} from '../builder-definition/tables';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      name: 'special_offers.id',
      width: '68px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
      ...createCellPlainStatuses({
        mapper: 'specialOffers',
        statusProp: 'status',
      }),
    },
    {
      value: 'name',
      text: 'Name',
      name: 'special_offer_translations.name',
      width: '145px',
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search name' }),
    },
    {
      value: 'place',
      text: 'Place',
      name: 'special_offers.place',
      width: '134px',
      sortable: false,
      isShown: true,
      customFilter: createPlacesFilter(),
      ...createCellPlace(),
    },
    {
      value: 'position',
      text: 'Position',
      name: 'special_offers.position',
      width: '120px',
      isShown: true,
      customFilter: createPositionsFilter(),
      ...createCellNetworkName(),
    },
    {
      text: 'Status',
      value: 'status',
      isHidden: true,
    },
    {
      value: 'actions',
      text: 'Actions',
      width: '60px',
      searchable: false,
      sortable: false,
      isShown: true,
      align: 'end',
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Edit',
              callBack: (item, store) => {
                store.dispatch('specialOffers/offerModal/openModal', {
                  id: item.id,
                  name: item.name,
                  isShown: true,
                  isArchive: item.isArchived,
                });
              },
            },
            {
              text: 'Archive',
              value: 'archival',
              prop: 'archival',
              callBack: (item, store) => {
                store.dispatch('specialOffers/offerModal/setId', item.id);
                store.dispatch('specialOffers/offerModal/setIsArchive', item.isArchived);
                store.dispatch('specialOffers/offerModal/archive');
              },
            },
            {
              text: 'Unarchive',
              value: 'restore',
              prop: 'restore',
              callBack: (item, store) => {
                store.dispatch('specialOffers/offerModal/setId', item.id);
                store.dispatch('specialOffers/offerModal/setIsArchive', item.isArchived);
                store.dispatch('specialOffers/offerModal/archive');
              },
            },
            {
              text: 'Enable',
              value: 'enable',
              prop: 'enable',
              callBack: (item, store) => {
                store.dispatch('specialOffers/offerModal/enable', item.id);
              },
            },
            {
              text: 'Disable',
              value: 'disable',
              prop: 'disable',
              callBack: (item, store) => {
                store.dispatch('specialOffers/offerModal/disable', item.id);
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;

            return tableElement.actions[prop]
              ? tableElement.actions[prop]
              : false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
