import { defaultDialogSetupBuilder } from '@/components/services/dialog/dialogSetup';

export default (invokeArgs = {}) => {
  const postfix = invokeArgs.amount > 1 ? 's' : '';
  const dialogSubject = `order${postfix}`;

  defaultDialogSetupBuilder(
    {
      isModalShown: true,
      confirmTitle: `Cancel the ${dialogSubject}`,
      confirmText: `Are you sure you want to cancel
 <strong>${invokeArgs.amount}</strong> ${dialogSubject}? This action cannot be undone.`,
      confirmBtnColor: 'invokedDialogRed',
      confirmBtnText: `Cancel ${dialogSubject} (${invokeArgs.amount})`,
      ...invokeArgs,
    },
  );
};
