export default function createFreeTrialsUpdateData(data) {
  if (!data) {
    return null;
  }

  return {
    is_enabled: data.isEnabled,
    allow_proof_url: data.allowProofUrl,
    auto_approve_is_allowed: data.autoApproveIsAllowed,
    min_amount_for_auto_approve: data.minAmountForAutoApprove,
    is_allowed_with_spend_above: data.isAllowedWithSpendAbove,
    min_user_spend_amount: data.minUserSpendAmount,
    service_id: data.service.id,
    is_repeatable: data.isRepeatable,
    image: data.image,
    translations: data.translations,
    quantity: data.quantity,
    repeats_count: data.repeatsCount,
  };
}
