import dateFilter from '@/services/functions/date-filter';
import createUserFromResource from '@/classes/createUser';
import createPaymentMethodFromResource from '@/classes/createPaymentMethod';

export default function createInvoiceFromResource(data) {
  return {
    amount: data.amount,
    createdAt: dateFilter(data.created_at),
    id: data.id,
    paidAt: dateFilter(data.paid_at),
    paymentMethod: createPaymentMethodFromResource(data.payment_method),
    shortName: data.short_name,
    status: data.status,
    user: createUserFromResource(data.user),
    actions: {
      canBeMarkedAsPaid: data.actions.can_be_marked_as_paid,
    },
  };
}
