export default {
  getRequestBody: (state) => ({
    headers: state.allHeaders,
    options: state.options,
    search: state.search,
    draw: state.draw,
    // ...state.requestParams,
    // ...getters.requestParams,
  }),
};
