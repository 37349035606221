import processServiceDescription from './processServiceDescription';

export default function createServiceStoreRequest(data) {
  return {
    translations: Object.entries(data.translations).reduce((acc, [key, val]) => {
      acc[key] = {
        name: val.name,
        example_link: val.exampleLink,
        speed: val.speed,
        geo: val.geo,
        requirements: val.requirements,
        guarantee: val.guarantee,
        start_time: val.startTime,
        short_description: val.shortDescription,
        reviews: val.reviews,
        description: processServiceDescription(val.description),
        meta_description: val.metaDescription,
        meta_title: val.metaTitle,
      };

      return acc;
    }, {}),
    admin_note: data.adminNote,
    min_quantity: data.minQuantity,
    max_quantity: data.maxQuantity,
    quantity_step: data.quantityStep,
    min_price: data.minPrice,
    pricing_per: data.pricingPer,
    default_price: data.defaultPrice,
    auto_refill_after_hours: data.autoRefillAfterHours,
    auto_refill_is_allowed: data.autoRefillIsAllowed,
    auto_refill_once_fill_percent: data.autoRefillOnceFillPercent,
    auto_refill_min_drop_percent: data.autoRefillMinDropPercent,
    auto_refill_max_drop_percent: data.autoRefillMaxDropPercent,
    auto_refill_max_total_refilled_percent: data.autoRefillMaxTotalRefilledPercent,
    order_quantity_ratio: data.orderQuantityRatio,
    api_access: data.apiAccess,
    restricted_access: data.restrictedAccess,
    has_refill: data.hasRefill,
    is_enabled: data.isEnabled,
    url_is_required: data.urlIsRequired,
    real_counter_complete_is_enabled: data.realCounterCompleteIsEnabled,
    moderation_is_enabled: data.moderationIsEnabled,
    cancel_by_client_is_allowed: data.cancelByClientIsAllowed,
    auto_cancel: data.autoCancel,
    gather_statistic: data.gatherStatistic,
    calculable_quantity: data.calculatableQuantity,
    service_type_id: data.serviceType ? data.serviceType.id : null,
    default_quantity_for_order_widget: data.defaultQuantityForWidget,
    budget_multiplier: data.budgetMultiplier,
    sync_params: data.syncParams,
    labels: data.labels.map((label) => label.id),
    sync_prices: data.syncPrices,
    sync_labels: data.syncLabels,
    sync_reviews: data.syncReviews,
    sync_short_description: data.syncShortDescription,
    sync_text_fields: data.syncTextFields,
    sync_description_field: data.syncDescriptionField,
    drip_feed_is_allowed: data.dripFeedIsAllowed,
    resale_margin_percent: data.resaleMarginPercent,
    promotable_checker_group_id: data.promotableCheckerGroupId,
    full_description_location: data.fullDescriptionLocation,
    slug: data.slug,
    providers: data.providers.map(({ id }) => id),
    countries: data.countries,
    live_stream_support_minutes: data.liveStreamSupportMinutes,
    live_stream_retention_quantity_multiplier: data.liveStreamRetentionQuantityMultiplier,
    forbids_orders_with_same_url: data.forbidsOrdersWithSameUrl,
    targets: data.targets,
    targetable_values: data.targetableValues.map((target) => ({
      target_id: target.targetId,
      values: target.values,
    })),
  };
}
