import axios from 'axios';
import settings from '@/settings';

const api = axios.create({
  baseURL: settings.apiHost,
});

api.CancelToken = axios.CancelToken;

export default api;
