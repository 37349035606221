import {
  createResetButton,
} from '../builder-definition/filters';
import { createPlainText } from '../builder-definition/filters/page';

export default {
  commonSearch: null,
  id: '',
  name: '',
  code: '',
  paymentType: '',
  bonusAmountCalculationType: '',
  bonusType: '',
  validFrom: '',
  validTo: '',
  usageLimit: '',
  minAmount: '',
  bonusAmount: '',
  bonusPercentage: '',
  affiliate: '',
  status: '',
};

export const config = {
  defaultSearchPropName: 'id',
  exceptionFilters: ['status'],
  rows: [
    [
      createPlainText({
        filterProp: 'commonSearch',
        placeholder: 'Search',
      }),
      createResetButton(),
    ],
  ],
};
