import createAplication from '@/classes/createAplications';
import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  index: '/api/v1/admin/application',
});

export default {
  index() {
    const endpoint = getURL('index');

    return http.get(endpoint)
      .then((response) => createAplication(response.data.data));
  },
  getURL,
};
