import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createGrade from '@/classes/createGrade';
import handleErrors from '@/services/handle-errors';
import http from '../../http';
import urlFormatter from '../../url-formatter';
import createGradeStoreErrors from './create-grade-errors';
import createGradeStoreRequest from './create-grade-store-request';

const getURL = urlFormatter({
  list: '/api/v1/admin/grades',
  store: '/api/v1/admin/grades',
  available: '/api/v1/admin/grades/available',
  show: '/api/v1/admin/grades/{gradeId}',
  update: '/api/v1/admin/grades/{gradeId}',
  archive: '/api/v1/admin/grades/{gradeId}/archive',
  restore: '/api/v1/admin/grades/{gradeId}/unarchive',
});
const gradesRepository = {
  async table(queryParams) {
    const endpoint = getURL('list');
    const { data } = await http.get(
      `${endpoint}?${datatableFormaterStringified(queryParams)}`,
      { params: { is_archived: Number(queryParams.search.isArchived) } },
    );

    return {
      items: data.data.map(createGrade),
      totalItemsCount: data.recordsFiltered,
    };
  },
  async list() {
    const endpoint = getURL('list');

    try {
      const { data } = await http.get(endpoint);

      return data.data.map(createGrade);
    } catch (error) {
      return handleErrors(error);
    }
  },
  async available() {
    const endpoint = getURL('available');

    try {
      const { data } = await http.get(endpoint);

      return data.data.map(createGrade);
    } catch (error) {
      return handleErrors(error);
    }
  },
  async show(gradeId) {
    const endpoint = getURL('show', { gradeId });

    try {
      const { data } = await http.get(endpoint);

      return createGrade(data.data);
    } catch (error) {
      return handleErrors(error);
    }
  },
  async store(data) {
    const endpoint = getURL('store');

    try {
      return await http.post(endpoint, createGradeStoreRequest(data));
    } catch (error) {
      return handleErrors(error, createGradeStoreErrors);
    }
  },
  async update(data) {
    const endpoint = getURL('update', { gradeId: data.id });

    try {
      return await http.put(endpoint, createGradeStoreRequest(data));
    } catch (error) {
      return handleErrors(error, createGradeStoreErrors);
    }
  },
  async archive({ gradeId }) {
    const endpoint = getURL('archive', { gradeId });

    try {
      return await http.post(endpoint);
    } catch (error) {
      return handleErrors(error);
    }
  },
  async restore({ gradeId }) {
    const endpoint = getURL('restore', { gradeId });

    try {
      return await http.post(endpoint);
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default gradesRepository;
