import stateExtender from '@/store/utils/stateExtender';
import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import ordersTable from './submodules/table';
import recreateModal from './submodules/recreateModal';
import cancelModal from './submodules/cancelModal';

export default stateExtender({
  namespaced: true,
  state,
  modules: {
    table: ordersTable,
    recreateModal,
    cancelModal,
  },
  getters,
  mutations,
  actions,
});
