export default function createDiscountStoreRequest(formData) {
  return {
    name: formData.name,
    valid_from: formData.validFrom,
    valid_to: formData.validTo,
    services: formData.services.map((discountService) => ({
      service_id: discountService.id,
      discount_percent: discountService.discountPercent / 100,
    })),
  };
}
