import balanceRepository from './repositories/balance-repository';
import servicesRepository from './repositories/services-repository/index';
import userServicesPricingRepository from './repositories/user-services-pricing-repository';
import rolesRepository from './repositories/roles-repository';
import freeTrialsRepository from './repositories/free-trials-repository';
import ordersRepository from './repositories/orders-repository';
import usersRepository from './repositories/users-repository';
import financesRepository from './repositories/finances-repository';
import ordersRefillsRepository from './repositories/order-refills-repository';
import settingsRepository from './repositories/settings-repository';
import providersRepository from './repositories/providers-repository';
import userAffiliates from './repositories/user-affiliates-repository';
import referralRepository from './repositories/referral-repository';
import paymentMethodsRepository from './repositories/payment-methods-repository/index';
import paymentMethodBonusesRepository from './repositories/payment-method-bonuses-repository';
import orderWidgetsRepository from './repositories/order-widgets-repository';
import providerStatisticRepository from './repositories/provider-statistic-repository';
import externalPanelsRepository from './repositories/external-panels-repository/index';
import networksRepository from './repositories/networks-repository';
import serviceTypesRepository from './repositories/service-types-repository';
import localesRepository from './repositories/locales-repository';
import invoicesRepository from './repositories/invoices-repository';
import couponsRepository from './repositories/coupons-repository/index';
import instanceSettingsRepository from './repositories/instance-settings-repository';
import permissionsRepository from './repositories/permissions-repository';
import webhooksRepository from './repositories/webhooks-repository/index';
import notificationsRepository from './repositories/notifications-repository';
import subscriptionsRepository from './repositories/subscriptions-repository';
import dripFeedsRepository from './repositories/drip-feeds-repository';
import promotableCheckerGroupsRepository from './repositories/promotable-checker-groups-repository';
import countriesRepository from './repositories/countries-repository';
import discountsRepository from './repositories/discounts-repository';
import adminNotificationsRepository from './repositories/admin-notifications-repository';
import staffsRepository from './repositories/staffs-repository';
import personalRepository from './repositories/personal-repository';
import auditLogsRepository from './repositories/audit-logs-repository';
import statusesCountersRepository from './repositories/statuses-counters-repository';
import applicationRepository from './repositories/application-repository';
import targetAttachmentsRepository from './repositories/target-attahcments-repository';
import websocketsRepository from './repositories/websockets-repository';
import specialOffersRepository from './repositories/special-offers-repository';
import featuresRepository from './repositories/featuresRepository';
import packagesRepository from './repositories/packages-repository';
import comboOrdersRepository from './repositories/combo-orders-repository';
import gradesRepository from './repositories/grades-repository';
import targetsRepository from './repositories/targets-repository';
import targetTypesRepository from './repositories/target-types-repository';
import logsRepository from './repositories/logs-repository';

const repositories = {
  balance: balanceRepository,
  services: servicesRepository,
  userServicesPricing: userServicesPricingRepository,
  roles: rolesRepository,
  freeTrials: freeTrialsRepository,
  orders: ordersRepository,
  users: usersRepository,
  finances: financesRepository,
  ordersRefills: ordersRefillsRepository,
  settings: settingsRepository,
  providers: providersRepository,
  userAffiliates,
  referral: referralRepository,
  paymentMethods: paymentMethodsRepository,
  paymentMethodBonuses: paymentMethodBonusesRepository,
  orderWidgets: orderWidgetsRepository,
  providerStatistic: providerStatisticRepository,
  externalPanels: externalPanelsRepository,
  networks: networksRepository,
  serviceTypes: serviceTypesRepository,
  locales: localesRepository,
  invoices: invoicesRepository,
  coupons: couponsRepository,
  instanceSettings: instanceSettingsRepository,
  permissions: permissionsRepository,
  webhooks: webhooksRepository,
  notifications: notificationsRepository,
  subscriptions: subscriptionsRepository,
  dripFeeds: dripFeedsRepository,
  promotableCheckerGroups: promotableCheckerGroupsRepository,
  countries: countriesRepository,
  adminNotifications: adminNotificationsRepository,
  discounts: discountsRepository,
  staffs: staffsRepository,
  personal: personalRepository,
  auditLogs: auditLogsRepository,
  statusesCounters: statusesCountersRepository,
  application: applicationRepository,
  targetAttachments: targetAttachmentsRepository,
  websockets: websocketsRepository,
  specialOffers: specialOffersRepository,
  features: featuresRepository,
  packages: packagesRepository,
  comboOrders: comboOrdersRepository,
  grades: gradesRepository,
  targets: targetsRepository,
  targetTypes: targetTypesRepository,
  logs: logsRepository,
};

export default {
  get: (name) => repositories[name],
};
