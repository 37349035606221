export default {
  isShown: (state) => state.isShown,
  discountLoading: (state) => state.discountLoading,
  name: (state) => state.formData.name,
  validFrom: (state) => state.formData.validFrom,
  validTo: (state) => state.formData.validTo,
  createdAt: (state) => state.formData.createdAt,
  services: (state) => state.formData.services,
  discountId: (state) => state.discountId,
  errors: (state) => state.errors,
};
