import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  index: '/api/v1/countries',
});

export default {
  async index() {
    const endpoint = getURL('index');
    const response = await http.get(endpoint);

    return response.data.data.map((item) => ({
      ...item,
      tags: [item.iso_code_2],
    }));
  },
  getURL,
};
