import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  statistics: '/api/v1/admin/referral-program-statistics',
});

export default {
  statistics({
    affiliates,
    referrals,
    groupBy,
    startDate,
    endDate,
  }) {
    const endpoint = getURL('statistics');

    return http.get(endpoint, {
      params: {
        affiliates,
        referrals,
        group_by: groupBy,
        start_date: startDate,
        end_date: endDate,
      },
    });
  },
  getURL,
};
