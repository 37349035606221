export default function getObjectValueByPath(object, path) {
  const pathSplitted = path.split('.');

  return pathSplitted.reduce((acc, stepKey) => {
    if (!acc) {
      return acc;
    }

    return acc[stepKey];
  }, object);
}
