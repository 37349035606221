<template>
  <!-- eslint-disable max-len -->
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9365 7.2183C15.6539 6.9357 15.1974 6.9357 14.9148 7.2183L10.8278 11.3052L11.8496 12.327L15.9365 8.23279C16.2119 7.95743 16.2119 7.49366 15.9365 7.2183ZM19.009 7.21106L11.8496 14.3704L9.32786 11.856C9.04525 11.5733 8.58874 11.5733 8.30613 11.856C8.02352 12.1386 8.02352 12.5951 8.30613 12.8777L11.3351 15.9066C11.6177 16.1893 12.0742 16.1893 12.3568 15.9066L20.0307 8.24004C20.3133 7.95743 20.3133 7.50091 20.0307 7.2183H20.0234C19.7481 6.92845 19.2916 6.92845 19.009 7.21106ZM4.21195 12.8849L7.24092 15.9139C7.52353 16.1965 7.98004 16.1965 8.26265 15.9139L8.76989 15.4067L5.23369 11.856C4.95108 11.5733 4.49456 11.5733 4.21195 11.856C3.92935 12.1386 3.92935 12.6023 4.21195 12.8849Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'IconDanger',
};
</script>
