import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createSubscription from '@/classes/createSubscription';
import http, { cancelTokenNames } from '../http';
import urlFormatter from '../url-formatter';

const tableHeadersDictionary = {

};
const getURL = urlFormatter({
  table: '/api/v1/admin/subscriptions',
  show: '/api/v1/admin/subscriptions/{id}',
  cancel: '/api/v1/admin/subscriptions/{id}/cancel',
  collections: '/api/v1/admin/subscriptions/collections',
});

export default {
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      headersDictionary: tableHeadersDictionary,
      options,
      search,
    });
    const endpoint = getURL('table');
    const response = await http.get(`${endpoint}?${requestBody}`, {
      applyCancelAdapter: cancelTokenNames.SUBSCRIPTIONS_TABLE,
    });

    return {
      items: response.data.data.map(createSubscription),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  cancel(id) {
    const endpoint = getURL('cancel', { id });

    return http.post(endpoint);
  },
  show(id) {
    const endpoint = getURL('show', { id });

    return http.get(endpoint)
      .then((response) => createSubscription(response.data.data));
  },
  collections() {
    const endpoint = getURL('collections');

    return http.get(endpoint)
      .then((response) => ({
        statuses: response.data.data.statuses,
        billingTypes: response.data.data.billing_types,
      }));
  },
};
