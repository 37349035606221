export default {
  shownEditorDialog: (state) => state.shownEditorDialog,
  isCreated: (state) => state.isCreated,
  grade: (state) => state.grade,
  errors: (state) => state.errors,
  gradeTranslations: (state) => state.grade.translations,
  gradeIcon: (state) => state.grade.icon,
  gradeSpendingGoal: (state) => state.grade.spendingGoal,
  gradeDiscountPercent: (state) => state.grade.discountPercent,
  gradeThumbnailPeriod: (state) => state.grade.thumbnailPeriod,
  gradeConsultationChatPeriod: (state) => state.grade.consultationChatPeriod,
  gradeAvailableClosedSales: (state) => state.grade.availableClosedSales,
  gradeAvailablePersonalManager: (state) => state.grade.availablePersonalManager,
  isArchived: (state) => state.grade.isArchived,
};
