export default function createStoreTransactionError(error) {
  if (!error) return null;

  return {
    userId: error.user_id,
    transactionType: error.transaction_type,
    amount: error.amount,
    comment: error.comment,
    balanceType: error.balance_type,
  };
}
