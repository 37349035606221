export default function useVModel(
  paramName = 'Value',
  prop = 'value',
  event = 'input',
) {
  return {
    computed: {
      [paramName]: {
        get() {
          return this[prop];
        },
        set(value) {
          this.$emit(event, value);
        },
      },
    },
  };
}
