<template>
  <v-data-table
    v-bind="$attrs"
    :class="['base-data-table rounded-t-0', {
      'base-data-table--mobile': isMobile,
    }]"
    :headers="accessedHeadsers"
    :expanded.sync="expanded"
    :item-key="itemKey"
    :show-expand="showExpand"
    :options.sync="tableOptions"
    :header-props="headerProps"
    single-expand
    hide-default-footer
    v-on="$listeners"
  >
    <template #no-data>
      <data-table-no-data :text="noDataText" />
    </template>

    <template #header.data-table-select="{ props, on }">
      <base-simple-checkbox
        v-bind="props"
        v-on="on"
      />
    </template>

    <template #item.data-table-select="{ isSelected, select }">
      <base-simple-checkbox
        :value="isSelected"
        @input="select($event)"
      />
    </template>

    <template
      v-for="col in accessedHeadsers"
      #[`header.${col.value}`]="{ header }"
    >
      <span
        :key="col.value"
        class="baseDataTableHeaderText--text header-text"
      >
        {{ header.text }}
      </span>
    </template>

    <template #footer="{ props }">
      <data-table-paginator
        :pagination="props.pagination"
        :options.sync="tableOptions"
        v-bind="paginatorAttrs"
      >
        <template #prepend-footer-row>
          <slot name="prepend-footer-row" />
        </template>
      </data-table-paginator>
    </template>

    <template
      v-for="(index, name) in $scopedSlots"
      #[name]="data"
    >
      <slot
        :name="name"
        v-bind="data"
      />
    </template>

    <template #expanded-item="data">
      <slot
        name="expanded-item"
        v-bind="{ ...data, expanded }"
      />
    </template>
  </v-data-table>
</template>
<script>
import { mapState } from 'vuex';
import DataTableNoData from '@/components/next/DataTableNoData.vue';
import DataTablePaginator from '@/components/next/DataTablePaginator.vue';
import isMobile from '@/mixins/isMobile';

export default {
  components: {
    DataTableNoData,
    DataTablePaginator,
  },
  mixins: [isMobile],
  props: {
    showExpand: Boolean,
    headers: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    headerProps: {
      type: Object,
      default: () => ({ sortIcon: '$icon-sort-next' }),
    },
    noDataText: {
      type: String,
      default: 'No data',
    },
    paginatorAttrs: {
      type: Object,
      default() { return {}; },
    },
    itemKey: {
      type: String,
      default: 'id',
    },
  },
  data() {
    return {
      expanded: [],
    };
  },
  computed: {
    ...mapState('auth', ['profile']),
    tableOptions: {
      get() {
        return this.options;
      },
      set(value) {
        this.$emit('update:options', value);
      },
    },
    allPermissions() {
      return this.profile?.allPermissions || [];
    },
    accessedHeadsers() {
      return this.headers.filter((header) => (
        header.permission
          ? this.allPermissions.includes(header.permission)
          : true
      ));
    },
  },
};
</script>
