import router from '@/router';
import {
  createCellActions, createCellLink, createCellMoney, createCellPlainStatuses, createCellNameInfo,
} from '../builder-definition/tables';
import {
  createCellBool, createCellFilled, createCellNumber,
  createCellPercent, createCellPriceModification, createCellService, createCellSource,
  createCellUrl,
} from '../builder-definition/tables/plain-builders';
import {
  createDateFilter, createSourceFilter, createTextFilter,
} from '../builder-definition/filters/table';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      hideable: false,
      isShown: true,
      name: 'orders.id',
      width: '106px',
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
      ...createCellPlainStatuses({
        mapper: 'order',
        statusProp: 'status',
        to: (item) => ({
          name: 'Order',
          params: {
            id: item.id,
          },
        }),
        hideTooltip: ['Error'],
      }),
    },
    {
      value: 'createdAt',
      name: 'orders.created_at',
      isShown: true,
      text: 'Created at',
      width: '120px',
      customFilter: createDateFilter(),
    },
    {
      value: 'completedAt',
      name: 'orders.completed_at',
      isShown: false,
      text: 'Completed at',
      width: '120px',
      customFilter: createDateFilter(),
    },

    {
      value: 'name',
      text: 'Name',
      customFilter: createTextFilter({ placeholder: 'Search Name' }),
      width: '192px',
      isShown: true,
      ...createCellNameInfo({
        to: (item) => ({
          name: 'Order',
          params: {
            id: item.id,
          },
        }),
      }),
    },
    {
      value: 'service',
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search Service' }),
      text: 'Service name',
      name: 'service',
      width: '192px',
      ...createCellService(),
    },
    {
      value: 'price',
      text: 'Price',
      width: '80px',
      isShown: true,
      ...createCellMoney(),
    },
    {
      value: 'cost',
      text: 'Cost',
      searchable: false,
      isShown: true,
      width: '80px',
      ...createCellMoney(),
    },
    {
      value: 'user.balance',
      text: 'User balance',
      searchable: false,
      isShown: true,
      width: '120px',
      ...createCellMoney(),
    },
    {
      value: 'user.spent',
      text: 'User spent',
      searchable: false,
      isShown: true,
      width: '100px',
      ...createCellMoney(),
    },
    {
      value: 'user',
      text: 'User',
      customFilter: createTextFilter({ placeholder: 'Search User' }),
      name: 'users.email',
      width: '153px',
      isShown: true,
      sortable: false,
      ...createCellLink({
        textProp: 'user.email',
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.user.id,
          },
        }),
      }),
    },
    {
      value: 'url',
      text: 'URL',
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search URL' }),
      width: '125px',
      sortable: false,
      ...createCellUrl(),
    },
    {
      value: 'revenue',
      text: 'Revenue',
      searchable: false,
      width: '100px',
      ...createCellMoney(),
    },
    {
      value: 'quantity',
      text: 'Quantity',
      customFilter: createTextFilter({ placeholder: 'Search Quantity' }),
      width: '90px',
      ...createCellNumber(),
      componentAttrs: {
        delimiter: '',
      },
    },
    {
      value: 'marginPercent',
      text: 'Margin percentage',
      searchable: false,
      sortable: false,
      name: 'margin_percent',
      width: '200px',
      ...createCellPercent(),
    },
    {
      value: 'currentQuantity',
      text: 'Current quantity',
      name: 'current_quantity',
      width: '150px',
      ...createCellNumber(),
      componentAttrs: {

        delimiter: ' ',
      },
    },
    {
      value: 'percentOfExternalStatisticsFill',
      name: 'percent_of_external_statistics_fill',
      text: 'Filled, %',
      width: '110px',
      searchable: false,
      ...createCellFilled(),

    },
    {
      text: 'Has no active providers',
      value: 'hasNoActiveProviders',
      name: 'has_no_active_providers',
      ...createCellBool(),
      width: '160px',
      sortable: false,
    },
    {
      text: 'Paid',
      value: 'isPaid',
      name: 'is_paid',
      ...createCellBool(),
      width: '100px',
      sortable: false,
    },
    {
      text: 'Status',
      value: 'status',
      isHidden: true,
    },
    {
      text: 'Refund',
      value: 'refundAmount',
      sortable: false,
      width: '90px',
      isShown: true,
      ...createCellMoney(),
    },
    {
      value: 'source',
      text: 'Source',
      width: '80px',
      customFilter: createSourceFilter(),
      ...createCellSource(),
    },
    {
      value: 'priceModifications',
      text: 'Price Modification',
      name: 'price_modification',
      width: '150px',
      searchable: false,
      sortable: false,
      ...createCellPriceModification(),
    },
    {
      value: 'actions',
      text: 'Actions',
      isShown: true,
      searchable: false,
      sortable: false,
      width: '77px',
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Edit',
              value: 'editable',
              prop: 'editable',
              callBack: (item) => {
                router.push({
                  name: 'Order',
                  params: {
                    id: item.id,
                  },
                });
              },
            },
            {
              text: 'Activate',
              value: 'activable',
              prop: 'activable',
              callBack: (item, store) => {
                store.dispatch('orders/confirmedActivate', item.id);
              },
            },
            {
              text: 'Approve',
              value: 'approvable',
              prop: 'approvable',
              callBack: (item, store) => {
                store.dispatch('orders/confirmedApprove', item.id);
              },
            },
            {
              text: 'Cancel',
              value: 'cancelable',
              prop: 'cancelable',
              callBack: (item, store) => {
                store.dispatch('orders/cancelModal/showSingleCancelModal', {
                  orderId: item.id,
                  refreshTable: true,
                });
              },
            },
            {
              text: 'Complete',
              value: 'completable',
              prop: 'completable',
              callBack: (item, store) => {
                store.dispatch('orders/confirmedComplete', item.id);
              },
            },
            {
              text: 'Pause',
              value: 'pausable',
              prop: 'pausable',
              callBack: (item, store) => {
                store.dispatch('orders/confirmedPause', item.id);
              },
            },
            {
              text: 'Reject',
              value: 'rejectable',
              prop: 'rejectable',
              callBack: (item, store) => {
                store.dispatch('orders/confirmedReject', item.id);
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;
            if (tableElement.actions[prop]) return true;

            return false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
