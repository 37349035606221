import { defaultState } from './state';

export default {
  SET_CLEAR_STATE: (state) => {
    Object.assign(state, defaultState());
  },
  SET_ID: (state, value) => { state.externalPanelId = value; },
  SET_IS_SHOWN: (state, value) => { state.isShown = value; },
  SET_BILLINGS: (state, value) => { state.billings = value; },
};
