import stateExtender from '@/store/utils/stateExtender';
import RepositoryFactory from '@/services/repository-factory';
import datatableModule from '@/store/builder/datatableModule';
import table from '@/components/builders/configs/tables/networksTable';
import filters from '@/components/builders/configs/filters/invoiceFilters';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import networksModal from './submodules/NetworksAndServiceTypesModal';

const networksRepository = RepositoryFactory.get('networks');
const serviceTypesRepository = RepositoryFactory.get('serviceTypes');
const state = {
  repository: networksRepository,
  serviceTypesRepository,
  error: null,
};

export default stateExtender({
  namespaced: true,
  state: () => state,
  modules: {
    table: datatableModule({
      fetch: networksRepository.table,
      headers: table.headers.filter((header) => header.isShown),
      allHeaders: table.headers,
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 30,
      },
      search: filters,
    }),
    networksModal,
  },
  getters,
  mutations,
  actions,
});
