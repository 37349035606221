export const defaultState = () => ({
  saveBtnLoading: false,
  loading: false,
  id: null,
  search: '',
  availableServices: [],
  selected: [],
  errors: {},
  errorsMessage: '',
});

export default () => ({
  isShown: false,
  ...defaultState(),
});
