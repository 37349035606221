import {
  kebab, registerSingleComponent, trimFileNames,
} from '@/utils/registerComponents';

const requireComponent = require.context(
  '.',
  false,
  /Icon[\w-]+\.vue$/,
);

export default function globalIcons() {
  const iconsObject = {};

  requireComponent.keys().forEach((fileName) => {
    const componentName = kebab(trimFileNames(fileName));

    registerSingleComponent(fileName, requireComponent);
    iconsObject[componentName] = { component: componentName };
  });

  return iconsObject;
}
