import moment from 'moment';

export default function createFreeTrialsList(data) {
  if (!data) {
    return null;
  }

  return {
    autoApproveIsAllowed: data.auto_approve_is_allowed,
    minAmountForAutoApprove: data.min_amount_for_auto_approve,
    id: data.id,
    image: data.image,
    createdAt: moment(data.created_at).utc().format('YYYY-MM-DD HH:mm:ss'),
    isArchived: data.is_archived,
    isEnabled: data.is_enabled,
    isRepeatable: data.is_repeatable,
    name: data.name,
    quantity: data.quantity,
    service: data.service,
  };
}
