import handleErrors from '@/services/handle-errors';
import createAffiliatesResponse from '@/classes/createAffiliatesResponse';
import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  list: '/api/v1/admin/users/{userId}/affiliates',
  store: '/api/v1/admin/users/{userId}/affiliates',
  show: '/api/v1/admin/users/{userId}/affiliates/{affiliatesUserId}',
  update: '/api/v1/admin/users/{userId}/affiliates/{selectedUserId}',
  delete: '/api/v1/admin/users/{userId}/affiliates/{affiliatesUserId}',
});

export default {
  list({ userId }) {
    const endpoint = getURL('list', { userId });

    return http.get(endpoint)
      .then((response) => response.data.data.map((item) => createAffiliatesResponse(item)));
  },
  store({
    userId,
    selectedUserId,
    startedAt,
    endedAt,
  }) {
    const endpoint = getURL('store', { userId });

    return http.post(endpoint, {
      affiliate_id: selectedUserId,
      started_at: startedAt,
      ended_at: endedAt,
    }).then((response) => response.data)
      .catch((error) => handleErrors(error));
  },
  update({
    userId,
    selectedUserId,
    startedAt,
    endedAt,
  }) {
    const endpoint = getURL('update', {
      userId,
      selectedUserId,
    });

    return http.put(endpoint, {
      affiliate_id: selectedUserId,
      started_at: startedAt,
      ended_at: endedAt,
    }).then((response) => response.data)
      .catch((error) => handleErrors(error));
  },
  delete({
    userId, affiliatesUserId,
  }) {
    const endpoint = getURL('delete', {
      userId,
      affiliatesUserId,
    });

    return http.delete(endpoint);
  },
  getURL,
};
