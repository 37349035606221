<template>
  <v-chip
    v-bind="$attrs"
    class="base-chip"
    v-on="$listeners"
  >
    <template #default>
      <slot />
    </template>
  </v-chip>
</template>

<script>
export default {};
</script>
