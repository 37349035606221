import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createValidationError from '@/services/functions/create-validation-error';
import createAutoRefillFromResource from '@/classes/createAutoRefillFromResource';
import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  index: '/api/v1/admin/orders/{orderId}/order-refill-requests',
  table: '/api/v1/admin/orders/{orderId}/order-refill-requests',
  store: '/api/v1/admin/orders/{orderId}/order-refill-requests',
  autoRefillParams: '/api/v1/admin/orders/{orderId}/auto-refill-params',
});

export default {
  async table({
    headers, options, orderId,
  }) {
    const endpoint = getURL('table', { orderId });
    const requestBody = datatableFormaterStringified({
      headers,
      options,
    });
    const response = await http.get(`${endpoint}?${requestBody}`);

    return {
      items: response.data.data,
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  index({ orderId }) {
    const endpoint = getURL('index', { orderId });

    return http.get(endpoint);
  },
  store({
    orderId, quantity,
  }) {
    const endpoint = getURL('store', { orderId });

    return http.post(endpoint, { quantity })
      .catch(createValidationError());
  },
  autoRefillParams({ orderId }) {
    const endpoint = getURL('autoRefillParams', { orderId });

    return http.get(endpoint)
      .then((response) => createAutoRefillFromResource(response.data.data));
  },

  getURL,
};
