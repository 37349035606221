import waiter from '@/store/utils/waiter';

export default {
  async archiveGrade({
    dispatch, getters, commit,
  }, gradeId) {
    await waiter('grades/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.archive({ gradeId });
    });
  },
  async restoreGrade({
    dispatch, getters, commit,
  }, gradeId) {
    await waiter('grades/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.restore({ gradeId });
    });
  },
  showArchived({
    dispatch,
  }) {
    dispatch('table/fetch');
  },
};
