import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import ServiceTypeFromResource from '@/classes/createServiceType';
import createServiceFromResource from '@/classes/createService';
import createServiceTypeTranslationsRequest from '@/classes/createServiceTypeTranslationsRequest';
import http from '../http';
import urlFormatter from '../url-formatter';
import handleErrors from '../handle-errors';

const getURL = urlFormatter({
  list: '/api/v1/admin/service-types',
  store: '/api/v1/admin/service-types',
  types: '/api/v1/admin/service-types/collections',
  getById: '/api/v1/admin/service-types/{id}',
  update: '/api/v1/admin/service-types/{id}',
  sortedServices: '/api/v1/admin/service-types/{serviceTypeId}/services',
  sortServices: '/api/v1/admin/service-types/{serviceTypeId}/sort-services',
});

export default {
  types() {
    const endpoint = getURL('types');

    return http.get(endpoint)
      .then((response) => response.data.data.types);
  },
  async table(queryParams) {
    const endpoint = getURL('list');
    const response = await http.get(`${endpoint}/?${datatableFormaterStringified(queryParams)}`);

    return {
      items: response.data.data.map((item) => new ServiceTypeFromResource(item)),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  list() {
    const endpoint = getURL('list');

    return http.get(endpoint)
      .then((response) => response.data.data
        .map((item) => new ServiceTypeFromResource(item)));
  },
  getTypeByID(id) {
    const endpoint = getURL('getById', { id });

    return http.get(endpoint)
      .then((response) => new ServiceTypeFromResource(response.data.data));
  },
  store(serviceType) {
    const endpoint = getURL('store');

    return http.post(endpoint, {
      id: serviceType.id,
      name: serviceType.name,
      description: serviceType.description,
      icon: serviceType.icon,
      type: serviceType.type,
      slug: serviceType.slug,
      network: serviceType.network,
      translations: serviceType?.translations,
    })
      .catch((error) => handleErrors(error, (errors) => ({
        id: errors.id,
        name: errors.name,
        description: errors.description,
        icon: errors.icon,
        type: errors.type,
        slug: errors.slug,
        sorting: errors.sorting,
        network: errors.network,
        translations: errors.translations,
      })));
  },
  update(serviceType) {
    const { id } = serviceType;
    const endpoint = getURL('update', { id });

    return http.put(endpoint, {
      panel_service_type: {
        translations: createServiceTypeTranslationsRequest(serviceType.translations),
        icon: serviceType.icon,
        id: serviceType.panel_service_type_id,
      },
    })
      .catch((error) => handleErrors(error, (errors) => ({
        id: errors.id,
        name: errors.name,
        description: errors.description,
        icon: errors.icon,
        type: errors.type,
        slug: errors.slug,
        sorting: errors.sorting,
        network: errors.network,
        translations: errors.translations,
      })));
  },
  sortedServices(serviceTypeId) {
    const endpoint = getURL('sortedServices', { serviceTypeId });

    return http.get(endpoint)
      .then((response) => response.data.data.map(createServiceFromResource));
  },
  sortServices(serviceTypeId, sortedServicesIds) {
    const endpoint = getURL('sortServices', { serviceTypeId });

    return http.post(endpoint, {
      services: sortedServicesIds,
    });
  },
  getURL,
};
