export default {
  // RECREATE SINGLE
  singleRecreateId: (state) => state.single.id,
  singleRecreateQuantity: (state) => state.single.quantity,
  isSingleRecreating: (_, getters) => Boolean(getters.singleRecreateId),
  recreateQuantity: (state) => state.single.quantity,

  // RECREATE BULK
  bulkRecreateIds: (state) => state.bulk.ids,
  isBulkRecreating: (_, getters) => Boolean(getters.bulkRecreateIds),

  // RECREATE COMMON
  isRecreateModalShown: (_, getters) => getters.isSingleRecreating || getters.isBulkRecreating,
  availableProviders: (state) => state.common.availableProviders.items ?? [],
  availableProvidersLastPage: (state) => state.common.availableProviders.lastPage,
  availableProvidersCurrentPage: (state) => state.common.availableProviders.currentPage,
  foundAvailableProviders: (state) => state.common.availableProviders.found,
  isAvailableProvidersLoading: (state) => state.common.availableProviders.loading,
  selectedProviderId: (state) => state.common.selectedProviderId,
  searchString: (state) => state.common.availableProviders.searchString,
  isSelectedSearchString: (_, getters) => {
    if (!getters.selectedProviderId) {
      return false;
    }

    const selectedProvider = getters.availableProviders
      .find((provider) => provider.id === getters.selectedProviderId);

    return Boolean(selectedProvider) && selectedProvider.name === getters.searchString;
  },
  shownProvidersList: (_, getters) => (
    getters.searchString
    && !getters.isSelectedSearchString
    && getters.foundAvailableProviders
  ) || getters.availableProviders,
  isRecreatingProcess: (state) => state.common.isRecreatingProcess,
  recreateRefreshTable: (state) => state.common.refreshTable,
  recreateErrors: (state) => state.common.errors,
};
