export default function createPaymentRefund(data) {
  if (!data) {
    return {};
  }

  return {
    id: data.id ?? null,
    admin: {
      id: (data.admin && data.admin.id) ?? null,
      email: (data.admin && data.admin.email) ?? null,
    },
    type: data.type ?? null,
    transactionType: data.transaction_type ?? null,
    createdAt: data.created_at ?? null,
    amount: data.amount ?? null,
  };
}
