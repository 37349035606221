import stateExtender from '@/store/utils/stateExtender';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  formattedRequest: false,
  loading: false,
  headers: [],
  allHeaders: [],
  items: [],
  options: {},
  search: {},
  selected: [],
  async fetch() {
    await (async () => {})();
  },
  updateTimeout: 500,
  timer: null,
  draw: 0,
  totalItemsCount: 0,
  error: null,
  innerLoading: false,
  requestParams: {},
};
const setState = ({
  search,
  headers,
  options,
  allHeaders,
  formattedRequest,
  fetch,
  // requestParams,
}) => ({
  ...state,
  // requestParams: requestParams || state.requestParams,
  search: search || state.search,
  headers: headers || state.headers,
  options: options || state.options,
  formattedRequest: formattedRequest || state.formattedRequest,
  fetch: fetch || state.fetch,
  allHeaders: allHeaders || state.allHeaders,
});

export default (payload, context) => stateExtender({
  namespaced: true,
  state: () => ({
    ...setState(payload),
    ...context?.state,
  }),
  getters: {
    ...getters,
    ...context?.getters,
    ...payload.requestParams,
    filteredAllHeadersWithPermition: (state, _, __, rootGetters) => {
      const test = state.allHeaders.filter((header) => {
        let hasPermission = !!header;

        if (header?.permission) {
          hasPermission = rootGetters['auth/allPermissions']?.some((item) => header.permission?.includes(item));
        }

        return hasPermission;
      });

      return test;
    },
    filteredHeadersWithPermition: (state, _, __, rootGetters) => {
      const test = [...state.headers].filter((header) => {
        let hasPermission = !!header;

        if (header?.permission) {
          hasPermission = rootGetters['auth/allPermissions']?.some((item) => header.permission?.includes(item));
        }

        return hasPermission;
      });

      return test;
    },
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
    ...context?.actions,
  },
});
