<template>
  <!-- eslint-disable max-len -->
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 5C8.136 5 5 8.136 5 12C5 15.864 8.136 19 12 19C15.864 19 19 15.864 19 12C19 8.136 15.864 5 12 5ZM12 17.95C8.906 17.95 6.05 15.094 6.05 12C6.05 10.705 6.708 9.095 7.45 8.15L15.85 16.55C14.905 17.292 13.295 17.95 12 17.95ZM16.55 15.85L8.15 7.45C9.095 6.708 10.705 6.05 12 6.05C15.094 6.05 17.95 8.906 17.95 12C17.95 13.295 17.292 14.905 16.55 15.85Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'IconDanger',
};
</script>
