export default function createTargetableValues(data) {
  if (!data) {
    return null;
  }

  return {
    targetId: data.target_id,
    values: data.values,
  };
}
