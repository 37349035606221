export default {
  innerIsShown: (state) => state.innerIsShown,
  providerId: (state) => state.providerId,
  type: (state) => state.type,
  loading: (state) => state.loading,
  allNetworks: (state) => state.allNetworks,
  allServicesTypes: (state) => state.allServicesTypes,
  services: (state) => state.services,
  selectedNetworkId: (state) => state.selectedNetworkId,
  search: (state) => state.search,
  selectedServiceTypeId: (state) => state.selectedServiceTypeId,
  selectedServices: (state) => state.selectedServices,
  multiplier: (state) => state.multiplier,
  saveBtnLoading: (state) => state.saveBtnLoading,
  tableOptions: (state) => state.tableOptions,
  errorsNotify: (state) => state.errorsNotify,
  errors: (state) => state.errors,
  errorsMessage: (state) => state.errorsMessage,
};
