import {
  createResetButton,
} from '../builder-definition/filters';
import { createPlainText } from '../builder-definition/filters/page';

export default {
  commonSearch: '',
  id: '',
  name: '',
  url: '',
  user: '',
  status: '',
  contentsLimit: '',
  subscriptionServices: '',
  billingType: '',
  expiredAt: '',
  completedAt: '',
  createdAt: '',
};

export const config = {
  defaultSearchPropName: 'id',
  exceptionFilters: ['status'],
  rows: [
    [
      createPlainText({
        filterProp: 'commonSearch',
        placeholder: 'Search',
      }),
      createResetButton(),
    ],
  ],
};
