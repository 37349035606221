import { ref } from 'vue';

const isModalShown = ref(false);
const confirmBtnColor = ref('');
const confirmText = ref('Confirm your move');
const confirmTitle = ref('Confirm');
const confirmBtnText = ref('Proceed');
const cancelBtnText = ref('Cancel');
const confirmCallback = ref(() => {});
const cancelCallback = ref(() => {});

export const defaultDialogSetupBuilder = ({
  isModalShown: newIsModalShown = false,
  confirmBtnColor: newConfirmBtnColor = '',
  confirmText: newConfirmText = 'Confirm your move',
  confirmTitle: newConfirmTitle = 'Confirm',
  confirmBtnText: newConfirmBtnText = 'Proceed',
  cancelBtnText: newCancelBtnText = 'Cancel',
  confirmCallback: newConfirmCallback = () => {},
  cancelCallback: newCancelCallback = () => {},
}) => {
  isModalShown.value = newIsModalShown;
  confirmBtnColor.value = newConfirmBtnColor;
  confirmText.value = newConfirmText;
  confirmTitle.value = newConfirmTitle;
  confirmBtnText.value = newConfirmBtnText;
  cancelBtnText.value = newCancelBtnText;
  confirmCallback.value = newConfirmCallback;
  cancelCallback.value = newCancelCallback;
};

const confirmHandler = () => {
  confirmCallback.value();
  isModalShown.value = false;
};
const cancelHandler = () => {
  cancelCallback.value();
  isModalShown.value = false;
};

export default function dialogSetup() {
  return {
    isModalShown,
    confirmBtnColor,
    confirmText,
    confirmTitle,
    confirmBtnText,
    cancelBtnText,
    confirmHandler,
    cancelHandler,
  };
}
