import stateExtender from '@/store/utils/stateExtender';
import RepositoryFactory from '@/services/repository-factory';
import datatableModule from '@/store/builder/datatableModule';
import couponTable from '@/components/builders/configs/tables/statisticsCouponTable';
import filters from '@/components/builders/configs/filters/statisticsFilter';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const statisticsRepository = RepositoryFactory.get('coupons');
const state = {
  repository: statisticsRepository,
  error: null,
};
const statisticsModule = {
  namespaced: true,
  state: () => state,
  modules: {
    table: datatableModule({
      fetch: statisticsRepository.statistics,
      headers: couponTable.headers.filter((header) => header.isShown),
      allHeaders: couponTable.headers,
      requestParams: {
        groupBy(state, getters, rootState, rootGetters) {
          return rootGetters['statistics/groupBy'];
        },
        startDate(state, getters, rootState, rootGetters) {
          return rootGetters['statistics/startDate'];
        },
        endDate(state, getters, rootState, rootGetters) {
          return rootGetters['statistics/endDate'];
        },
      },
      options: {
        sortBy: ['coupon.name'],
        sortDesc: [true],
        itemsPerPage: 30,
      },
      search: filters,
    }),
  },
  getters,
  mutations,
  actions,
};

export default stateExtender(statisticsModule);
