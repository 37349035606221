export default {
  setMarkAsPaidModalOpen({ dispatch }, {
    id,
    amount,
    isShown,
  }) {
    dispatch('setMarkAsPaidModalClose', isShown);
    dispatch('setInvoiceAmount', amount);
    dispatch('setInvoiceId', id);
  },
  setInvoiceId({ commit }, id) {
    commit('SET_INVOICE_ID', id);
  },
  setMarkAsPaidModalClose({ commit }, isShown) {
    commit('SET_MARK_AS_PAID_MODAL', isShown);
  },
  setInvoiceAmount({ commit }, amount) {
    commit('SET_INVOICE_AMOUNT', amount);
  },
  setSaving({ commit }, value) {
    commit('SET_SAVING', value);
  },
  setErrors({ commit }, errors) {
    commit('SET_ERRORS', errors);
  },
  clearErrors({ commit }, key) {
    commit('CLEAR_ERRORS', key);
  },
  setSuccess({ commit }, value) {
    commit('SET_SUCCESS', value);
  },
  async saveMarkAsPaid({
    dispatch, rootGetters, state,
  }) {
    dispatch('setSaving', true);
    await rootGetters['invoices/getRepository'].markAsPaid(state.invoiceId, state.formData)
      .then(() => {
        dispatch('invoices/table/fetch', null, { root: true });
        dispatch('setSuccess', true);
        dispatch('setMarkAsPaidModalClose', false);
      })
      .catch((error) => {
        if (error.messages) {
          dispatch('setErrors', error.messages);
        }
      })
      .finally(() => {
        dispatch('setSaving', false);
      });
  },
};
