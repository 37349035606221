import {
  createPlainText, createResetButton,
} from '../builder-definition/filters/page';

export default {
  commonSearch: '',
  id: '',
  name: '',
  email: '',
  balance: '',
  affiliate: '',
  affiliateCode: '',
  createdAt: '',
  userServiceExists: '',
  status: 'active',
};

export const config = {
  defaultSearchPropName: 'commonSearch',
  exceptionFilters: ['status'],
  rows: [
    [
      createPlainText({
        filterProp: 'commonSearch',
        placeholder: 'Search',
      }),
      createResetButton(),
    ],
  ],
};
